import React, { useState, useEffect, useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend } from 'recharts';
import { collection, query, where, getDocs, or, Timestamp } from 'firebase/firestore';
import { db } from '../../../helpers/firebase';
import '../../../helpers/spinner.css';
import { Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import Select from 'react-select';
import { useTopbar } from '../../../helpers/TopbarContext';
import useAuth from '../../../hooks/useAuth';

const PeriodComparisonChart = ({ store, ordersGraph }) => {
    const [data, setData] = useState({});
    const [selectedStore, setSelectedStore] = useState(null);
    const {
        startDate,
        endDate,
        comparisonStartDate,
        comparisonEndDate,
        selectedChartInterval,
        selectedTimezone
    } = useTopbar();

    // Función para ajustar las fechas según la zona horaria seleccionada
    const adjustDatesToTimezone = (start, end, timezone) => {
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        });

        const adjustDate = (date) => {
            const parts = formatter.formatToParts(date);
            const dateObject = {};
            parts.forEach(part => {
                if (['year', 'month', 'day', 'hour', 'minute', 'second'].includes(part.type)) {
                    dateObject[part.type] = parseInt(part.value, 10);
                }
            });
            return new Date(Date.UTC(
                dateObject.year,
                dateObject.month - 1,
                dateObject.day,
                dateObject.hour,
                dateObject.minute,
                dateObject.second
            ));
        };

        return {
            adjustedStart: adjustDate(start),
            adjustedEnd: adjustDate(end)
        };
    };

    const { currentUser } = useAuth();

    useEffect(() => {
        if (store) {
            setSelectedStore(store);
        }
    }, [store]);

    const [storeOptions, setStoreOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [chartWidth, setChartWidth] = useState(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth) * 0.5) - 50);

    useEffect(() => {
        const handleResize = () => {
            setChartWidth(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth) * 0.5) - 50);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const currencyFormatter = (value) => {
        return new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN'
        }).format(value);
    };

    useEffect(() => {
        if (!currentUser) return;
        const uid = currentUser.uid;
        if (!uid) return;

        const storeRef = collection(db, `users/${uid}/stores`);
        const fetchStores = async () => {
            setLoading(true);
            const snapshot = await getDocs(storeRef);

            const storesList = snapshot.docs.map(doc => ({
                id: doc.id,
                value: doc.data().custom_name,
                data: doc.data(),
                label: <div className='flex flex-row items-center'>
                    <img
                        src={doc.id.startsWith('ml')
                            ? `https://http2.mlstatic.com/frontend-assets/ui-navigation/5.18.5/mercadolibre/logo__large_plus.png`
                            : `https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Amazon.com-Logo.svg/1200px-Amazon.com-Logo.svg.png`
                        }
                        className='w-16 mr-4 object-contain'
                        alt=""
                    />
                    {doc.data().custom_name}
                </div>
            }));

            if (storesList.length === 0) {
                setLoading(false);
                return;
            }

            if (!store) setSelectedStore(storesList[0])
            setStoreOptions(storesList);
            setLoading(false);
        };

        fetchStores();
    }, [currentUser]);

    useEffect(() => {
        if (!selectedStore) return;

        const fetchData = async () => {
            setLoading(true);
            if (!currentUser) return;

            const uid = currentUser.uid;

            if (!uid) return;

            // Ajustar las fechas según la zona horaria seleccionada
            const { adjustedStart: adjustedStartDate, adjustedEnd: adjustedEndDate } = adjustDatesToTimezone(startDate, endDate, selectedTimezone.value);
            const { adjustedStart: adjustedComparisonStartDate, adjustedEnd: adjustedComparisonEndDate } = adjustDatesToTimezone(comparisonStartDate, comparisonEndDate, selectedTimezone.value);

            const ordersRef = collection(db, `users/${uid}/stores/${selectedStore.id}/orders`);
            const currentPeriodQuery = query(ordersRef,
                where('date_timestamp', '>=', Timestamp.fromDate(adjustedStartDate)),
                where('date_timestamp', '<=', Timestamp.fromDate(adjustedEndDate))
            );
            const comparisonPeriodQuery = query(ordersRef,
                where('date_timestamp', '>=', Timestamp.fromDate(adjustedComparisonStartDate)),
                where('date_timestamp', '<=', Timestamp.fromDate(adjustedComparisonEndDate))
            );

            const [currentSnapshot, comparisonSnapshot] = await Promise.all([
                getDocs(currentPeriodQuery),
                getDocs(comparisonPeriodQuery)
            ]);

            const currentOrders = currentSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            const comparisonOrders = comparisonSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

            const currentAggregated = aggregateData(currentOrders, adjustedStartDate, adjustedEndDate, selectedChartInterval.value);
            const comparisonAggregated = aggregateData(comparisonOrders, adjustedComparisonStartDate, adjustedComparisonEndDate, selectedChartInterval.value);

            const chartData = currentAggregated.map((currentItem, index) => {
                const comparisonItem = comparisonAggregated[index] || { sales: 0, orders: 0 };
                return {
                    date: currentItem.date,
                    currentSales: currentItem.sales,
                    currentOrders: currentItem.orders,
                    comparisonSales: comparisonItem.sales,
                    comparisonOrders: comparisonItem.orders
                };
            });

            setData(chartData);
            setLoading(false);
        };

        fetchData();
    }, [selectedStore, startDate, endDate, comparisonStartDate, comparisonEndDate, selectedChartInterval, currentUser, selectedTimezone]);

    const calculateDarkerTone = (color) => {
        const hex = color.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        const factor = 0.7;
        const newR = Math.floor(r * factor);
        const newG = Math.floor(g * factor);
        const newB = Math.floor(b * factor);

        // Usamos padStart para asegurar que cada valor tenga al menos dos dígitos
        const newRHex = newR.toString(16).padStart(2, '0');
        const newGHex = newG.toString(16).padStart(2, '0');
        const newBHex = newB.toString(16).padStart(2, '0');

        return `#${newRHex}${newGHex}${newBHex}`;
    };

    const { currentColor, previousColor } = {
        currentColor: (selectedStore?.data?.color || "#8884d8"),
        previousColor: calculateDarkerTone(selectedStore?.data?.color || "#8884d8")
    };
    // getColorScheme(selectedStore?.value

    const mesesEnEspanol = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            const date = new Date(data.date);
            let formattedDate;
            switch (selectedChartInterval.value) {
                case 'SEMANA':
                    formattedDate = `Semana del ${date.toLocaleDateString()}`;
                    break;
                case 'MES':
                    formattedDate = `${mesesEnEspanol[date.getMonth()]} ${date.getFullYear()}`;
                    break;
                case 'ANO':
                    formattedDate = date.getFullYear().toString();
                    break;
                default:
                    formattedDate = date.toLocaleDateString();
            }

            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                    <p className="label text-sm font-semibold">{formattedDate}</p>
                    <p style={{ color: currentColor }}>{`Ventas periodo actual: ${currencyFormatter(data.currentSales)}`}</p>
                    <p style={{ color: calculateDarkerTone(currentColor) }}>{`Órdenes periodo actual: ${data.currentOrders}`}</p>
                    <p style={{ color: previousColor }}>{`Ventas periodo comparación: ${currencyFormatter(data.comparisonSales)}`}</p>
                    <p style={{ color: calculateDarkerTone(previousColor) }}>{`Órdenes periodo comparación: ${data.comparisonOrders}`}</p>
                </div>
            );
        }

        return null;
    };

    const renderChart = () => {
        return (
            <BarChart
                width={chartWidth}
                height={300}
                data={data}
                margin={{ top: 20, right: 20, left: 40, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="date"
                    tickFormatter={(value) => {
                        const date = new Date(value);
                        switch (selectedChartInterval.value) {
                            case 'SEMANA':
                                return `Sem ${date.getDate()}/${date.getMonth() + 1}`;
                            case 'MES':
                                return date.toLocaleString('default', { month: 'short' });
                            case 'ANO':
                                return date.getFullYear().toString();
                            default:
                                return date.toLocaleDateString();
                        }
                    }}
                />

                <YAxis tickFormatter={currencyFormatter} />
                <RechartsTooltip content={<CustomTooltip />} />
                <Legend />
                {!ordersGraph ?
                    <>
                        <Bar dataKey="currentSales" fill={currentColor} name="Ventas Periodo Actual" />
                        <Bar dataKey="comparisonSales" fill={previousColor} name="Ventas Periodo Comparación" />
                    </>
                    :
                    <>
                        <Bar dataKey="currentOrders" fill={calculateDarkerTone(currentColor)} name="Órdenes Periodo Actual" />
                        <Bar dataKey="comparisonOrders" fill={calculateDarkerTone(previousColor)} name="Órdenes Periodo Comparación" />
                    </>
                }
            </BarChart>
        );
    };

    useEffect(() => {
        if (storeOptions.length > 0 && !selectedStore && !store && !loading) {
            setSelectedStore(storeOptions[0]); // Ajusta esto para establecer el objeto completo, no solo el value
        }
    }, [storeOptions, selectedStore]);

    const aggregateData = (orders, startDate, endDate, interval) => {
        const aggregated = {};

        orders.forEach(order => {
            const date = order.date_timestamp.toDate();
            let key;

            switch (interval) {
                case 'SEMANA':
                    const weekStart = new Date(date);
                    weekStart.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1));
                    key = weekStart.toISOString().split('T')[0];
                    break;
                case 'MES':
                    key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
                    break;
                case 'ANO':
                    key = `${date.getFullYear()}`;
                    break;
                default: // 'DIA'
                    key = date.toISOString().split('T')[0];
            }

            if (!aggregated[key]) {
                aggregated[key] = { sales: 0, orders: 0 };
            }
            aggregated[key].sales += parseFloat(order.total_order_amount || 0);
            aggregated[key].orders += 1;
        });

        // Fill in missing intervals
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            let key;
            switch (interval) {
                case 'SEMANA':
                    const weekStart = new Date(currentDate);
                    weekStart.setDate(currentDate.getDate() - currentDate.getDay() + (currentDate.getDay() === 0 ? -6 : 1));
                    key = weekStart.toISOString().split('T')[0];
                    currentDate.setDate(currentDate.getDate() + 7);
                    break;
                case 'MES':
                    key = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
                    currentDate.setMonth(currentDate.getMonth() + 1);
                    break;
                case 'ANO':
                    key = `${currentDate.getFullYear()}`;
                    currentDate.setFullYear(currentDate.getFullYear() + 1);
                    break;
                default: // 'DIA'
                    key = currentDate.toISOString().split('T')[0];
                    currentDate.setDate(currentDate.getDate() + 1);
            }
            if (!aggregated[key]) {
                aggregated[key] = { sales: 0, orders: 0 };
            }
        }

        return Object.entries(aggregated).map(([key, value]) => ({
            date: key,
            ...value
        })).sort((a, b) => new Date(a.date) - new Date(b.date));
    };

    return (
        <div className="flex flex-col items-start justify-start bg-white rounded-lg shadow-md m-1" style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
            <div className="flex flex-col md:flex-row items-center px-5 text-kompamxblue text-base justify-center md:justify-between w-full" style={{ minHeight: "3.5rem" }}>
                <div className="flex items-center justify-start w-full">
                    <div className="font-semibold mr-2">
                        {ordersGraph ? "Órdenes" : "Ventas"} de Periodos
                    </div>
                    <Tooltip title={"Comparación de ventas de los últimos 6 meses"}>
                        <InfoOutlined style={{ fontSize: 18 }} className='ml-auto mr-2' />
                    </Tooltip>
                    {!store &&
                        <Select
                            options={storeOptions}
                            onChange={option => setSelectedStore(option)} // Aquí pasas el objeto completo a setSelectedStore
                            value={selectedStore} // Aquí pasas el objeto completo que representa la tienda seleccionada
                            placeholder="Selecciona una tienda"
                        // components={{ Option: IconOption }}
                        />
                    }
                </div>
            </div>
            <hr className="w-full border-gray-300 mb-2" />

            <div className="flex items-center md:mt-0 w-full justify-center px-2 mb-2 h-full">
                {loading ? <div className="spinner" /> : selectedStore ? renderChart() :
                    <div className="flex justify-center items-center h-full w-full">
                        <p className="text-gray-500 text-lg">No hay datos para mostrar</p>
                    </div>
                }
            </div>
        </div>
    );
};

export default PeriodComparisonChart;