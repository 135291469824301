import React from 'react';
import Select from 'react-select';

function StoreSelector({ stores, selectedStore, onStoreChange }) {
    return (
        <Select
            options={stores}
            className='w-full'
            placeholder='Selecciona una tienda'
            value={selectedStore}
            onChange={onStoreChange}
        />
    );
}

export default StoreSelector;