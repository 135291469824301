import { DragIndicator } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '../helpers/ripple.css';

const styles = {
    menuContainer: {
        position: 'absolute',
        backgroundColor: '#ffffff',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
        borderRadius: '4px',
        overflow: 'hidden',
        zIndex: 97,
        width: '350px',
        opacity: 0, // Inicia oculto
        transform: 'scale(0.7)', // Comienza desde un escalamiento menor
        transformOrigin: 'top right', // Punto de origen para el escalamiento
        transition: 'opacity 0.5s ease, transform 0.5s ease', // Suaviza la transición
    },
    menuItem: {
        padding: '5px 10px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    menuItemHover: {
        backgroundColor: '#f0f0f0',
    },
    divider: {
        height: '1px',
        backgroundColor: '#e0e0e0',
        margin: '5px 0',
    },
    menuHeader: {
        padding: '10px 20px',
        color: '#333',
        fontSize: '14px',
    },
    checkboxContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    checkboxInput: {
        marginRight: '10px',
    },
};

const CustomMenuItem = ({ id, index, label, isVisible, moveItem, onClick }) => {
    const ref = useRef(null);
    const [isHovering, setIsHovering] = useState(false);

    const [, drag] = useDrag({ type: 'menu-item', item: { id, index } });
    const [, drop] = useDrop({
        accept: 'menu-item',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            moveItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    drag(drop(ref));

    return (
        <div ref={ref}
            className="menu-item"
            style={{
                ...styles.menuItem,
                ...(isHovering ? styles.menuItemHover : {})
            }}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onClick={onClick}
        >
            <div className='text-kompamxblue opacity-20 hover:opacity-100 transition-all duration-300 cursor-move mr-2'>
                <DragIndicator />
            </div>
            <div style={styles.checkboxContainer}>
                <input type="checkbox" style={styles.checkboxInput} checked={isVisible} readOnly />
                <div className="text-kompamxblue text-sm">
                    {label}
                </div>
            </div>
        </div>
    );
};


export default function CustomMenu({ charts, setCharts, anchorPosition, menuIsVisible, setMenuIsVisible }) {
    const menuRef = useRef();

    const backdropStyles = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.0)', // Fondo ligeramente oscurecido
        zIndex: 96, // Menor que el zIndex del menú para que aparezca detrás
        display: menuIsVisible ? 'block' : 'none',
    };

    useEffect(() => {
        // Actualiza el ancho del menú cuando sea necesario (aunque en este caso, el ancho está fijado en los estilos)
        // No es necesario calcular el ancho si ya lo has definido en los estilos como 300px.
        // setMenuWidth(menuRef.current.offsetWidth);

        // Deshabilita scroll cuando el menú esté visible
        if (menuIsVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Función para manejar clics fuera del menú
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuIsVisible(false);
            }
        }

        // Agregar escuchador para clics fuera del menú
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Restaura el scroll cuando el componente se desmonte o el menú se oculte
            document.body.style.overflow = 'auto';
            // Asegúrate de limpiar el event listener cuando el componente se desmonte
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuIsVisible, setMenuIsVisible]);

    const updateChartVisibility = (index) => {
        const updatedCharts = charts.map((chart, i) => {
            if (i === index) {
                return { ...chart, visible: !chart.visible };
            }
            return chart;
        });
        setCharts(updatedCharts);
    };

    // Asegúrate de que menuStyle calcule la posición cada vez que menuWidth o anchorPosition cambien
    const menuStyle = {
        ...styles.menuContainer,
        left: `${anchorPosition.x - 350}px`,
        top: `${anchorPosition.y}px`,
        opacity: menuIsVisible ? 1 : 0,
        transform: menuIsVisible ? 'scale(1)' : 'scale(0.7)',
        visibility: menuIsVisible ? 'visible' : 'hidden',
        transition: `opacity 0.2s ease, transform 0.2s ease, visibility 0s ${menuIsVisible ? '0s' : '0.2s'}`,
        paddingBottom: '5px',
    };

    const moveItem = (dragIndex, hoverIndex) => {
        const dragItem = charts[dragIndex];
        const newCharts = [...charts];
        newCharts.splice(dragIndex, 1); // Remueve el item arrastrado
        newCharts.splice(hoverIndex, 0, dragItem); // Inserta el item arrastrado en su nueva posición

        setCharts(newCharts);
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <>
                {menuIsVisible && <div style={backdropStyles} onClick={() => setMenuIsVisible(false)} />}
                <div ref={menuRef} style={menuStyle}>
                    <p className="mx-4 my-4 select-none text-kompamxblue text-sm font-semibold">
                        Ocultar o Reordenar Gráficas
                    </p>
                    <div style={styles.divider}></div>
                    {charts.map((chart, index) => (
                        <CustomMenuItem
                            key={chart.id}
                            id={chart.id}
                            index={index}
                            label={chart.label}
                            isVisible={chart.visible}
                            moveItem={moveItem}
                            onClick={() => updateChartVisibility(index)}
                        />
                    ))}
                </div>
            </>
        </DndProvider>
    );
}
