import { ArrowDropDown, Language, Timeline, Today } from '@mui/icons-material';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import TFFirstSection from './TFFirstSection';
import TFThirdSection from './TFThirdSection';
import { useTopbar } from '../../helpers/TopbarContext';
import moment from 'moment-timezone';

const TimeFilter = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [anchorPosition, setAnchorPosition] = useState({ x: 0, y: 0 });
    const [selectedColor, setSelectedColor] = useState('gray-500');
    const [hovered, setHovered] = useState(false);
    const [menuIsVisible, setMenuIsVisible] = useState(false);

    const menuRef = useRef(null);
    // const location = useLocation();

    const isWideScreen = screenWidth > 1268; //  && !location.pathname.startsWith('/mis-tiendas') && !location.pathname.startsWith('/comparador');

    const backdropStyles = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.2)', // Fondo ligeramente oscurecido
        zIndex: 99, // Menor que el zIndex del menú para que aparezca detrás
        display: menuIsVisible ? 'block' : 'none',
    };

    const menuWidth = isWideScreen ? 800 : 400;

    const menuStyle = {
        position: 'absolute',
        backgroundColor: '#ffffff',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.3)',
        borderRadius: '9px',
        overflow: 'hidden',
        zIndex: 100,
        width: `${menuWidth}px`,
        // height: `${menuHeight}px`,
        transformOrigin: 'top right', // Punto de origen para el escalamiento
        left: `${anchorPosition.x - menuWidth}px`,
        top: `${anchorPosition.y}px`,
        opacity: menuIsVisible ? 1 : 0,
        transform: menuIsVisible ? 'scale(1)' : 'scale(0.7)',
        visibility: menuIsVisible ? 'visible' : 'hidden',
        transition: `opacity 0.2s ease, transform 0.2s ease, visibility 0s ${menuIsVisible ? '0s' : '0.2s'}`,
    };

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (menuIsVisible) {
            setSelectedColor('gray-600');
        } else if (hovered) {
            setSelectedColor('gray-400');
        } else {
            setSelectedColor('gray-500');
        }
    }, [hovered, menuIsVisible]);

    const handleMenuClick = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setAnchorPosition({
            x: rect.right,
            y: rect.bottom + 5,
        });
        setMenuIsVisible(!menuIsVisible);
    };

    const {
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        comparisonStartDate,
        setComparisonStartDate,
        comparisonEndDate,
        setComparisonEndDate,
        selectedDatePeriod,
        selectedComparisonPeriod,
        showCustom,
        setShowCustom,
        setNewDatePeriod,
        datePeriodOptions,
        selectedTimezone,
    } = useTopbar();


    const DatePeriodOption = ({ title, value }) => {
        return (
            <div
                className={`flex items-center cursor-pointer justify-between border-b border-gray-200 text-xs font-normal px-4 py-2 text-black ${selectedDatePeriod.value === value ? 'bg-gray-200 border-l-4 border-l-gray-500 font-semibold' : 'hover:bg-gray-100'}`}
                onClick={() => {
                    setNewDatePeriod(true, { title, value }, false);
                }}
            >
                <span className='select-none'>{title}</span>
            </div>
        );
    }


    return (
        <>
            {menuIsVisible && <div style={backdropStyles} onClick={() => setMenuIsVisible(false)} />}

            <div ref={menuRef} style={menuStyle} className="flex flex-row">
                {isWideScreen &&
                    <TFFirstSection />
                }
                <div className={`bg-white flex flex-col border-l border-r border-gray-200 ${isWideScreen ? 'w-[25%]' : 'w-full'}`}>
                    {datePeriodOptions.filter(option => showCustom || option.value !== 'custom').map((option) => (
                        <DatePeriodOption title={option.title} value={option.value} key={option.value} />
                    ))}
                </div>
                {isWideScreen &&
                    <TFThirdSection
                        startDate={startDate}
                        endDate={endDate}
                        comparisonStartDate={comparisonStartDate}
                        comparisonEndDate={comparisonEndDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setComparisonStartDate={setComparisonStartDate}
                        setComparisonEndDate={setComparisonEndDate}
                        setShouldShowCustom={setShowCustom}
                    />
                }
            </div>

            <div
                className={`relative ${isWideScreen ? '' : ''} font-semibold text-sm text-${selectedColor} cursor-pointer`}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={handleMenuClick}
            >
                <div className={`flex items-center justify-center border-2 rounded-lg transition-all duration-100 ${menuIsVisible ? 'border-gray-600' : hovered ? 'border-gray-400' : 'border-gray-500'}`}>
                    <div className="flex items-center justify-center flex-1 px-4 py-1">
                        <Language className="mr-3 mb-0.5" size={20} />
                        {isWideScreen && <span className='whitespace-nowrap text-xs'>
                            {moment.tz.zone(selectedTimezone.value).abbr(moment().valueOf())}
                        </span>}
                    </div>
                    <div className={`h-6 border-l-2 border-${selectedColor}`} />
                    <div className="flex items-center justify-center flex-1 px-4 py-1">
                        <Today className="mr-3 mb-0.5" size={20} />
                        {isWideScreen && <span className=' whitespace-nowrap text-xs'>
                            {selectedDatePeriod.title}
                        </span>}
                    </div>
                    <div className={`h-6 border-l-2 border-${selectedColor}`} />
                    <div className="flex items-center justify-center flex-1 px-4 py-1">
                        <Timeline className="mr-3 mb-0.5" size={20} />
                        {isWideScreen && <span className="mr-8 whitespace-nowrap text-xs">
                            {selectedComparisonPeriod.label}
                        </span>}
                    </div>
                </div>
                <ArrowDropDown className="absolute top-1/2 right-1.5 transform -translate-y-1/2" size={24} />
            </div>
        </>
    );
};

export default TimeFilter;
