import React from 'react';

function ProductRow({ data, dataIndex, columnsOrder, visibleColumns, getClassNamesForColumn, renderColumnData }) {
    return (
        <div key={dataIndex} className="p-2 flex items-center space-x-2 border-b border-gray-200">
            {columnsOrder.map((columnId, columnIndex) => (
                visibleColumns[columnId] && <div key={columnId + columnIndex} className={getClassNamesForColumn(columnId)}>
                    {renderColumnData(data, columnId)}
                </div>
            ))}
        </div>
    );
}

export default ProductRow;