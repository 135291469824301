import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyBW2ZldHIyAh9U2VX2N6cdbtWydM6x7VKo",
    authDomain: "goodz-project.firebaseapp.com",
    projectId: "goodz-project",
    storageBucket: "goodz-project.appspot.com",
    messagingSenderId: "14810196169",
    appId: "1:14810196169:web:b95db06bb7e2bf7ae74518",
    measurementId: "G-9EKE967MLS"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider, db, functions, storage };
