import React, { useState, useEffect } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DragIndicator, ArrowDropUp, ArrowDropDown, Search, ViewWeek, FirstPage, NavigateBefore, NavigateNext, LastPage, Launch } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Checkbox, Divider, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { collection, getDocs, query, orderBy, limit, doc, setDoc, getDoc, documentId, startAfter, where } from 'firebase/firestore';
import { db, auth } from '../helpers/firebase';
import { ToastContainer, toast } from 'react-toastify';

const DraggableColumnHeader = ({ name, type, id, moveColumn, onSortChange, sortState, onDrop }) => {


    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: type,
        item: { id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const [, drop] = useDrop({
        accept: type,
        hover(item, monitor) {
            if (item.id !== id) {
                moveColumn(item.id, id, false); // false indicates not to perform the final drop operation yet
            }
        },
        drop(item, monitor) {
            onDrop(item.id, id); // Perform the final drop operation, including Firestore update
        },
    });

    const handleSort = () => {
        onSortChange(id);
    };
    if (id !== 'producto') {
        if (id === 'productos') { id = 'producto' }
        return (
            <div
                ref={(node) => {
                    drop(node);
                    preview(node);
                }}
                style={{ marginLeft: -2, opacity: isDragging ? 0.5 : 1, borderRight: '2px solid #ccc', height: 45 }}
                className={`draggable-column-header relative text-gray-500 text-sm font-semibold uppercase flex flex-row items-center ${name === "Producto" ? "w-96" : "w-48"} ${name === "Producto" ? "text-left justify-start pl-4" : "text-right justify-center"}`}
            >
                <div ref={drag} className={`cursor-move text-transparent ${name === "Producto" ? "hover:text-transparent" : "hover:text-gray-500"} transition-all duration-300 absolute left-0`}>
                    <DragIndicator />
                </div>
                <span onClick={handleSort} className="flex items-center cursor-pointer ">
                    {name}
                    {sortState.column === id && sortState.direction === 'asc' ? <ArrowDropUp /> : sortState.column === id && sortState.direction === 'desc' ? <ArrowDropDown /> : null}
                </span>
                {name === "ACOS" && (
                    <Tooltip title="Advertising Cost of Sales (Costo de venta de publicidad)">
                        <IconButton aria-label="info" size="small">
                            <InfoOutlined fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                )}
                {name === "CTR" && (
                    <Tooltip title="Click-Through Rate (Tasa de clicks)">
                        <IconButton aria-label="info" size="small">
                            <InfoOutlined fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                )}
                {name === "CPC" && (
                    <Tooltip title="Cost Per Click (Costo por clic)">
                        <IconButton aria-label="info" size="small">
                            <InfoOutlined fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                )}
                {name === "Conv" && (
                    <Tooltip title="Conversiones">
                        <IconButton aria-label="info" size="small">
                            <InfoOutlined fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                )}
                {name === "ROAS" && (
                    <Tooltip title="Return on Advertising Spend (Retorno de la inversión publicitaria)">
                        <IconButton aria-label="info" size="small">
                            <InfoOutlined fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
        );
    }
};

function getClassNamesForColumn(columnId) {
    // Retorna las clases CSS basadas en el ID de la columna, ajusta según tus necesidades
    switch (columnId) {
        case 'producto':
            return 'w-96 border-b-2 border-gray-100';
        default:
            return 'w-48 border-b-2 border-gray-100';
    }
}

const ProductsCardComparativa = ({ passedStore, relation }) => {
    const [stores, setStores] = useState([])
    const [store, setStore] = useState(passedStore);

    useEffect(() => {
        setStore(passedStore);
    }, [passedStore]);

    const [columnsMenuAnchorEl, setColumnsMenuAnchorEl] = useState(null);
    const [campaignMenuAnchorEl, setCampaignMenuAnchorEl] = useState(null);
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [visibleColumns, setVisibleColumns] = useState({
        venta_de_anuncios: true,
        gasto: true,
        acos: true,
        impresiones: true,
        clicks: true,
        ctr: true,
        cpc: true,
        conv: true,
        pedidos: true,
        unidades: true,
        roas: true,
    });
    const [includeCampaigns, setIncludeCampaigns] = useState([]);
    const [loadingColumnOrder, setLoadingColumnOrder] = useState(true);
    const [sortState, setSortState] = useState({ column: null, direction: 'desc' });
    const [storeListings, setStoreListings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const listingsPerPage = 10; // Ajusta según tus necesidades

    // Estado para manejar el total de listados
    const [totalListings, setTotalListings] = useState(0);

    useEffect(() => {
        setIncludeCampaigns(store && store.data && store.data.campanas ? store.data.campanas : []);
    }, [store]);


    useEffect(() => {
        if (!store) {
            return;
        }

        if (store.data.total_productos) {
            setTotalListings(store.data.total_productos);
            return;
        }

        const fetchTotalListings = async () => {
            const listingsRef = collection(db, 'users', auth.currentUser.uid, 'relatedStores', relation.id, 'relations');
            const querySnapshot = await getDocs(listingsRef);
            // setDoc store Doc with merge True with field total_productos
            const totalListings = querySnapshot.size;
            const storeDocRef = doc(db, 'users', auth.currentUser.uid, 'stores', store.id);
            await setDoc(storeDocRef, { total_productos: totalListings }, { merge: true });
            setTotalListings(querySnapshot.size); // Actualiza el estado con el total de documentos
        };

        fetchTotalListings();
    }, [store]); // Este efecto depende del store, se ejecutará cada vez que el store cambie

    const totalPages = Math.ceil(totalListings / listingsPerPage);

    const filteredData = storeListings.filter((data) =>
        data.producto.toLowerCase().includes(searchText.toLowerCase()) ||
        data.asin.toLowerCase().includes(searchText.toLowerCase())
    );

    const onSortChange = (columnId) => {
        setSortState((prevState) => {
            if (prevState.column === columnId) {
                return { ...prevState, direction: prevState.direction === 'desc' ? 'asc' : 'desc' };
            } else {
                return { column: columnId, direction: 'desc' };
            }
        });
        setCurrentPage(0);
        // setAnchorDocs([]);
    };

    useEffect(() => {
        if (sortState.column !== null) {
            const sorted = [...filteredData].sort((a, b) => {
                if (a[sortState.column] < b[sortState.column]) {
                    return sortState.direction === 'asc' ? -1 : 1;
                }
                if (a[sortState.column] > b[sortState.column]) {
                    return sortState.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
            setStoreListings(sorted);
        }
    }, [sortState]);

    const handleClickChooseColumns = (event) => {
        setColumnsMenuAnchorEl(event.currentTarget);
    };


    const handleCloseChooseColumns = () => {
        setColumnsMenuAnchorEl(null);
    };

    // Función para manejar el clic en el botón "Elegir Campañas"
    const handleClickChooseCampaigns = (event) => {
        setCampaignMenuAnchorEl(event.currentTarget);
    };

    // Función para cerrar el menú de campañas
    const handleCloseCampaignMenu = () => {
        setCampaignMenuAnchorEl(null);
    };

    const handleColumnToggle = (column) => {
        setVisibleColumns({
            ...visibleColumns,
            [column]: !visibleColumns[column],
        });
    };

    const handleAllColumnsToggle = () => {
        const allVisible = Object.values(visibleColumns).every((column) => column);
        const newVisibleColumns = {};
        for (const key in visibleColumns) {
            newVisibleColumns[key] = !allVisible;
        }
        setVisibleColumns(newVisibleColumns);
    };

    const handleChooseCampaign = (campaign) => {
        setIncludeCampaigns((prevState) => {
            if (prevState.includes(campaign)) {
                return prevState.filter((item) => item !== campaign);
            } else {
                return [...prevState, campaign];
            }
        });
    }

    const handleAllCampaignsToggle = () => {
        if (includeCampaigns.length === store.data.campanas.length) {
            setIncludeCampaigns([]);
        } else {
            setIncludeCampaigns(store.data.campanas);
        }
    }

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const columnNames = {
        producto: "Producto",
        venta_de_anuncios: "Venta de Anuncios",
        gasto: "Gasto",
        acos: "ACOS",
        impresiones: "Impresiones",
        clicks: "Clicks",
        ctr: "CTR",
        cpc: "CPC",
        conv: "Conv",
        pedidos: "Pedidos",
        unidades: "Unidades",
        roas: "ROAS",
    };

    const [columnsOrder, setColumnsOrder] = useState([
        'producto', 'venta_de_anuncios', 'gasto', 'acos', 'impresiones', 'clicks', 'ctr', 'cpc', 'conv', 'pedidos', 'unidades', 'roas'
    ]);

    const moveColumn = async (draggedId, overId, finalizeDrop = false) => {
        const newOrder = [...columnsOrder];
        const draggedIndex = newOrder.indexOf(draggedId);
        const overIndex = newOrder.indexOf(overId);
        if (draggedIndex >= 0 && overIndex >= 0) {
            newOrder.splice(draggedIndex, 1);
            newOrder.splice(overIndex, 0, draggedId);
            setColumnsOrder(newOrder);

            if (finalizeDrop) {
                const uid = auth.currentUser?.uid;
                if (uid) {
                    const userDocRef = doc(db, `users/${uid}`);
                    try {
                        await setDoc(userDocRef, { columnsOrderField: newOrder }, { merge: true });
                        toast.success("¡Nuevo orden de las columnas guardado exitosamente!");
                    } catch (error) {
                        toast.error("Ocurrió un error al guardar el orden.");
                    }
                }
            }
        }
    };

    const onDrop = async (draggedId, overId) => {
        moveColumn(draggedId, overId, true); // true indicates to finalize the drop and update Firestore
    };

    const saveInitialColumnOrder = async (columnsOrder) => {
        const uid = auth.currentUser?.uid;
        if (!uid) return;

        const userDocRef = doc(db, `users/${uid}`);
        await setDoc(userDocRef, { columnsOrderField: columnsOrder }, { merge: true });
    };

    useEffect(() => {
        const uid = auth.currentUser?.uid;
        if (!uid) return;

        const fetchAndInitializeColumnsOrder = async () => {
            setLoadingColumnOrder(true);
            const userDocRef = doc(db, `users/${uid}`);
            const docSnap = await getDoc(userDocRef);

            // Si ya existe un orden de gráficas, lo usamos para establecer el estado inicial
            if (docSnap.exists() && docSnap.data().columnsOrderField) {
                const { columnsOrderField } = docSnap.data();
                // Reordenar `columnsOrders` basado en `columnsOrderField`
                setColumnsOrder(columnsOrderField);
            } else {
                // Si no existe un orden, lo guardamos en Firestore
                await saveInitialColumnOrder(columnsOrder);
            }
            setLoadingColumnOrder(false);
        };

        fetchAndInitializeColumnsOrder();
    }, []); // Dependencias vacías para que se ejecute solo una vez al montar el componente

    const [anchorDocs, setAnchorDocs] = useState([]);

    const fetchListings = async () => {
        if (!store) {
            return;
        }

        setLoading(true);
        setStoreListings([]); // Limpia los listados actuales

        let startAfterId
        if (currentPage > 0) {
            startAfterId = anchorDocs[currentPage - 1];
        }


        const storeRef = collection(db, 'users', auth.currentUser.uid, 'relatedStores', relation.id, 'relations');

        let q;

        if (startAfterId) {
            q = query(
                storeRef,
                orderBy(sortState.column || documentId(), sortState.direction || 'desc'),
                startAfter(startAfterId),
                limit(listingsPerPage)
            );
        } else {
            q = query(
                storeRef,
                orderBy(sortState.column || documentId(), sortState.direction || 'desc'),
                limit(listingsPerPage)
            );
        }

        const querySnapshot = await getDocs(q);

        // Maneja la respuesta, creando la lista de listados
        const listings = querySnapshot.docs.map(doc => {
            const data = doc.data();

            return {
                ...data,
                id: doc.id || "-",
                producto: data.producto || "-",
                asin: data.asin || data.listing_id || "-",
                venta_de_anuncios: data.venta_de_anuncios || "-",
                gasto: data.gasto || "-",
                acos: data.acos || "-",
                impresiones: data.impresiones || "-",
                clicks: data.clicks || "-",
                ctr: data.ctr || "-",
                cpc: data.cpc || "-",
                conv: data.conv || "-",
                pedidos: data.pedidos || "-",
                unidades: data.unidades || "-",
                roas: data.roas || "-",
                thumbnail: data.thumbnail || "https://via.placeholder.com/50",
                store: store,

            };
        });

        setStoreListings(listings);

        if (listings.length === 0) {
            setLoading(false);
            return;
        }

        // if last listing is not on anchorDocs, add it
        if (currentPage === 0) {
            setAnchorDocs([listings[0].id]);
        } else if (anchorDocs.indexOf(listings[listings.length - 1].id) === -1) {
            setAnchorDocs([...anchorDocs, listings[listings.length - 1].id]);
        }

        setLoading(false);
    };

    useEffect(() => {
        // Cargar listados para la tienda seleccionada
        fetchListings();
    }, [store, currentPage, sortState]); // Dependencia en la tienda seleccionada y la página actual

    // if store changes, reset currentPage to 0
    useEffect(() => {
        // Restablecer estados relevantes
        setCurrentPage(0);
        setStoreListings([]);
    }, [store]); // Dependencia en la tienda seleccionada    

    function renderColumnData(data, columnId) {
        if (!store) {
            return;
        }
        if (columnId === 'producto') { return }
        if (columnId === 'productos') { columnId = 'producto' }

        // Retorna el contenido a renderizar basado en el ID de la columna y los datos
        // Ejemplo:
        switch (columnId) {
            case 'producto':
                return (
                    <div className="w-96 pl-4 flex flex-row" style={{ marginLeft: -2, borderRight: '2px solid #ccc', height: 60, alignItems: "center" }}>
                        <img src={data.thumbnail} alt="Producto" className="w-10 h-10" />
                        <div style={{ marginLeft: 10 }}>
                            <a
                                href={!store.id.startsWith('ml') ?
                                    `https://www.amazon.com.mx/dp/${data.asin1}`
                                    :
                                    data.listing_permalink
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-row items-center justify-start"
                            >
                                <p
                                    className="text-sm text-blue-500 underline w-60 truncate"
                                >
                                    {data.producto}
                                </p>

                                <Launch
                                    className="cursor-pointer ml-2"
                                    style={{ fontSize: 15 }}
                                />
                            </a>

                            <p
                                className="text-sm"
                            >
                                { // if it does not start with MLM then show ASIN
                                    !data.asin.startsWith('MLM') ? `ASIN: ${data.asin}` :
                                        // if it starts with MLM then show SKU
                                        `SKU: ${data.asin}`
                                }
                            </p>
                        </div>
                    </div>
                );
            case 'venta_de_anuncios':
                return (
                    <div className="w-48 flex flex-row" style={{ marginLeft: -10, borderRight: '2px solid #ccc', height: 60, alignItems: "center" }}>
                        <div className="flex w-24" style={{ borderRight: '1px solid #ccc', height: 45, alignItems: "center" }}>
                            <p className="w-full text-center" style={{ verticalAlign: 'flex-center', color: (colors.length > 0 && colors[0]) || 'gray' }}>
                                {data.from && data.from.venta_de_anuncios ? `$${data.from.venta_de_anuncios.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}
                            </p >
                        </div>
                        <div className="flex w-24" style={{ height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 1 && colors[1]) || 'gray' }}>
                                {data.to && data.to.venta_de_anuncios ? `$${data.to.venta_de_anuncios.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}
                            </p >
                        </div>
                    </div>
                )
            case 'gasto':
                return (
                    <div className="w-48 flex flex-row" style={{ marginLeft: -10, borderRight: '2px solid #ccc', height: 60, alignItems: "center" }}>
                        <div className="flex w-24" style={{ borderRight: '1px solid #ccc', height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 0 && colors[0]) || 'gray' }}>
                                {data.from && data.from.gasto ? `$${data.from.gasto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}
                            </p >
                        </div>
                        <div className="flex w-24" style={{ height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 1 && colors[1]) || 'gray' }}>
                                {data.to && data.to.gasto ? `$${data.to.gasto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}
                            </p >
                        </div>
                    </div>)
            case 'acos':
                return (
                    <div className="w-48 flex flex-row" style={{ marginLeft: -10, borderRight: '2px solid #ccc', height: 60, alignItems: "center" }}>
                        <div className="flex w-24" style={{ borderRight: '1px solid #ccc', height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 0 && colors[0]) || 'gray' }}>
                                {data.from && data.from.acos ? `${data.from.acos.toFixed(2)}%` : "-"}
                            </p >
                        </div>
                        <div className="flex w-24" style={{ height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 1 && colors[1]) || 'gray' }}>
                                {data.to && data.to.acos ? `${data.to.acos.toFixed(2)}%` : "-"}
                            </p >
                        </div>
                    </div>)
            case 'impresiones':
                return (
                    <div className="w-48 flex flex-row" style={{ marginLeft: -10, borderRight: '2px solid #ccc', height: 60, alignItems: "center" }}>
                        <div className="flex w-24" style={{ borderRight: '1px solid #ccc', height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 0 && colors[0]) || 'gray' }}>
                                {data.from && data.from.impressions ? `${data.from.impressions.toLocaleString('en-US')}` : "-"}
                            </p >
                        </div>
                        <div className="flex w-24" style={{ height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 1 && colors[1]) || 'gray' }}>
                                {data.to && data.to.impressions ? `${data.to.impressions.toLocaleString('en-US')}` : "-"}
                            </p >
                        </div>
                    </div>)
            case 'clicks':
                return (
                    <div className="w-48 flex flex-row" style={{ marginLeft: -10, borderRight: '2px solid #ccc', height: 60, alignItems: "center" }}>
                        <div className="flex w-24" style={{ borderRight: '1px solid #ccc', height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 0 && colors[0]) || 'gray' }}>
                                {data.from && data.from.clicks ? `${data.from.clicks.toLocaleString('en-US')}` : "-"}
                            </p >
                        </div>
                        <div className="flex w-24" style={{ height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 1 && colors[1]) || 'gray' }}>
                                {data.to && data.to.clicks ? `${data.to.clicks.toLocaleString('en-US')}` : "-"}
                            </p >
                        </div>
                    </div>)
            case 'ctr':
                return (
                    <div className="w-48 flex flex-row" style={{ marginLeft: -10, borderRight: '2px solid #ccc', height: 60, alignItems: "center" }}>
                        <div className="flex w-24" style={{ borderRight: '1px solid #ccc', height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 0 && colors[0]) || 'gray' }}>
                                {data.from && data.from.ctr ? `${data.from.ctr.toFixed(2)}%` : "-"}
                            </p >
                        </div>
                        <div className="flex w-24" style={{ height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 1 && colors[1]) || 'gray' }}>
                                {data.to && data.to.ctr ? `${data.to.ctr.toFixed(2)}%` : "-"}
                            </p >
                        </div>
                    </div>)
            case 'cpc':
                return (
                    <div className="w-48 flex flex-row" style={{ marginLeft: -10, borderRight: '2px solid #ccc', height: 60, alignItems: "center" }}>
                        <div className="flex w-24" style={{ borderRight: '1px solid #ccc', height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 0 && colors[0]) || 'gray' }}>
                                {data.from && data.from.cpc ? `$${data.from.cpc.toFixed(2)}` : "-"}
                            </p >
                        </div>
                        <div className="flex w-24" style={{ height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 1 && colors[1]) || 'gray' }}>
                                {data.to && data.to.cpc ? `$${data.to.cpc.toFixed(2)}` : "-"}
                            </p >
                        </div>
                    </div>)
            case 'conv':
                return (
                    <div className="w-48 flex flex-row" style={{ marginLeft: -10, borderRight: '2px solid #ccc', height: 60, alignItems: "center" }}>
                        <div className="flex w-24" style={{ borderRight: '1px solid #ccc', height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 0 && colors[0]) || 'gray' }}>
                                {data.from && data.from.conv ? `${data.from.conv.toFixed(2)}%` : "-"}
                            </p >
                        </div>
                        <div className="flex w-24" style={{ height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 1 && colors[1]) || 'gray' }}>
                                {data.to && data.to.conv ? `${data.to.conv.toFixed(2)}%` : "-"}
                            </p >
                        </div>
                    </div>)
            case 'pedidos':
                return (
                    <div className="w-48 flex flex-row" style={{ marginLeft: -10, borderRight: '2px solid #ccc', height: 60, alignItems: "center" }}>
                        <div className="flex w-24" style={{ borderRight: '1px solid #ccc', height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 0 && colors[0]) || 'gray' }}>
                                {data.from && data.from.pedidos ? `${data.from.pedidos.toLocaleString('en-US')}` : "-"}
                            </p >
                        </div>
                        <div className="flex w-24" style={{ height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 1 && colors[1]) || 'gray' }}>
                                {data.to && data.to.pedidos ? `${data.to.pedidos.toLocaleString('en-US')}` : "-"}
                            </p >
                        </div>
                    </div>)
            case 'unidades':
                return (
                    <div className="w-48 flex flex-row" style={{ marginLeft: -10, borderRight: '2px solid #ccc', height: 60, alignItems: "center" }}>
                        <div className="flex w-24" style={{ borderRight: '1px solid #ccc', height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 0 && colors[0]) || 'gray' }}>
                                {data.from && data.from.unidades ? `${data.from.unidades.toLocaleString('en-US')}` : "-"}
                            </p >
                        </div>
                        <div className="flex w-24" style={{ height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 1 && colors[1]) || 'gray' }}>
                                {data.to && data.to.unidades ? `${data.to.unidades.toLocaleString('en-US')}` : "-"}
                            </p >
                        </div>
                    </div>)
            case 'roas':
                return (
                    <div className="w-48 flex flex-row" style={{ marginLeft: -10, borderRight: '2px solid #ccc', height: 60, alignItems: "center" }}>
                        <div className="flex w-24" style={{ borderRight: '1px solid #ccc', height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 0 && colors[0]) || 'gray' }}>
                                {data.from && data.from.roas ? `${data.from.roas.toFixed(2)}%` : "-"}
                            </p >
                        </div>
                        <div className="flex w-24" style={{ height: 45, alignItems: "center" }}>
                            <p className="w-full text-sm text-center" style={{ color: (colors.length > 1 && colors[1]) || 'gray' }}>
                                {data.to && data.to.roas ? `${data.to.roas.toFixed(2)}%` : "-"}
                            </p >
                        </div>
                    </div>)
            default:
                return null;
        }
    }

    const [tableWidth, setTableWidth] = useState((window.innerWidth - 75) * 0.9);

    useEffect(() => {
        const handleResize = () => {
            setTableWidth((window.innerWidth - 75) * 0.9);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        document.title = 'Informes | Kompa'

        const getUserStores = async () => {
            if (!auth.currentUser) return;
            const storesRef = collection(db, 'users', auth.currentUser.uid, 'stores');
            const storesSnapshot = await getDocs(storesRef);
            const storesList = storesSnapshot.docs.map(doc => {
                const store = doc.data();
                return {
                    id: doc.id,
                    value: store.custom_name,
                    data: store,
                    label: <div
                        className='flex flex-row items-center'
                    >
                        <img
                            // si empieza con ml usamos el logo de mercado libre
                            src={
                                doc.id.startsWith('ml') ?
                                    `https://http2.mlstatic.com/frontend-assets/ui-navigation/5.18.5/mercadolibre/logo__large_plus.png` :
                                    doc.id.startsWith('az') &&
                                    `https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Amazon.com-Logo.svg/1200px-Amazon.com-Logo.svg.png`
                            }
                            className='w-16 mr-4 object-contain'
                            alt="" />
                        {store.custom_name}
                    </div>
                }
            }
            );
            setStores(storesList);

            if (storesList.length > 0 && !store) {
                setStore(storesList[0]);
            }
        };

        getUserStores();
    }, []);

    const [colors, setColors] = useState([]);
    const [storeNames, setStoreNames] = useState([]);

    useEffect(() => {
        const fetchColors = async () => {
            // from relation.data.storeFrom and relation.data.storeTo
            const storeFrom = relation?.data?.storeFrom;
            const storeTo = relation?.data?.storeTo;

            if (!storeFrom || !storeTo) {
                return;
            }

            const storeFromRef = doc(db, 'users', auth.currentUser.uid, 'stores', storeFrom);
            const storeToRef = doc(db, 'users', auth.currentUser.uid, 'stores', storeTo);

            const storeFromDoc = await getDoc(storeFromRef);
            const storeToDoc = await getDoc(storeToRef);

            const storeFromData = storeFromDoc.data();
            const storeToData = storeToDoc.data();

            if (!storeFromData || !storeToData) {
                return;
            }

            const storeFromColor = storeFromData.color;
            const storeToColor = storeToData.color;

            if (!storeFromColor || !storeToColor) {
                return;
            }

            setColors([storeFromColor, storeToColor]);

            const storeFromName = storeFromData.custom_name;
            const storeToName = storeToData.custom_name;

            if (!storeFromName || !storeToName) {
                return;
            }

            setStoreNames([storeFromName, storeToName]);
        };

        fetchColors();
    }, [relation]);

    if (loadingColumnOrder) {
        return <div className='h-36 flex justify-center items-center'>
            <div className='spinner' />
        </div>;
    }

    return (
        <DndProvider backend={HTML5Backend}>

            <div
                className="p-4"
                style={{ width: tableWidth }}
            >

                <div className="rounded-lg shadow-lg bg-white w-full">
                    <div
                        className="flex flex-row items-center justify-between px-4 py-3"
                        id="productsHeader"
                    >
                        <h2 className="text-lg font-semibold">Productos</h2>
                        {colors.length > 1 && storeNames.length > 1 &&
                            <div
                                className="flex flex-row items-center"
                            >
                                <div
                                    className="w-3 h-3 mr-2"
                                    style={{ backgroundColor: colors[0] }}
                                />
                                <p className="text-sm mr-4">{storeNames[0]}</p>
                                <div
                                    className="w-3 h-3 mr-2"
                                    style={{ backgroundColor: colors[1] }}
                                />
                                <p className="text-sm">{storeNames[1]}</p>
                            </div>

                        }
                        <div className="flex flex-row items-center">
                            <div
                                className={`mr-2 flex flex-row border-b px-2 py-1 text-black bg-transparent box-content leading-normal  ${isSearchFocused ? "border-kompamx-300 border-b-2" : "hover:border-black hover:border-b-2 border-gray-300"}`}
                            >
                                <Search className="mr-2" />
                                <input
                                    type="text"
                                    placeholder="Buscar productos"
                                    className='w-full bg-transparent focus:outline-none'
                                    onFocus={() => {
                                        setIsSearchFocused(true);
                                    }}
                                    onBlur={() => {
                                        setIsSearchFocused(false);
                                    }}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            <div
                                className="text-black cursor-pointer mx-2 my-2 rounded-full hover:bg-gray-200 transition-all duration-200 p-1"
                                onClick={handleClickChooseColumns}
                            >
                                <ViewWeek />
                            </div>
                            {store && store.id.startsWith('ml') &&
                                <div
                                    className="cursor-pointer mx-2 my-2 rounded-full transition-all duration-200 px-3 py-1 text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white"
                                    onClick={handleClickChooseCampaigns}
                                >
                                    Elegir Campañas
                                </div>
                            }
                        </div>

                        {/* Menú de campañas */}
                        {store && store.id.startsWith('ml') &&
                            <Menu
                                id="campaign-menu"
                                anchorEl={campaignMenuAnchorEl}
                                keepMounted
                                open={Boolean(campaignMenuAnchorEl)}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                }}
                                onClose={handleCloseCampaignMenu}
                            >
                                <p className="text-black mx-4 my-2">
                                    Incluir Campañas
                                </p>

                                <Divider />

                                <MenuItem onClick={handleAllCampaignsToggle}>
                                    <Checkbox checked={store ? includeCampaigns.length === store.data.campanas.length : false} />
                                    Todas las campañas
                                </MenuItem>

                                {store && store.data && store.data.campanas && store.data.campanas.map((campana, index) => (
                                    <MenuItem key={index} onClick={() => handleChooseCampaign(campana)}>
                                        <Checkbox checked={includeCampaigns.includes(campana)} />
                                        {campana.name}
                                    </MenuItem>
                                ))}
                            </Menu>
                        }

                        {/* Menú de columnas */}
                        <Menu
                            anchorEl={columnsMenuAnchorEl}
                            open={Boolean(columnsMenuAnchorEl)}
                            onClose={handleCloseChooseColumns}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            getAnchorEl={null}
                        >
                            <p className="text-black mx-4 my-2">
                                Mostrar/Ocultar columnas
                            </p>

                            <Divider />

                            <MenuItem onClick={handleAllColumnsToggle}>
                                <Checkbox checked={Object.values(visibleColumns).every((column) => column)} />
                                Todas las columnas
                            </MenuItem>

                            {Object.entries(visibleColumns).map(([column, isVisible]) => (
                                <MenuItem key={column} onClick={() => handleColumnToggle(column)}>
                                    <Checkbox checked={isVisible} />
                                    {columnNames[column]}
                                </MenuItem>
                            ))}
                        </Menu>

                    </div>
                    <div className='flex flex-row'>
                        <div style={{ backgroundColor: 'transparent' }}>
                            <div className="table-wrapper">
                                <div
                                    className='bg-gray-100 flex h-12 border-gray-200'
                                >
                                    <div className='bg-gray-100 flex flex-row items-center'>
                                        <DraggableColumnHeader
                                            key={'producto'}
                                            id={'productos'}
                                            name={columnNames['producto']}
                                            type="COLUMN"
                                            moveColumn={moveColumn}
                                            onSortChange={onSortChange}
                                            sortState={sortState}
                                            onDrop={onDrop}
                                        />
                                    </div>
                                </div>
                                {loading ? <div className='h-36 flex justify-center items-center'> <div className='spinner' /> </div> :
                                    filteredData.map((data, dataIndex) => (
                                        <div key={dataIndex} className="flex items-center border-gray-200">
                                            <div key={'producto' + 0} className={getClassNamesForColumn('producto')}>
                                                {renderColumnData(data, 'productos')}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="overflow-x-auto" style={{ backgroundColor: 'transparent' }}>
                            <div className="table-wrapper">
                                <div
                                    className='bg-gray-100 flex h-12 border-t-2 border-gray-200'
                                >
                                    <div className='bg-gray-100 flex flex-row space-x-2 items-center'>
                                        {columnsOrder.map((columnId) => visibleColumns[columnId] && (

                                            <DraggableColumnHeader
                                                key={columnId}
                                                id={columnId}
                                                name={columnNames[columnId]}
                                                type="COLUMN"
                                                moveColumn={moveColumn}
                                                onSortChange={onSortChange}
                                                sortState={sortState}
                                                onDrop={onDrop}
                                            />

                                        ))}
                                    </div>
                                </div>
                                {loading ? <div className='h-36 flex justify-center items-center'> <div className='spinner' /> </div> :
                                    filteredData.map((data, dataIndex) => (
                                        <div key={dataIndex} className="flex items-center space-x-2 w-full pl-2">
                                            {columnsOrder.map((columnId, columnIndex) => (
                                                visibleColumns[columnId] && <div key={columnId + columnIndex} className={getClassNamesForColumn(columnId)}>
                                                    {renderColumnData(data, columnId)}
                                                </div>
                                            ))}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="pagination-controls flex flex-row justify-end items-center px-4 py-3">
                        <IconButton onClick={() => setCurrentPage(0)} disabled={currentPage === 0}>
                            <FirstPage />
                        </IconButton>
                        <IconButton onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
                            <NavigateBefore />
                        </IconButton>
                        <IconButton onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage >= totalPages - 1}>
                            <NavigateNext />
                        </IconButton>
                        <IconButton onClick={() => setCurrentPage(totalPages - 1)} disabled={currentPage === totalPages - 1}>
                            <LastPage />
                        </IconButton>
                    </div>
                </div>
            </div >
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </DndProvider>
    );
};

export default ProductsCardComparativa;
