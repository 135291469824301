import React, { useEffect, useMemo, useState } from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, Cell, ReferenceLine } from 'recharts';
import { db } from '../../helpers/firebase';
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import Select from 'react-select';
import { InfoOutlined } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { useTopbar } from '../../helpers/TopbarContext';
import useAuth from '../../hooks/useAuth';

const StorePerformanceChart = ({ store, onlyAdsSales }) => {
    const {
        startDate,
        endDate,
        selectedChartInterval
    } = useTopbar();

    const { currentUser } = useAuth();

    // Componente de Leyenda Personalizado
    const renderLegend = (props) => {
        const { payload } = props;

        return (
            <div style={{ margin: '0 auto', textAlign: 'right' }}>
                <ul style={{ listStyle: 'none', marginLeft: 10, padding: 0 }}>
                    {payload.map((entry, index) => (
                        <li key={`item-${index}`} style={{ color: entry.value == 'ganancia' ? '#48A047' : entry.color, marginBottom: 10, fontSize: 12, display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <svg width="20" height={entry.value == 'ganancia' ? "20" : "5"} style={{ verticalAlign: 'middle', marginRight: 5 }}>
                                <rect width="20" height={entry.value == 'ganancia' ? "20" : "5"} fill={entry.value == 'ganancia' ? '#48A047' : entry.color} />
                            </svg>
                            <span className='text-gray-500'>
                                {entry.value.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    function CustomizedAxisTick({ x, y, stroke, payload }) {
        const date = new Date(payload.value);
        let formattedDate;
        switch (selectedChartInterval.value) {
            case 'SEMANA':
                formattedDate = `Sem ${date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit' })}`;
                break;
            case 'MES':
                formattedDate = date.toLocaleDateString('es-ES', { month: 'short', year: 'numeric' });
                break;
            case 'ANO':
                formattedDate = date.getFullYear().toString();
                break;
            default: // 'DIA'
                formattedDate = date.toLocaleDateString('es-ES', { day: '2-digit', month: 'short' });
        }
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                    {formattedDate}
                </text>
            </g>
        );
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const date = new Date(label);
            let formattedDate;
            switch (selectedChartInterval.value) {
                case 'SEMANA':
                    formattedDate = `Semana del ${date.toLocaleDateString('es-ES', { day: '2-digit', month: 'long', year: 'numeric' })}`;
                    break;
                case 'MES':
                    formattedDate = date.toLocaleDateString('es-ES', { month: 'long', year: 'numeric' });
                    break;
                case 'ANO':
                    formattedDate = date.getFullYear().toString();
                    break;
                default: // 'DIA'
                    formattedDate = date.toLocaleDateString('es-ES', { day: '2-digit', month: 'long', year: 'numeric' });
            }
            return (
                <div style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px' }}>
                    <p>{formattedDate}</p>
                    {payload.map((entry, index) => (
                        <p key={`item-${index}`} style={{ color: entry.dataKey === 'ganancia' ? (entry.value < 0 ? '#E43631' : '#48A047') : entry.color }}>
                            {`${entry.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}: ${entry.value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}
                        </p>
                    ))}
                </div>
            );
        }

        return null;
    };

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [chartWidth, setChartWidth] = useState(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth) * 0.5) - 50);
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState(store);

    useEffect(() => {
        if (store) setSelectedStore(store);
    }, [store]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setChartWidth((window.innerWidth - 15));
                return;
            }
            const newChartWidth = ((window.innerWidth * 0.5) - 50);
            setChartWidth(newChartWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const getUserStores = async () => {
            if (!currentUser) {
                return;
            }
            const storesRef = collection(db, 'users', currentUser.uid, 'stores');
            const storesSnapshot = await getDocs(storesRef);

            const storesDocs = storesSnapshot.docs;

            const storesList = storesSnapshot.docs.map(doc => ({
                id: doc.id,
                value: doc.data().custom_name,
                data: doc.data(),
                label: <div className='flex flex-row items-center'>
                    <img
                        src={doc.id.startsWith('ml')
                            ? `https://http2.mlstatic.com/frontend-assets/ui-navigation/5.18.5/mercadolibre/logo__large_plus.png`
                            : `https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Amazon.com-Logo.svg/1200px-Amazon.com-Logo.svg.png`
                        }
                        className='w-16 mr-4 object-contain'
                        alt=""
                    />
                    {doc.data().custom_name}
                </div>
            }));

            setStores(storesList);
            if (storesList.length > 0 && !store) setSelectedStore(storesList[0]);
            setLoading(false);
        };

        getUserStores();
    }, [currentUser, store]);

    const totalGanancia = useMemo(() => data.reduce((acc, curr) => acc + curr.ganancia, 0), [data]);

    const currencyCode = 'MXN';

    const parseMonetaryValue = (value) => {
        if (typeof value === 'string') {
            const cleanedValue = value.replace(/[^\d.]/g, '');
            return parseFloat(cleanedValue) || 0;
        }
        return typeof value === 'number' ? value : 0;
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!selectedStore || !startDate || !endDate || !selectedChartInterval || !currentUser) return;

            setLoading(true);

            const startDateString = startDate.toISOString().split('T')[0];
            const endDateString = endDate.toISOString().split('T')[0];

            // Fetch days data
            const daysRef = collection(db, `users/${currentUser.uid}/stores/${selectedStore.id}/days`);
            const daysQuery = query(
                daysRef,
                where('__name__', '>=', startDateString),
                where('__name__', '<=', endDateString),
                orderBy('__name__', 'asc')
            );

            const daysSnapshot = await getDocs(daysQuery);

            const daysData = daysSnapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    date: doc.id,
                    ventas: onlyAdsSales
                        ? parseMonetaryValue(data.ad_report?.sales30d || data.ads_amount || 0)
                        : parseMonetaryValue(data.sales_by_date?.orderedProductSales?.amount || data.total_amount || 0),
                    publicidad: parseMonetaryValue(data.ad_report?.cost || data.ads_cost || 0),
                };
            });

            // If not onlyAdsSales, fetch orders data
            let ordersData = [];
            if (!onlyAdsSales) {
                const ordersRef = collection(db, `users/${currentUser.uid}/stores/${selectedStore.id}/orders`);
                const ordersQuery = query(
                    ordersRef,
                    where('date_timestamp', '>=', new Date(startDateString)),
                    where('date_timestamp', '<=', new Date(endDateString)),
                    orderBy('date_timestamp', 'asc')
                );

                const ordersSnapshot = await getDocs(ordersQuery);

                ordersData = ordersSnapshot.docs.map(doc => ({
                    date: doc.data().date_timestamp.toDate().toISOString().split('T')[0],
                    ventas: parseMonetaryValue(doc.data().total_order_amount),
                }));
            }

            // Merge data
            const mergedData = mergeData(ordersData, daysData, onlyAdsSales);

            // Aggregate data based on selected interval
            const aggregatedData = aggregateData(mergedData, selectedChartInterval.value);

            setData(aggregatedData);
            setLoading(false);
        }

        fetchData();
    }, [selectedStore, startDate, endDate, onlyAdsSales, selectedChartInterval, currentUser]);

    const mergeData = (ordersData, daysData, onlyAdsSales) => {
        const mergedMap = new Map();

        if (onlyAdsSales) {
            // Use only days data for ad sales
            daysData.forEach(item => {
                mergedMap.set(item.date, { ...item });
            });
        } else {
            // Acumular ventas por día de orders
            ordersData.forEach(item => {
                if (mergedMap.has(item.date)) {
                    mergedMap.get(item.date).ventas += item.ventas;
                } else {
                    mergedMap.set(item.date, { date: item.date, ventas: item.ventas, publicidad: 0 });
                }
            });

            // Agregar datos de publicidad de days
            daysData.forEach(item => {
                if (mergedMap.has(item.date)) {
                    mergedMap.get(item.date).publicidad = item.publicidad;
                } else {
                    mergedMap.set(item.date, { ...item, ventas: 0 });
                }
            });
        }

        return Array.from(mergedMap.values());
    };

    const aggregateData = (data, interval) => {
        const aggregated = {};

        data.forEach(item => {
            let key;
            const date = new Date(item.date);

            switch (interval) {
                case 'SEMANA':
                    const day = date.getDay();
                    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
                    key = new Date(date.setDate(diff)).toISOString().split('T')[0];
                    break;
                case 'MES':
                    key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
                    break;
                case 'ANO':
                    key = `${date.getFullYear()}`;
                    break;
                default: // 'DIA'
                    key = item.date;
            }

            if (!aggregated[key]) {
                aggregated[key] = { name: key, ventas: 0, publicidad: 0, ganancia: 0 };
            }
            aggregated[key].ventas += item.ventas;
            aggregated[key].publicidad += item.publicidad;
            aggregated[key].ganancia = aggregated[key].ventas - aggregated[key].publicidad;
        });

        return Object.values(aggregated).sort((a, b) => new Date(a.name) - new Date(b.name));
    };

    return <div className='flex flex-col items-start justify-start bg-white rounded-lg shadow-md m-1' style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
        <div className="flex flex-col md:flex-row items-center px-5 text-kompamxblue text-base justify-center md:justify-between w-full" style={{ minHeight: "3.5rem" }}>
            <div className="flex items-center justify-start w-full">
                <div className="flex flex-row items-center justify-start w-full">
                    <div className="font-semibold mr-2 lg:whitespace-nowrap">
                        Rentabilidad {!onlyAdsSales ? 'General' : 'de Publicidad'}
                    </div>
                    <Tooltip title={'Selecciona una tienda para ver sus métricas de rentabilidad y tendencias de costos'}>
                        <InfoOutlined style={{ fontSize: 18 }} className='ml-auto mr-2' />
                    </Tooltip>
                </div>
                {!store &&
                    <Select
                        options={stores}
                        className=''
                        placeholder='Selecciona una tienda'
                        value={selectedStore}
                        onChange={setSelectedStore}
                    />
                }
            </div>
        </div>
        <hr className="w-full border-gray-300 mb-4" />
        {!selectedStore ? <div className="flex justify-center items-center h-full w-full">
            <p className="text-gray-500 text-lg">No hay datos para mostrar</p>
        </div> : loading ? < div className='h-full w-full flex justify-center items-center'><div className="spinner" /></div> :

            data.length === 0
                ? <div className="flex justify-center items-center h-full w-full">
                    <p className="text-gray-500 text-lg">No hay datos para mostrar</p>
                </div> : <div className='flex flex-row items-center justify-between w-full pb-3 h-full'>
                    <ComposedChart
                        width={chartWidth - 140}
                        height={300}
                        data={data}
                        margin={{ top: 20, bottom: 40, left: 20 }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
                        No decimals:
                        <YAxis tickFormatter={(value) => value.toLocaleString('en-US', { style: 'currency', currency: "USD", minimumFractionDigits: 0 })} />
                        <RechartsTooltip content={<CustomTooltip />} />
                        <Legend content={renderLegend} align="right" verticalAlign="middle" layout="vertical" />

                        <Line type="linear" dataKey="ventas" stroke="#1E6BB9" strokeWidth={2} dot={false} />
                        <Line type="linear" dataKey="publicidad" stroke="#F4A240" strokeWidth={2} dot={false} />

                        <Bar dataKey="ganancia" barSize={20}>
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}-${index}`} fill={entry.ganancia < 0 ? '#E43631' : '#48A047'} />
                            ))}
                        </Bar>

                        <ReferenceLine y={0} stroke="#CCCCCC" strokeDasharray="3 3" />
                    </ComposedChart>
                    <div className='flex flex-col items-start justify-start'>
                        <h2 className='text-kompamx-800 text-xl uppercase font-bold w-32 mb-2'>
                            Ganancia Total
                        </h2>
                        <h1 className='text-kompamx-800 text-2xl font-bold mb-2'>
                            {totalGanancia.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                        </h1>
                        <h3 className='text-gray-500 text-md'>
                            {currencyCode}
                        </h3>
                    </div>
                </div>
        }
    </div >
};

export default StorePerformanceChart;