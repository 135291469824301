import React from 'react';

function Footer() {
    return (
        <div className="flex justify-between items-center text-kompamx-500 px-9 text-sm font-medium py-2 md:py-1 h-16">
            <p className="text-right text-sm font-light w-full">
                © 2024 KompaMx. Todos los derechos reservados.
            </p>
        </div>
    );
}

export default Footer;
