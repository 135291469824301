import React, { useEffect, useState } from 'react'
import ProductsCard from '../../../components/ProductsCard'
import Footer from '../../../components/Footer'

export default function TablaDeProductosMT({ store }) {
    const [lastScrollTop, setLastScrollTop] = useState(0);

    const handleScroll = (e) => {
        const scrollTop = e.target.scrollTop;  // Obtiene la posición actual del scroll
        if (scrollTop < lastScrollTop) {
            // scrollear hasta arriba el big-container, suavemente
            const bigContainer = document.getElementById('big-container');
            bigContainer.scrollTop = 0;

        }
        setLastScrollTop(scrollTop);  // Actualiza la última posición del scroll
    };

    useEffect(() => {
        const contentToPdf = document.getElementById('content-to-pdf');
        contentToPdf.addEventListener('scroll', handleScroll);

        return () => {
            contentToPdf.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);  // Dependencias en las que el efecto depende

    return (
        <div id="big-container" style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>

            <div  id="content-to-pdf" className='flex flex-col justify-start items-center bg-gray-100 py-5 px-5' style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>
                <ProductsCard passedStore={store} />
            </div >
            <Footer />
        </div>
    )
}
