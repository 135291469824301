import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '../helpers/stripe';
import { DisenoFinoV2 } from '../componentes/DisenoFinoV2';
import StripeForm from './StripeForm';

const StripeCheckoutWrapper = () => (
  <DisenoFinoV2>
    <Elements stripe={stripePromise}>
      <StripeForm />
    </Elements>
  </DisenoFinoV2>
);

export default StripeCheckoutWrapper;