'use client'

import { Link } from 'react-router-dom'
import {
  Popover,
  PopoverButton,
  PopoverBackdrop,
  PopoverPanel,
} from '@headlessui/react'
import clsx from 'clsx'

import { Boton } from './Boton'
import { Contenedor } from './Contenedor'
import { Logo } from './Logo'
import { EnlaceNavegacion } from './EnlaceNavegacion'

function EnlaceNavMovil({ href, children }) {
  return (
    <PopoverButton as={Link} href={href} className="block w-full p-2">
      {children}
    </PopoverButton>
  )
}

function IconoNavMovil({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0',
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0',
        )}
      />
    </svg>
  )
}

function NavegacionMovil() {
  return (
    <Popover>
      <PopoverButton
        className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Alternar Navegación"
      >
        {({ open }) => <IconoNavMovil open={open} />}
      </PopoverButton>
      <PopoverBackdrop
        transition
        className="fixed inset-0 bg-slate-300/50 duration-150 data-[closed]:opacity-0 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <PopoverPanel
        transition
        className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5 data-[closed]:scale-95 data-[closed]:opacity-0 data-[enter]:duration-150 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in"
      >
        <EnlaceNavMovil href="#caracteristicas">Características</EnlaceNavMovil>
        <EnlaceNavMovil href="#testimonios">Testimonios</EnlaceNavMovil>
        <EnlaceNavMovil href="#precios">Precios</EnlaceNavMovil>
        <hr className="m-2 border-slate-300/40" />
        <EnlaceNavMovil href="/iniciar-sesion">Iniciar sesión</EnlaceNavMovil>
      </PopoverPanel>
    </Popover>
  )
}

export function Cabecera() {
  return (
    <header className="py-10">
      <Contenedor>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <Link to="#" aria-label="Inicio">
              <Logo className="h-10 w-auto" />
            </Link>
            <div className="hidden md:flex md:gap-x-6">
              <EnlaceNavegacion href="#caracteristicas">Características</EnlaceNavegacion>
              <EnlaceNavegacion href="#testimonios">Testimonios</EnlaceNavegacion>
              <EnlaceNavegacion href="#precios">Precios</EnlaceNavegacion>
            </div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <div className="hidden md:block">
              <EnlaceNavegacion href="/iniciar-sesion">Iniciar sesión</EnlaceNavegacion>
            </div>
            <Boton to="/registro" color="azul">
              <span className='my-[-0.125rem]'>
                Empezar <span className="hidden lg:inline">ahora</span>
              </span>
            </Boton>
            <div className="-mr-1 md:hidden">
              <NavegacionMovil />
            </div>
          </div>
        </nav>
      </Contenedor>
    </header>
  )
}