import clsx from 'clsx'
import { Link } from 'react-router-dom'

const estilosBase = {
  solido:
    'group inline-flex items-center justify-center rounded-full py-[0.6rem] px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2',
  contorno:
    'group inline-flex ring-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none',
}

const estilosVariantes = {
  solido: {
    gris:
      'bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900',
    azul: 'bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600',
    blanco:
      'bg-white text-slate-900 hover:bg-blue-50 active:bg-blue-200 active:text-slate-600 focus-visible:outline-white',
  },
  contorno: {
    gris:
      'ring-slate-200 text-slate-700 hover:text-slate-900 hover:ring-slate-300 active:bg-slate-100 active:text-slate-600 focus-visible:outline-blue-600 focus-visible:ring-slate-300',
    blanco:
      'ring-slate-700 text-white hover:ring-slate-500 active:ring-slate-700 active:text-slate-400 focus-visible:outline-white',
  },
}

export function Boton({ className, ...props }) {
  // Establecer valores por defecto si no se proporcionan
  props.variante ??= 'solido'
  props.color ??= 'gris'

  className = clsx(
    estilosBase[props.variante],
    props.variante === 'contorno'
      ? estilosVariantes.contorno[props.color]
      : props.variante === 'solido'
        ? estilosVariantes.solido[props.color]
        : undefined,
    className,
  )

  // Renderizar un botón o un enlace dependiendo de si se proporciona una prop 'href'
  return typeof props.to === 'undefined' ? (
    <button className={className} {...props} />
  ) : (
    <>
      <Link className={className} {...props} {...(props.openInNewTab && { target: '_blank' })} />
    </>
  )
}