
import { Contenedor } from './Contenedor'
import avatarImage1 from '../imagenes/avatares/avatar-1.png'
import avatarImage2 from '../imagenes/avatares/avatar-2.png'
import avatarImage3 from '../imagenes/avatares/avatar-3.png'
import avatarImage4 from '../imagenes/avatares/avatar-4.png'
import avatarImage5 from '../imagenes/avatares/avatar-5.png'
import avatarImage6 from '../imagenes/avatares/avatar-6.jpg'

const testimonios = [
  [
    {
      contenido:
        'Kompa ha revolucionado la forma en que gestionamos nuestras tiendas online. Los datos que nos proporciona son invaluables para tomar decisiones estratégicas.',
      autor: {
        nombre: 'Laura Martínez',
        cargo: 'CEO de Moda Express',
        imagen: avatarImage1,
      },
    },
    {
      contenido:
        'Desde que empezamos a usar Kompa, nuestras ventas han aumentado un 30%. La capacidad de analizar tendencias en tiempo real es simplemente asombrosa.',
      autor: {
        nombre: 'Carlos Rodríguez',
        cargo: 'Director de Ventas en TechnoShop',
        imagen: avatarImage5,
      },
    },
  ],
  [
    {
      contenido:
        'Lo mejor de Kompa es cómo simplifica datos complejos. Ahora puedo entender el rendimiento de mis productos en Amazon y Mercado Libre de un vistazo.',
      autor: {
        nombre: 'Elena Gómez',
        cargo: 'Fundadora de Eco-Hogar',
        imagen: avatarImage4,
      },
    },
    {
      contenido:
        'Kompa nos ha permitido optimizar nuestro inventario y reducir costos. Es como tener un analista de datos trabajando 24/7 para nosotros.',
      autor: {
        nombre: 'Javier López',
        cargo: 'COO de Deportes Unidos',
        imagen: avatarImage3,
      },
    },
  ],
  [
    {
      contenido:
        'La integración de Kompa con nuestras tiendas fue sorprendentemente fácil. En cuestión de minutos, teníamos acceso a insights que antes nos llevaban semanas obtener.',
      autor: {
        nombre: 'María Sánchez',
        cargo: 'Fundadora de Belleza Natural',
        imagen: avatarImage2,
      },
    },
    {
      contenido:
        'El soporte al cliente de Kompa es excepcional. Siempre están dispuestos a ayudar y asegurarse de que aprovechemos al máximo la plataforma.',
      autor: {
        nombre: 'Pedro Ramírez',
        cargo: 'Director de E-commerce en Electro-Todo',
        imagen: avatarImage6,
      },
    },
  ],
]

function IconoComillas(props) {
  return (
    <svg aria-hidden="true" width={105} height={78} {...props}>
      <path d="M25.086 77.292c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622C1.054 58.534 0 53.411 0 47.686c0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C28.325 3.917 33.599 1.507 39.324 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Zm54.24 0c-4.821 0-9.115-1.205-12.882-3.616-3.767-2.561-6.78-6.102-9.04-10.622-2.11-4.52-3.164-9.643-3.164-15.368 0-5.273.904-10.396 2.712-15.368 1.959-4.972 4.746-9.567 8.362-13.786a59.042 59.042 0 0 1 12.43-11.3C82.565 3.917 87.839 1.507 93.564 0l11.074 13.786c-6.479 2.561-11.677 5.951-15.594 10.17-3.767 4.219-5.65 7.835-5.65 10.848 0 1.356.377 2.863 1.13 4.52.904 1.507 2.637 3.089 5.198 4.746 3.767 2.41 6.328 4.972 7.684 7.684 1.507 2.561 2.26 5.5 2.26 8.814 0 5.123-1.959 9.19-5.876 12.204-3.767 3.013-8.588 4.52-14.464 4.52Z" />
    </svg>
  )
}

export function Testimonios() {
  return (
    <section
      id="testimonios"
      aria-label="Lo que dicen nuestros clientes"
      className="bg-slate-50 py-20 sm:py-32"
    >
      <Contenedor>
        <div className="mx-auto max-w-2xl md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-slate-900 sm:text-4xl">
            Amado por negocios en todo el mundo.
          </h2>
          <p className="mt-4 text-lg tracking-tight text-slate-700">
            Descubre cómo Kompa está transformando la forma en que las empresas gestionan y analizan sus ventas en línea.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:gap-8 lg:mt-20 lg:max-w-none lg:grid-cols-3"
        >
          {testimonios.map((columna, indiceColumna) => (
            <li key={indiceColumna}>
              <ul role="list" className="flex flex-col gap-y-6 sm:gap-y-8">
                {columna.map((testimonio, indiceTestimonio) => (
                  <li key={indiceTestimonio}>
                    <figure className="relative rounded-2xl bg-white p-6 shadow-xl shadow-slate-900/10">
                      <IconoComillas className="absolute left-6 top-6 fill-slate-100" />
                      <blockquote className="relative">
                        <p className="text-lg tracking-tight text-slate-900">
                          {testimonio.contenido}
                        </p>
                      </blockquote>
                      <figcaption className="relative mt-6 flex items-center justify-between border-t border-slate-100 pt-6">
                        <div>
                          <div className="font-display text-base text-slate-900">
                            {testimonio.autor.nombre}
                          </div>
                          <div className="mt-1 text-sm text-slate-500">
                            {testimonio.autor.cargo}
                          </div>
                        </div>
                        <div className="overflow-hidden rounded-full bg-slate-50">
                          <img
                            className="h-14 w-14 object-cover"
                            src={testimonio.autor.imagen}
                            alt=""
                            width={56}
                            height={56}
                          />
                        </div>
                      </figcaption>
                    </figure>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Contenedor>
    </section>
  )
}