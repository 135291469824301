import React, { useState, useEffect } from 'react';
import DragIndicator from '@mui/icons-material/DragIndicator';
import { db } from '../../../helpers/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Checkbox, Divider, Menu, MenuItem } from '@mui/material';
import CustomMenu from '../../../components/CustomMenu';
import MostSoldProducts from '../../../components/charts/MostSoldProducts';
import TrafficConversionChart from '../../../components/charts/TrafficConversionChart';
import MeLiMetrics from '../../../components/charts/MeLiMetrics';
import StorePerformanceChart from '../../../components/charts/StorePerformanceChart';
import PeriodComparisonChart from '../../../components/charts/bar/PeriodComparisonChart';
import AdMetricsChart from '../../../components/charts/AdMetricsChart';
import SvgPdfIcon from '../../../components/SvgPdfIcon';
import Footer from '../../../components/Footer';
import useAuth from '../../../hooks/useAuth';

export default function GraficosYAnalisisMT({ store }) {
    const [charts, setCharts] = useState([
        { id: 'mostSold1', type: 'MostSoldProducts', visible: true, label: 'Productos más vendidos 1', props: { sortingTypeProp: 'desc' } },
        { id: 'mostSold2', type: 'MostSoldProducts', visible: true, label: 'Productos menos vendidos 1', props: { sortingTypeProp: 'asc' } },
        { id: 'trafficConversion1', type: 'TrafficConversionChart', visible: true, label: 'Tráfico y Conversión 1' },
        { id: 'storePerformanceChart1', type: 'StorePerformanceChart', visible: true, label: 'Rentabilidad General' },
        { id: 'storePerformanceChart2', type: 'StorePerformanceChart', visible: true, label: 'Rentabilidad De Anuncios', props: { onlyAdsSales: true } },
        { id: 'periodComparison1', type: 'PeriodComparisonChart', visible: true, label: 'Comparativa de Periodos 1', props: { ordersGraph: false } },
        { id: 'periodComparison2', type: 'PeriodComparisonChart', visible: true, label: 'Comparativa de Periodos 2', props: { ordersGraph: true } },
        { id: 'adMetricsChart1', type: 'AdMetricsChart', visible: true, label: 'ACoS 1', props: { selectedOptionProp: { value: 'acos', label: 'ACoS' } } },
        { id: 'adMetricsChart2', type: 'AdMetricsChart', visible: true, label: 'ROAS 1', props: { selectedOptionProp: { value: 'roas', label: 'ROAS' } } },
        { id: 'adMetricsChart3', type: 'AdMetricsChart', visible: true, label: 'TACoS 1', props: { selectedOptionProp: { value: 'tacos', label: 'TACoS' } } },
        { id: 'adMetricsChart4', type: 'AdMetricsChart', visible: true, label: 'Gasto en publicidad 1', props: { selectedOptionProp: { value: 'adSpend', label: 'Gasto en publicidad' } } },
        { id: 'MeLiMetrics1', type: 'MeLiMetrics', visible: true, label: 'Métricas de Mercado Libre 1' },
    ]);
    const [dashboardAnchorEl, setDashboardAnchorEl] = useState(null);
    const [loading1, setLoading1] = useState(true);
    const [menuVisible, setMenuVisible] = useState(false);
    const [anchorPosition, setAnchorPosition] = useState({ x: 0, y: 0 });
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const { currentUser } = useAuth();

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMenuClick = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setAnchorPosition({
            x: rect.left, // O quizás rect.right, dependiendo de dónde desees que aparezca el menú
            y: rect.top + window.scrollY,
        });
        setMenuVisible(!menuVisible);
    };

    const saveInitialChartOrder = async () => {
        if (!currentUser) return;
        const uid = currentUser.uid;
        if (!uid) return;

        const userDocRef = doc(db, `users/${uid}`);

        // if one of the fields is missing print out which one and return
        if (!charts.every(chart => chart.id)) {
            console.log('One of the charts is missing an id');
            return;
        }

        if (!charts.every(chart => chart.visible)) {
            console.log('One of the charts is missing a visibility flag');
            return;
        }

        await setDoc(userDocRef, {
            chartsOrder_MT: charts.map(chart => chart.id),
            chartsVisibility_MT: charts.map(chart => chart.visible)
        }, { merge: true });
    };

    useEffect(() => {
        if (loading1) return; // Asegúrate de que no se ejecute mientras se cargan los datos iniciales

        const saveChartsState = async () => {
            if (!currentUser) return;
            const uid = currentUser.uid;
            if (!uid) return;

            const userDocRef = doc(db, `users/${uid}`);

            // if one of the fields is missing print out which one and return
            if (!charts.every(chart => chart.id)) {
                console.log('One of the charts is missing an id');
                return;
            }

            if (!charts.every(chart => chart.visible)) {
                console.log('One of the charts is missing a visibility flag');
                return;
            }

            await setDoc(userDocRef, {
                chartsOrder_MT: charts.map(chart => chart.id),
                chartsVisibility_MT: charts.map(chart => chart.visible)
            }, { merge: true });
        };

        saveChartsState();
    }, [charts, currentUser]); // Observa cambios en charts para actualizar Firestore    

    useEffect(() => {
        const fetchChartOrder = async () => {
            if (!currentUser) return;
            const uid = currentUser.uid;
            if (!uid) return;

            setLoading1(true); // Asumiendo que usas este estado para manejar la carga

            const userDocRef = doc(db, `users/${uid}`);
            const docSnap = await getDoc(userDocRef);

            if (docSnap.exists()) {
                const { chartsOrder_MT } = docSnap.data();
                if (chartsOrder_MT) {
                    // Reordenar `charts` basado en `chartsOrder_MT`
                    const orderedCharts = chartsOrder_MT.map(id => charts.find(chart => chart.id === id)).filter(Boolean);
                    setCharts(orderedCharts);
                }
            }

            setLoading1(false);
        };

        fetchChartOrder();
    }, [currentUser]); // Observa cambios en currentUser para cargar el orden de los gráficos

    useEffect(() => {
        const fetchAndInitializeChartOrderAndVisibility = async () => {
            if (!currentUser) return;
            const uid = currentUser.uid;
            if (!uid) return;

            const userDocRef = doc(db, `users/${uid}`);
            const docSnap = await getDoc(userDocRef);

            if (docSnap.exists() && docSnap.data().chartsOrder_MT && docSnap.data().chartsVisibility_MT) {
                // Si el documento existe y los campos chartsOrder_MT y chartsVisibility_MT también existen, establece el orden
                const { chartsOrder_MT, chartsVisibility_MT } = docSnap.data();
                const orderedAndVisibleCharts = chartsOrder_MT.map((id, index) => {
                    const chart = charts.find(chart => chart.id === id);
                    return chart ? { ...chart, visible: chartsVisibility_MT[index] } : null;
                }).filter(Boolean);
                setCharts(orderedAndVisibleCharts);
            } else {
                // Si no existen los campos, llama a saveInitialChartOrder para crearlos
                await saveInitialChartOrder();
            }
        };

        fetchAndInitializeChartOrderAndVisibility();
    }, [currentUser]); // Observa cambios en currentUser para cargar el orden de los gráficos

    const renderChartContent = (chart) => {
        const Component = chartMappings[chart.type]; // Obtiene el componente basado en el tipo
        if (!Component) return null;

        // Verificar si la tienda es de Amazon
        const isAmazonStore = store && store.id && store.id.startsWith('az_');

        // Si es una tienda de Amazon y el gráfico es de MeLiMetrics, no lo renderizamos
        if (isAmazonStore && chart.type === 'MeLiMetrics') {
            return null;
        }

        return <Component store={store} {...chart.props} />;
    };

    const chartMappings = {
        MostSoldProducts: MostSoldProducts,
        TrafficConversionChart: TrafficConversionChart,
        MeLiMetrics: MeLiMetrics,
        StorePerformanceChart: StorePerformanceChart,
        PeriodComparisonChart: PeriodComparisonChart,
        AdMetricsChart: AdMetricsChart,
    };

    const [lastScrollTop, setLastScrollTop] = useState(0);

    const handleScroll = (e) => {
        const scrollTop = e.target.scrollTop;  // Obtiene la posición actual del scroll
        if (scrollTop < lastScrollTop) {
            // scrollear hasta arriba el big-container, suavemente
            const bigContainer = document.getElementById('big-container');
            bigContainer.scrollTop = 0;

        }
        setLastScrollTop(scrollTop);  // Actualiza la última posición del scroll
    };

    useEffect(() => {
        if (loading1) return; // Asegúrate de que no se ejecute mientras se cargan los datos iniciales

        const contentToPdf = document.getElementById('content-to-pdf');
        contentToPdf.addEventListener('scroll', handleScroll);

        return () => {
            contentToPdf.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop, loading1]);

    if (loading1) {
        return <div className='h-screen flex justify-center items-center bg-gray-100'>
            <div className='spinner' />
        </div>;
    }

    return (
        <>
            <CustomMenu charts={charts} setCharts={setCharts} anchorPosition={anchorPosition} menuIsVisible={menuVisible} setMenuIsVisible={setMenuVisible} />

            <Menu
                id="dashboard-menu"
                anchorEl={dashboardAnchorEl}
                keepMounted
                open={Boolean(dashboardAnchorEl)}
                onClose={() => setDashboardAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <p className="mx-4 my-2 select-none text-kompamxblue text-sm font-semibold">
                    Ocultar o Reordenar Gráficas
                </p>

                <Divider />

                {charts.map((chart, index) => (
                    <MenuItem key={chart.id} onClick={() => {
                        // Actualizar la visibilidad del gráfico específico
                        const updatedCharts = charts.map((c, i) => {
                            if (i === index) {
                                return { ...c, visible: !c.visible };
                            }
                            return c;
                        });
                        setCharts(updatedCharts);
                    }}>
                        <div className='flex flex-row justify-start items-center w-full'>
                            <DragIndicator className='text-kompamxblue opacity-20 hover:opacity-100 transition-all duration-300' />
                            <div className='flex flex-row justify-start items-center'>
                                <Checkbox checked={chart.visible} />
                                {chart.label}
                            </div>
                        </div>
                    </MenuItem>
                ))}
            </Menu>

            <div id="big-container" style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>

                <div id='content-to-pdf' className='flex flex-col justify-start items-center bg-gray-100' style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>
                    {/* <div
                        className='flex flex-row justify-end items-end w-full mb-5 ml-auto '
                    >
                        <div className='justify-end items-end text-right mb-5 flex flex-row'>
                            <Tooltip title='Ocultar o Reordenar Gráficas' arrow>
                                <div
                                    className="text-kompamxblue cursor-pointer mx-2 my-2 flex flex-row rounded-full hover:bg-gray-200 transition-all duration-200 p-1"
                                    onClick={handleMenuClick}
                                >
                                    <Dashboard />
                                </div>
                            </Tooltip>
                        </div>

                    </div> */}

                    {screenWidth > 768 ? (
                        Array.from({
                            // Primero filtra los gráficos para incluir solo los visibles, luego calcula la longitud necesaria para el nuevo array basado en los gráficos visibles.
                            length: Math.ceil(charts.filter(chart => chart.visible).length / 2)
                        }).map((_, rowIndex) => {
                            // A partir de los gráficos visibles, obtén los que corresponden a esta fila en particular.
                            const rowCharts = charts
                                .filter(chart => chart.visible)
                                .slice(rowIndex * 2, rowIndex * 2 + 2);

                            return (
                                <div key={rowIndex} className='flex flex-row justify-center items-center w-full'>
                                    {rowCharts.map((chart, index) => {
                                        return renderChartContent(chart)
                                    })}
                                </div>
                            );
                        })
                    ) : (
                        charts.filter(chart => chart.visible).map((chart, index) => (

                            <div key={index} className='flex flex-row justify-center items-center w-full'>
                                {renderChartContent(chart)}
                            </div>
                        ))
                    )}
                </div>
                <Footer />
            </div>
        </>
    );
}
