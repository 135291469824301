import { useRef, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../helpers/firebase';

const useDB = (initialSearchTerm = '') => {
    const [tables, setTables] = useState([]);
    const [filteredTables, setFilteredTables] = useState([]);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
    const [selectedTable, setSelectedTable] = useState('');
    const [rawQuery, setRawQuery] = useState('SELECT TRIM(RDB$RELATION_NAME) AS NOMBRE_DE_TABLA FROM RDB$RELATIONS WHERE RDB$SYSTEM_FLAG = 0');
    const [naturalQuery, setNaturalQuery] = useState('Traer el inventario ordenado de mayor a menor existencia') // 'Traer todas las tablas');
    const [responseQuery, setResponseQuery] = useState(null);
    const [rawResults, setRawResults] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingRawQuery, setLoadingRawQuery] = useState(false);
    const [loadingRandomQuery, setLoadingRandomQuery] = useState(false);
    const [loadingTables, setLoadingTables] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'none' });
    const [tablePage, setTablePage] = useState(1);
    const [tableSortOrder, setTableSortOrder] = useState('default');
    const [dataPage, setDataPage] = useState(1);
    const [currentDocId, setCurrentDocId] = useState(null);
    const [likeState, setLikeState] = useState("none"); // "none", "like", "dislike"
    const [resultsHistory, setResultsHistory] = useState([]); // Arreglo de objetos para el historial

    const [contextText, setContextText] = useState(`You are a helpful assistant that generates SQL queries for a Firebird database. 
The user will provide a description of the data they want to retrieve. 
You should generate a Firebird SQL query based on this description. 
The output should be a valid Firebird SQL query. 
Not all valid SQL queries are valid for Firebird, so ensure the query is specifically for Firebird. 
The Input may be in spoken language, so you may need to interpret the user's intent. 
The Input may be in spanish, so you may need to interpret the user's intent. 
Input format: 'Retrieve {data} from {table} where {conditions}'. 
Output format: 'SELECT TRIM(RDB$RELATION_NAME) AS RDB$RELATION_NAME FROM RDB$RELATIONS WHERE RDB$SYSTEM_FLAG = 0'`);
    const [nullCounts, setNullCounts] = useState({});
    const rawQueryTitle = useRef(null);

    const fetchTables = (refresh = false) => {
        const storedTables = localStorage.getItem('tablesData');
        
        setLoadingTables(true);

        if (storedTables && !refresh) {
            setTables(JSON.parse(storedTables));
            setFilteredTables(JSON.parse(storedTables));
            setLoadingTables(false);
        } else {
            setTables([]);
            
            const queryTables = httpsCallable(functions, 'query_api_v2');
            queryTables({ query: "SELECT TRIM(RDB$RELATION_NAME) AS RDB$RELATION_NAME FROM RDB$RELATIONS WHERE RDB$SYSTEM_FLAG = 0" })
                .then(async (result) => {
                    
                    const tablesWithCounts = await Promise.all(result.data.map(async (table) => {
                        const countRowsQuery = `SELECT COUNT(*) FROM ${table.RDB$RELATION_NAME}`;
                        const countFieldsQuery = `SELECT COUNT(*) FROM RDB$RELATION_FIELDS WHERE RDB$RELATION_NAME = '${table.RDB$RELATION_NAME}' AND RDB$SYSTEM_FLAG = 0`;
                        const countRowsResult = await queryTables({ query: countRowsQuery });
                        const countFieldsResult = await queryTables({ query: countFieldsQuery });
                        
                        return {
                            ...table,
                            count: countRowsResult.data[0].COUNT,
                            fieldsCount: countFieldsResult.data[0].COUNT
                        };
                    }));

                    setTables(tablesWithCounts);
                    setFilteredTables(tablesWithCounts);
                    localStorage.setItem('tablesData', JSON.stringify(tablesWithCounts));
                    setLoadingTables(false);
                })
                .catch((error) => {
                    console.error('Error fetching tables:', error);
                    setLoadingTables(false);
                });
        }
    };

    const fetchTableData = async (tableName, dataPage = 1, sortConfig = { key: null, direction: 'none' }) => {
        const offset = (dataPage - 1) * 12;
        let query = `SELECT FIRST 12 SKIP ${offset} * FROM ${tableName}`;
        if (sortConfig.key && sortConfig.direction !== 'none') {
            query += ` ORDER BY ${sortConfig.key} ${sortConfig.direction === 'asc' ? 'ASC' : 'DESC'}`;
        }
        const fetchData = httpsCallable(functions, 'query_api_v2');
        setLoadingData(true);

        try {
            const result = await fetchData({ query });
            setData(result.data);
            setSelectedTable(tableName);

            // Inicializar el conteo de nulos
            const nullCounts = {};

            // Asegúrate de obtener solo las columnas una vez que tengas resultados
            if (result.data.length > 0) {
                const columns = Object.keys(result.data[0]);

                // Bucle para contar valores nulos por cada columna
                for (const column of columns) {
                    const nullCountQuery = `SELECT COUNT(*) AS null_count FROM ${tableName} WHERE COALESCE(${column}, '') = ''`;
                    // WHERE ${column} IS NULL`;
                    const nullCountResult = await fetchData({ query: nullCountQuery });
                    nullCounts[column] = nullCountResult.data[0].NULL_COUNT;
                }
            }

            setNullCounts(nullCounts); // Asegúrate de haber definido este estado
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoadingData(false);
        }
    };

    const executeRawQuery = async (rawQueryParam = null) => {
        setCurrentDocId(null);
        setLikeState("none");
        if (!rawQueryParam) {
            setResponseQuery(null);
        }

        const rawQueryConst = rawQueryParam || rawQuery;

        const queryApi = httpsCallable(functions, 'queryBigQuery');
        setLoadingRawQuery(true);

        try {
            const result = await queryApi({ query: rawQueryConst });

            const newResult = {
                rawResults: JSON.stringify(result.data, null, 2), // Guarda los resultados en data
                query: rawQueryConst,
                canvasId: `canvas${Math.random().toString(36).substring(2, 15)}`,
            };

            setResultsHistory(prevHistory => [newResult, ...prevHistory]); // Añadir al inicio del historial

            setRawResults(JSON.stringify(result.data, null, 2)); // Pretty-print JSON

            setLoadingRawQuery(false);

            // wait 0.5 seconds for the JSON to render before scrolling to it
            setTimeout(() => {
                if (rawQueryTitle.current) rawQueryTitle.current.scrollIntoView({ behavior: 'smooth' });
            }, 500);

            return newResult;
        } catch (error) {
            const errorResult = {
                rawResults: JSON.stringify(error, null, 2), // Guarda los resultados en data
                query: rawQueryConst,
                canvasId: `canvas${Math.random().toString(36).substring(2, 15)}`,
            };
            setResultsHistory(prevHistory => [errorResult, ...prevHistory]); // Añadir al inicio del historial
            setRawResults(JSON.stringify(error, null, 2)); // Pretty-print JSON
            setLoadingRawQuery(false);
        }
    };

    const executeNaturalQuery = async (promptParam = null, temperatureParam = 0.0) => {
        setCurrentDocId(null);
        setLikeState("none");

        const generate_firebird_query = httpsCallable(functions, 'generateBigQueryQuery'); // 'generate_firebird_query_v4');


        const promptConst = promptParam || naturalQuery;
        const temperatureConst = temperatureParam;

        if (temperatureConst > 0.0) {
            setLoadingRandomQuery(true);
        } else {
            setLoadingRawQuery(true);
        }

        try {

            const response = await generate_firebird_query({ prompt: promptConst, temperature: temperatureConst });

            setResponseQuery("" + response.data.query_generated); // "-- Consulta generada automáticamente: \n\n"

            const rawResults = JSON.stringify(response.data.response, null, 2);
            const newResult = {
                rawResults: rawResults, // Guarda los resultados en data
                query: response.data.query_generated,
                naturalQuery: promptConst,
                random: temperatureConst > 0.0,
                canvasId: `canvas${Math.random().toString(36).substring(2, 15)}`,
            };

            setRawResults(rawResults); // Pretty-print JSON
            setResultsHistory(prevHistory => [newResult, ...prevHistory]); // Añadir al inicio del historial

            setTimeout(() => {
                if (rawQueryTitle.current) rawQueryTitle.current.scrollIntoView({ behavior: 'smooth' });
            }, 500);

            return newResult;
        } catch (error) {
            setResponseQuery(JSON.stringify(error, null, 2)); // Pretty-print JSON
            const errorResult = {
                rawResults: JSON.stringify(error, null, 2), // Guarda los resultados en data
                query: error && error.message ? error.message : 'Error generando la consulta',
                naturalQuery: naturalQuery,
                canvasId: `canvas${Math.random().toString(36).substring(2, 15)}`,
            };
            setResultsHistory(prevHistory => [errorResult, ...prevHistory]); // Añadir al inicio del histor
            return errorResult;
        } finally {
            setLoadingRawQuery(false);
            setLoadingRandomQuery(false);
        }
    };

    const toggleTableSort = (tableSortCriterionParam, tableSortOrderParam) => {
        let sortedTables;
        setTablePage(1);
        switch (tableSortOrderParam) {
            case 'default':
                sortedTables = [...tables].sort((a, b) => tableSortCriterionParam === 'rows' ? b.count - a.count : b.fieldsCount - a.fieldsCount);
                setTableSortOrder('desc');
                break;
            case 'desc':
                sortedTables = [...tables].sort((a, b) => tableSortCriterionParam === 'rows' ? a.count - b.count : a.fieldsCount - b.fieldsCount);
                setTableSortOrder('asc');
                break;
            case 'asc':
                sortedTables = [...tables];
                setTableSortOrder('default');
                break;
            default:
                sortedTables = [...tables];
                setTableSortOrder('default');
        }
        setFilteredTables(sortedTables);
    };


    const handleTableDataSort = (key) => {
        let direction = 'desc';
        if (sortConfig.key === key && sortConfig.direction === 'desc') {
            direction = 'asc';
        } else if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'none';
        }
        setSortConfig({ key, direction });
        setDataPage(1);
        fetchTableData(selectedTable, 1, { key, direction });
    };

    return {
        tables,
        filteredTables,
        setFilteredTables,
        data,
        searchTerm,
        setSearchTerm,
        selectedTable,
        rawQuery,
        setRawQuery,
        rawQueryTitle,
        naturalQuery,
        setNaturalQuery,
        responseQuery,
        setResponseQuery,
        rawResults,
        setRawResults,
        loadingData,
        loadingRawQuery,
        loadingTables,
        fetchTables,
        fetchTableData,
        executeRawQuery,
        executeNaturalQuery,
        toggleTableSort,
        handleTableDataSort,
        sortConfig,
        tablePage,
        setTablePage,
        tableSortOrder,
        setTableSortOrder,
        dataPage,
        setDataPage,
        contextText,
        setContextText,
        nullCounts,
        currentDocId,
        setCurrentDocId,
        likeState,
        setLikeState,
        resultsHistory,
        setResultsHistory,
        loadingRandomQuery,
    };
};

export default useDB;
