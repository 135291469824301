import React, { useState } from 'react';
import Select, { components } from 'react-select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Opción personalizada para incluir banderas
const { Option, DropdownIndicator } = components;
const FlagOption = (props) => (
    <Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={`https://flagcdn.com/24x18/${props.data.value.toLowerCase()}.png`} style={{ width: 24, height: 18 }} alt={props.data.value} />
        </div>
    </Option>
);

// Componente personalizado para el indicador del desplegable
const CustomDropdownIndicator = (props) => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <DropdownIndicator {...props}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <KeyboardArrowDownIcon
                style={{
                    transform: props.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: '0.3s',
                    color: isHovered ? '#6b86ad' : 'white', // Cambia el color al hacer hover
                }}
            />
        </DropdownIndicator>
    );
};

export default function CountrySelector({ selectedCountryCode, setSelectedCountryCode }) {
    const countries = ['CA', 'US', 'MX', 'BR', 'ES', 'GB', 'FR', 'BE', 'NL', 'DE', 'IT', 'SE', 'ZA', 'PL', 'EG', 'TR', 'SA', 'AE', 'IN', 'SG', 'AU', 'JP'].map(code => ({ value: code, label: code }));

    const FlagSingleValue = ({ ...props }) => (
        <components.SingleValue {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img src={`https://flagcdn.com/24x18/${props.data.value.toLowerCase()}.png`} style={{ width: 24 }} alt={props.data.value} />
            </div>
        </components.SingleValue>
    );

    // Estilos personalizados
    const customStyles = {
        control: (provided) => ({
            ...provided,
            background: 'transparent',
            borderColor: 'transparent',
            boxShadow: 'none',
            '&:hover': {
                borderColor: 'transparent'
            },
            padding: 0,
            minHeight: 'initial', // Puede ayudar a reducir el tamaño si hay espacio extra no deseado
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '2px 8px',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            padding: 0,
            // Intenta eliminar cualquier sombra o borde aquí también
            border: 'none', // Elimina bordes
            boxShadow: 'none', // Elimina sombras
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            padding: 0,
            margin: 0,
            // Adicionalmente, asegura que no haya sombras o bordes
            svg: {
                display: 'block', // Asegura que el SVG del ícono llene el espacio
            }
        }),
        menu: (provided) => ({
            ...provided,
            background: '#ced7e4',
            borderRadius: '20px',
            padding: '12px 0', // Ajusta este valor según sea necesario
        }),
        option: (provided, state) => ({
            ...provided,
            background: state.isSelected ? '#6b86ad' : 'transparent',
            '&:hover': {
                background: '#adbcd2',
            },
        }),
    };

    return (
        <div onClick={(e) => e.stopPropagation()} className="flex flex-col items-start justify-start pl-3">
            <Select
                options={countries}
                components={{ Option: FlagOption, SingleValue: FlagSingleValue, DropdownIndicator: CustomDropdownIndicator, IndicatorSeparator: () => null }}
                onChange={(option) => setSelectedCountryCode(option.value)}
                value={countries.find(option => option.value === selectedCountryCode)}
                isSearchable={true}
                styles={customStyles}
                classNamePrefix="react-select"
            />
        </div>
    );
}
