import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const originalWarn = console.warn.bind(console);
const originalError = console.error.bind(console);

console.warn = console.error = (message, ...args) => {
  if (typeof message === 'string' && !message.toLowerCase().includes('children with the same key')) {
    if (message.startsWith('Warning:')) {
      if (args.length > 0 && typeof args[0] === 'string' && args[0].startsWith('rectangle')) {
        return;
      }
      originalWarn(message, ...args);
    } else {
      originalError(message, ...args);
    }
  }
};

root.render(
  <App />
);