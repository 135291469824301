import React from 'react';
import { Menu, MenuItem, Checkbox, Divider } from '@mui/material';

function CampaignsMenu({ campaignMenuAnchorEl, handleCloseCampaignMenu, store, includeCampaigns, handleAllCampaignsToggle, handleChooseCampaign }) {
    return (
        <>
            {store && store.id.startsWith('ml') &&
                <Menu
                    id="campaign-menu"
                    anchorEl={campaignMenuAnchorEl}
                    keepMounted
                    open={Boolean(campaignMenuAnchorEl)}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    onClose={handleCloseCampaignMenu}
                >
                    <p className="text-black mx-4 my-2">
                        Incluir Campañas
                    </p>

                    <Divider />

                    <MenuItem onClick={handleAllCampaignsToggle}>
                        <Checkbox checked={store ? includeCampaigns.length === store.data.campanas.length : false} />
                        Todas las campañas
                    </MenuItem>

                    {store && store.data && store.data.campanas && store.data.campanas.map((campana, index) => (
                        <MenuItem key={index} onClick={() => handleChooseCampaign(campana)}>
                            <Checkbox checked={includeCampaigns.includes(campana)} />
                            {campana.name}
                        </MenuItem>
                    ))}
                </Menu>
            }
        </>
    );
}

export default CampaignsMenu;