import React, { useState, useEffect, useCallback } from 'react';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, addDays, format, isSameMonth, isWithinInterval, isSameDay, isAfter, isBefore, getDay, isFuture } from 'date-fns';

const Calendar = ({ monthProp, yearProp, startDateProp, endDateProp, comparisonStartDateProp, comparisonEndDateProp, onDateSelect, hoverDate, setHoverDate }) => {
    const monthStart = startOfMonth(new Date(yearProp, monthProp, 1));
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;

    const isInRange = (date, start, end) => {
        return isWithinInterval(date, { start, end });
    };

    const [selectionPhase, setSelectionPhase] = useState(0);

    const handleDateClick = useCallback((date) => {
        onDateSelect(date, selectionPhase);
        setSelectionPhase((prev) => (prev + 1) % 4);
    }, [onDateSelect, selectionPhase]);

    const getHoverColor = useCallback(() => {
        return selectionPhase < 2 ? 'border-[#416A8B]' : 'border-[#B4B4B4]';
    }, [selectionPhase]);

    const isInHoverRange = useCallback((date) => {
        if (!hoverDate || (selectionPhase !== 1 && selectionPhase !== 3)) return false;
        const rangeStart = selectionPhase === 1 ? startDateProp : comparisonStartDateProp;
        return (isAfter(date, rangeStart) && isBefore(date, hoverDate)) || isSameDay(date, rangeStart) || isSameDay(date, hoverDate);
    }, [hoverDate, selectionPhase, startDateProp, comparisonStartDateProp]);

    const getBorderClasses = useCallback((date) => {
        if (!isInHoverRange(date) || isFuture(date)) return 'border-0';

        const rangeStart = selectionPhase === 1 ? startDateProp : comparisonStartDateProp;
        const classes = [];

        const prevDay = addDays(date, -1);
        const nextDay = addDays(date, 1);
        const aboveDay = addDays(date, -7);
        const belowDay = addDays(date, 7);
        const dayOfWeek = getDay(date);

        // Siempre borde izquierdo para domingos (0) en hover range
        if (dayOfWeek === 0 && isInHoverRange(date)) {
            classes.push('border-l-1');
        } else if (isSameDay(date, rangeStart) || !isInHoverRange(prevDay)) {
            classes.push('border-l-1');
        } else {
            classes.push('border-l-0');
        }

        // Siempre borde derecho para sábados (6) en hover range
        if (dayOfWeek === 6 && isInHoverRange(date)) {
            classes.push('border-r-1');
        } else if (isSameDay(date, hoverDate) || !isInHoverRange(nextDay)) {
            classes.push('border-r-1');
        } else {
            classes.push('border-r-0');
        }

        if (!isInHoverRange(aboveDay)) {
            classes.push('border-t-1');
        } else {
            classes.push('border-t-0');
        }

        if (!isInHoverRange(belowDay)) {
            classes.push('border-b-1');
        } else {
            classes.push('border-b-0');
        }

        return classes.join(' ');
    }, [isInHoverRange, selectionPhase, startDateProp, comparisonStartDateProp, hoverDate]);

    while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
            const cloneDay = day;
            const formattedDate = format(cloneDay, dateFormat);
            const isCurrentMonth = isSameMonth(cloneDay, monthStart) && !(cloneDay > new Date());

            const isInMainRange = isInRange(cloneDay, startDateProp, endDateProp);
            const isInComparisonRange = isInRange(cloneDay, comparisonStartDateProp, comparisonEndDateProp);
            const isInHover = isInHoverRange(cloneDay);

            let bgColor = '';
            let borderColor = getHoverColor();

            if (isInMainRange && isInComparisonRange) {
                bgColor = 'bg-[#657684] text-white';
            } else if (isInMainRange) {
                bgColor = 'bg-[#416A8B] text-white';
            } else if (isInComparisonRange) {
                bgColor = 'bg-[#B4B4B4] text-white';
            }

            const borderClasses = getBorderClasses(cloneDay);
            const isStartDate = isSameDay(cloneDay, startDateProp) || isSameDay(cloneDay, comparisonStartDateProp);
            const isEndDate = isSameDay(cloneDay, endDateProp) || isSameDay(cloneDay, comparisonEndDateProp);

            days.push(
                <div
                    key={day.toISOString()}
                    onClick={() => { if (isCurrentMonth) handleDateClick(cloneDay) }}
                    onMouseEnter={() => setHoverDate(cloneDay)}
                    onMouseLeave={() => setHoverDate(null)}
                    className={`w-[14.29%] h-full text-xs text-center items-center flex flex-row justify-center
                    ${isCurrentMonth ? 'text-black cursor-pointer' : 'text-gray-400 cursor-not-allowed'}
                    ${isCurrentMonth ? `hover:border ${getHoverColor()}` : ''}
                    ${bgColor}
                    ${borderColor}
                    ${isInHover ? `border ${borderColor} ${borderClasses}` : ''}
                    ${isStartDate ? 'rounded-l-full-retomar-esto-despues' : ''} 
                    ${isEndDate ? 'rounded-r-full-retomar-esto-despues' : ''}
                    `}
                >
                    {formattedDate}
                </div>
            );
            day = addDays(day, 1);
        }
        rows.push(
            <div key={day} className="w-full h-8 flex flex-row">
                {days}
            </div>
        );
        days = [];
    }

    const formatMonthYear = (date) => {
        const monthName = date.toLocaleString('es-ES', { month: 'long' });
        const abbreviatedMonth = monthName.slice(0, 3);
        const capitalizedMonth = abbreviatedMonth.charAt(0).toUpperCase() + abbreviatedMonth.slice(1);
        return `${capitalizedMonth} ${date.getFullYear()}`;
    };

    return (
        <div className='flex flex-col w-1/2 h-52 justify-start py-2 items-center'>
            <span className='font-bold'>
                {formatMonthYear(monthStart)}
            </span>
            <div className='w-full h-8 flex flex-row'>
                {["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"].map((day, index) => (
                    <div
                        key={index}
                        className="w-[14.29%] h-full text-xs text-gray-500 text-center items-center flex flex-row justify-center"
                    >
                        {day}
                    </div>
                ))}
            </div>
            {rows}
        </div>
    );
};

const TFThirdSection = ({ startDate, endDate, comparisonStartDate, comparisonEndDate, setStartDate, setEndDate, setComparisonStartDate, setComparisonEndDate, setShouldShowCustom }) => {
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [hoverDate, setHoverDate] = useState(null);

    const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 5 }, (_, i) => currentYear - i);

    const handlePrevious = () => {
        if (selectedMonth === 0 && selectedYear === currentYear - 4) {
            return;
        }
        if (selectedMonth === 0) {
            setSelectedMonth(11);
            setSelectedYear(prev => prev - 1);
        } else {
            setSelectedMonth(prev => prev - 1);
        }
    };

    const handleNext = () => {
        if (selectedYear === currentYear && selectedMonth == new Date().getMonth()) {
            return;
        }
        if (selectedMonth === 11) {
            setSelectedMonth(0);
            setSelectedYear(prev => prev + 1);
        } else {
            setSelectedMonth(prev => prev + 1);
        }
    };

    useEffect(() => {
        // si el año es menor a año - 4 return
        if (selectedYear < currentYear - 4) {
            return;
        }
        // si el año es mayor a año return
        if (selectedYear > currentYear) {
            return;
        }
        // si el mes es menor a 0 return
        if (selectedMonth < 0) {
            return;
        }
        // si el mes es mayor a 11 return
        if (selectedMonth > 11) {
            return;
        }

        // set selectedMonth and selectedYear to startDate
        setSelectedMonth(startDate.getMonth());
        setSelectedYear(startDate.getFullYear());
    }, [startDate]);

    useEffect(() => {
        // Aquí puedes manejar los cambios de mes y año
        // Por ejemplo, actualizar startDate y endDate
    }, [selectedMonth, selectedYear]);

    const handleDateSelect = useCallback((date, phase) => {
        switch (phase) {
            case 0:
                setStartDate(date);
                setEndDate(date);
                break;
            case 1:
                if (date < startDate) {
                    setStartDate(date);
                } else {
                    setEndDate(date);
                }
                setShouldShowCustom(true);
                break;
            case 2:
                setComparisonStartDate(date);
                setComparisonEndDate(date);
                break;
            case 3:
                if (date < comparisonStartDate) {
                    setComparisonStartDate(date);
                } else {
                    setComparisonEndDate(date);
                }
                setShouldShowCustom(true);
                break;
        }
    }, [startDate, comparisonStartDate]);

    return (
        <div className="bg-white w-[50%] text-black flex flex-col justify-start items-center font-normal">
            <div className='flex w-full flex-row justify-between px-3 items-center py-2'>
                <div
                    className={`rounded-full p-0.5 ${selectedMonth === 0 && selectedYear === currentYear - 4 ? 'text-gray-200 cursor-not-allowed' : 'hover:bg-gray-200 cursor-pointer'}`}
                    onClick={handlePrevious}
                >
                    <ArrowLeft />
                </div>
                <div className='flex flex-row justify-center items-center space-x-2'>
                    <select
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(Number(e.target.value))}
                        className="bg-transparent border-none text-black hover:bg-gray-100 py-2 px-2 rounded-md"
                    >
                        {months.map((month, index) => (
                            <option key={index} value={index}>{month}</option>
                        ))}
                    </select>
                    <select
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(Number(e.target.value))}
                        className="bg-transparent border-none text-black hover:bg-gray-100 py-2 px-2 rounded-md"
                    >
                        {years.map((year) => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
                <div
                    className={`rounded-full p-0.5 ${selectedMonth === new Date().getMonth() && selectedYear === currentYear ? 'text-gray-200 cursor-not-allowed' : 'hover:bg-gray-200 cursor-pointer'}`}
                    onClick={handleNext}
                >
                    <ArrowRight />
                </div>
            </div>
            <div className='flex select-none flex-row justify-center items-start space-x-2 w-full p-2'>
                <Calendar
                    monthProp={selectedMonth}
                    yearProp={selectedYear}
                    startDateProp={startDate}
                    endDateProp={endDate}
                    comparisonStartDateProp={comparisonStartDate}
                    comparisonEndDateProp={comparisonEndDate}
                    onDateSelect={handleDateSelect}
                    hoverDate={hoverDate}
                    setHoverDate={setHoverDate}
                />
                <Calendar
                    monthProp={(selectedMonth + 1) % 12}
                    yearProp={selectedMonth === 11 ? selectedYear + 1 : selectedYear}
                    startDateProp={startDate}
                    endDateProp={endDate}
                    comparisonStartDateProp={comparisonStartDate}
                    comparisonEndDateProp={comparisonEndDate}
                    onDateSelect={handleDateSelect}
                    hoverDate={hoverDate}
                    setHoverDate={setHoverDate}
                />
            </div>
            <div className="flex justify-center space-x-4 mt-4 px-4 text-sm">
                <div className="flex items-center">
                    <div className="w-4 h-4 bg-[#B4B4B4] mr-2 rounded-full shadow-sm border"></div>
                    <span>Rango de comparación</span>
                </div>
                <div className="flex items-center">
                    <div className="w-4 h-4 bg-[#416A8B] mr-2 rounded-full shadow-sm border"></div>
                    <span>Rango principal</span>
                </div>
            </div>
            {/* <div className="flex justify-center space-x-4 mt-4 px-4 text-sm">
                <div className="flex items-center">
                    <div className="w-4 h-4 bg-[#657684] mr-2 rounded-full shadow-sm border"></div>
                    <span>Rango de comparación y principal</span>
                </div>
            </div> */}
        </div>
    );
};

export default TFThirdSection;
