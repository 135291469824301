import React, { useEffect, useState } from 'react';
import { NavLink, Link, useLocation, useNavigate } from 'react-router-dom';
import newLogoKompaMxAnalytics from '../assets/LogoKompaMxAzul.png';
import icon1 from '../assets/sidebar/icon1.png';
import icon2 from '../assets/sidebar/icon2.png';
import icon3 from '../assets/sidebar/icon3.png';
import AddIcon from '@mui/icons-material/Add';
import './Sidebar.css';
import { useTopbar } from '../helpers/TopbarContext';
import { Close } from '@mui/icons-material';
import useAuth from '../hooks/useAuth';

function Sidebar() {
    const [openMisTiendas, setOpenMisTiendas] = useState(false);
    const [openComparador, setOpenComparador] = useState(false);
    const location = useLocation();
    const { shouldSidebarBeOpen, setShouldSidebarBeOpen } = useTopbar();
    const { currentUser } = useAuth();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const tableroStyle = {
        width: '50px',
        height: '50px',
        border: (location.pathname.startsWith('/tablero') || location.pathname === '/') ? '2.5px solid #416A8B' : '1.5px solid #FFF', // Cambia el estilo del borde aquí
        borderRadius: '10px',
        padding: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    };

    const misTiendasStyle = {
        width: '50px',
        height: '50px',
        border: location.pathname.startsWith('/mis-tiendas') ? '2.5px solid #416A8B' : '1.5px solid #FFF', // Cambia el estilo del borde aquí
        borderRadius: '10px',
        padding: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    };

    const comparadorStyle = {
        width: '50px',
        height: '50px',
        border: location.pathname.startsWith('/comparador') ? '2.5px solid #416A8B' : '1.5px solid #FFF', // Cambia el estilo del borde aquí
        borderRadius: '10px',
        padding: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    };

    const agregarTiendaStyle = {
        width: '50px',
        height: '50px',
        border: location.pathname.startsWith('/agregar-tienda') ? '2.5px solid #416A8B' : '1.5px solid #FFF', // Cambia el estilo del borde aquí
        borderRadius: '10px',
        padding: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        minWidth: '50px' // Asegura que el elemento no se haga más pequeño que 50px
    };

    // Aquí se calcula el ancho del sidebar en función del estado de openMisTiendas u openComparador
    const sidebarStyle = {
        width: (openMisTiendas || openComparador || shouldSidebarBeOpen) ? (screenWidth > 768 ? "300px" : screenWidth) : (screenWidth > 768 ? "75px" : "0px"), // Cambia el ancho según si alguna está abierta 275px : 75px,  screenWidth : '0px'
        height: '100%',
        backgroundColor: '#B4B4B4',
        display: 'flex',
        flexDirection: 'column',
        transition: 'width 0.3s ease-in-out', // Aplica una transición suave al cambiar el ancho
        color: 'white',
        position: 'fixed',
        zIndex: 98,
        top: 0,
        left: 0,
        overflow: 'hidden',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    };

    const backdropStyles = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 97,
        transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
        opacity: 0,
        visibility: 'hidden',
    };

    useEffect(() => {
        const backdrop = document.getElementById('sidebar-backdrop');
        if (backdrop) {
            if (openComparador || openMisTiendas || shouldSidebarBeOpen) {
                backdrop.style.visibility = 'visible';
                setTimeout(() => {
                    backdrop.style.opacity = 1;
                }, 0);
            } else {
                backdrop.style.opacity = 0;
                setTimeout(() => {
                    backdrop.style.visibility = 'hidden';
                }, 300);
            }
        }
    }, [openComparador, openMisTiendas, shouldSidebarBeOpen]);

    return (
        <>
            <div id="sidebar-backdrop" style={backdropStyles} onClick={() => {
                setOpenMisTiendas(false);
                setOpenComparador(false);
                setShouldSidebarBeOpen(false);
            }}></div>

            <div style={sidebarStyle}>
                {(shouldSidebarBeOpen || openMisTiendas || openComparador) && (screenWidth < 768) &&
                    <Close
                        onClick={(e) => {
                            e.preventDefault();
                            setOpenMisTiendas(false);
                            setOpenComparador(false);
                            setShouldSidebarBeOpen(false)
                        }}
                        style={{ color: 'white', position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', zIndex: 98 }}
                        fontSize='large'

                    />
                }
                <ul className="flex flex-col list-none space-y-3 py-2" style={{ flex: 1 }}>
                    <div className="flex flex-row items-center justify-start py-3 relative transition-all duration-100 ease-in-out">
                        <Link
                            to="/"
                            className={` min-w-[4.7rem] items-center justify-center transition-none flex`}
                            onClick={() => {
                                setOpenComparador(false);
                                setOpenMisTiendas(false);
                                setShouldSidebarBeOpen(false);
                            }}
                        >
                            <img
                                src={newLogoKompaMxAnalytics}
                                alt="Logo KompaMx Analytics"
                                style={{ width: '40px' }}
                                className="-mt-2"
                            />
                        </Link>

                        <div className={`flex flex-col items-start w-full -ml-3 -mt-[0.125rem] ${openComparador || openMisTiendas || shouldSidebarBeOpen ? '' : ''}`}>
                            <p className={`font-semibold text-xl ml-3 max-w-0 transition-all overflow-hidden whitespace-nowrap duration-100 ease-in-out ${(openComparador || openMisTiendas || shouldSidebarBeOpen) ? 'pr-52' : ''}`}>
                                Bienvenido
                            </p>
                            <p className={`font-semibold text-white  w-full -mt-1 max-w-0 text-xl ml-3 transition-all overflow-hidden whitespace-nowrap duration-100 ease-in-out ${(openComparador || openMisTiendas || shouldSidebarBeOpen) ? 'pr-52' : ''}`}>
                                {/* ANALYTICS */}
                                {currentUser && currentUser.email && currentUser.email.split('@')[0][0].toUpperCase() + currentUser.email.split('@')[0].slice(1)}
                            </p>
                        </div>
                    </div>

                    <li className={`flex justify-start ml-3 items-center py-3 ${location.pathname.startsWith('/tablero' || location.pathname === '/') ? '' : ''}`}>
                        <div
                            onClick={() => {
                                setOpenComparador(false);
                                setOpenMisTiendas(false);
                                setShouldSidebarBeOpen(!shouldSidebarBeOpen);
                            }}
                        >
                            <div
                                style={tableroStyle}
                                className="flex items-center cursor-pointer font-semibold text-lg relative transition-all duration-100 ease-in-out bg-[#B4B4B4] hover:bg-[#aaaaaa]"
                            >
                                <img
                                    src={icon1}
                                    alt="Tablero"
                                    className="h-8"
                                />
                                <p
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setOpenComparador(false);
                                        setOpenMisTiendas(false);
                                        setShouldSidebarBeOpen(false);
                                        // navigate to tablero
                                        navigate('/');
                                    }}
                                    className={`ml-5 transition-all overflow-hidden duration-100 ease-in-out ${(openComparador || openMisTiendas || shouldSidebarBeOpen) ? 'pr-52' : ''}`}
                                >
                                    Tablero
                                </p>
                            </div>
                        </div>
                    </li>
                    <li className={`flex flex-col justify-start items-start ml-3 py-3 ${location.pathname.startsWith('/mis-tiendas') ? '' : ''}`}>
                        <div
                            onClick={() => {
                                setOpenMisTiendas(false)
                                setOpenComparador(false);
                                setShouldSidebarBeOpen(false);
                                setShouldSidebarBeOpen(!shouldSidebarBeOpen);
                            }}
                            style={misTiendasStyle}
                            className="flex items-center cursor-pointer font-semibold text-lg relative  transition-all duration-100 ease-in-out bg-[#B4B4B4] hover:bg-[#aaaaaa]"
                        >
                            <img
                                src={icon2}
                                alt="Mis Tiendas"
                                className="h-8 cursor-pointer"
                            />
                            <p
                                className={`transition-all overflow-hidden whitespace-nowrap duration-100 ease-in-out ml-5 ${(openComparador || openMisTiendas || shouldSidebarBeOpen) ? 'pr-52' : ''}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setOpenMisTiendas(!openMisTiendas)
                                    setOpenComparador(false);
                                    setShouldSidebarBeOpen(true);
                                }}
                            >
                                Mis Tiendas
                            </p>
                        </div>
                        <ul className={`border-l border-customlightgray list-none font-normal text-sm w-full transition-all duration-500 text-white ease-in-out ${openMisTiendas ? 'max-h-40 ml-14 ' : 'max-h-0 overflow-hidden'} ${location.pathname.startsWith('/mis-tiendas') ? '' : ''}`}>
                            <li className="">
                                <NavLink
                                    to="/mis-tiendas/graficos"
                                    className={({ isActive }) => `transition-all duration-300 ease-in-out h-full w-full py-3 px-2 flex whitespace-nowrap hover:bg-customlightgrays-450 ${isActive ? 'bg-customlightgray hover:bg-customlightgrays-550' : ''}`}
                                    onClick={() => {
                                        setOpenMisTiendas(false);
                                        setOpenComparador(false);
                                        setShouldSidebarBeOpen(false);
                                    }}
                                >
                                    Gráficos y Análisis
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/mis-tiendas/tabla-de-productos"
                                    className={({ isActive }) => `transition-all duration-300 ease-in-out h-full w-full py-3 px-2 flex whitespace-nowrap hover:bg-customlightgrays-450 ${isActive ? 'bg-customlightgray hover:bg-customlightgrays-550' : ''}`}
                                    onClick={() => {
                                        setOpenMisTiendas(false);
                                        setOpenComparador(false);
                                        setShouldSidebarBeOpen(false);
                                    }}
                                >
                                    Tabla de productos
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/mis-tiendas/metricas-de-reputacion"
                                    className={({ isActive }) => `transition-all duration-300 ease-in-out h-full w-full py-3 px-2 flex whitespace-nowrap hover:bg-customlightgrays-450 ${isActive ? 'bg-customlightgray hover:bg-customlightgrays-550' : ''}`}
                                    onClick={() => {
                                        setOpenMisTiendas(false);
                                        setOpenComparador(false);
                                        setShouldSidebarBeOpen(false);
                                    }}
                                >
                                    Métricas de Reputación
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li
                        className={`flex flex-col justify-start items-start ml-3 py-3 ${location.pathname.startsWith('/comparador') ? '' : ''}`}
                    >
                        <div
                            onClick={() => {
                                setOpenComparador(false)
                                setOpenMisTiendas(false);
                                setShouldSidebarBeOpen(!shouldSidebarBeOpen);
                            }}
                            style={comparadorStyle}
                            className="flex items-center cursor-pointer font-semibold text-lg relative  transition-all duration-100 ease-in-out bg-[#B4B4B4] hover:bg-[#aaaaaa]"
                        >
                            <img
                                src={icon3}
                                alt="Comparador"
                                className="h-8 "
                            />
                            <p
                                className={`ml-5  transition-all overflow-hidden duration-100 ease-in-out ${(openComparador || openMisTiendas || shouldSidebarBeOpen) ? 'pr-52' : ''}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setOpenComparador(!openComparador)
                                    setOpenMisTiendas(false);
                                    setShouldSidebarBeOpen(true);
                                }}
                            >
                                Comparador
                            </p>

                        </div>
                        <ul className={`border-l border-customlightgray list-none font-normal text-sm w-full transition-all duration-500 text-white ease-in-out ${openComparador ? 'max-h-40 ml-14' : 'max-h-0 overflow-hidden'} ${location.pathname.startsWith('/comparador') ? '' : ''}`}>
                            <li>
                                <NavLink
                                    to="/comparador/graficos"
                                    className={({ isActive }) => `transition-all duration-300 ease-in-out h-full w-full py-3 px-2 flex whitespace-nowrap hover:bg-customlightgrays-450 ${isActive ? 'bg-customlightgray hover:bg-customlightgrays-550' : ''}`}
                                    onClick={() => {
                                        setOpenComparador(false);
                                        setOpenMisTiendas(false);
                                        setShouldSidebarBeOpen(false);
                                    }}
                                >
                                    Gráficos y Análisis
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/comparador/comparativa-de-productos"
                                    className={({ isActive }) => `transition-all duration-300 ease-in-out h-full w-full py-3 px-2 flex whitespace-nowrap hover:bg-customlightgrays-450 ${isActive ? 'bg-customlightgray hover:bg-customlightgrays-550' : ''}`}
                                    onClick={() => {
                                        setOpenComparador(false);
                                        setOpenMisTiendas(false);
                                        setShouldSidebarBeOpen(false);
                                    }}
                                >
                                    Comparativa de productos
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to="/comparador/graficos-por-producto"
                                    className={({ isActive }) => `transition-all duration-300 ease-in-out h-full w-full py-3 px-2 flex whitespace-nowrap hover:bg-customlightgrays-450 ${isActive ? 'bg-customlightgray hover:bg-customlightgrays-550' : ''}`}
                                    onClick={() => {
                                        setOpenComparador(false);
                                        setOpenMisTiendas(false);
                                        setShouldSidebarBeOpen(false);
                                    }}
                                >
                                    Gráficos por producto
                                </NavLink>
                            </li>
                        </ul>
                    </li>

                    {/* 
                Feature disabled for the time being

                <li className={`flex justify-start  ml-3 items-center py-3 ${location.pathname.startsWith('/aichat' || location.pathname === '/') ? '' : ''}`}>
                    <div
                        onClick={() => {
                            setOpenComparador(false);
                            setOpenMisTiendas(false);
                            setShouldSidebarBeOpen(!shouldSidebarBeOpen);
                        }}
                        className=''
                    >
                        <div
                            style={aiChatStyle}
                            className="flex items-center cursor-pointer font-semibold text-lg relative transition-all duration-100 ease-in-out bg-[#939292] hover:bg-[#aaaaaa]"
                        >
                            <img
                                src={icon4}
                                alt="Chat"
                                className="h-8"
                            />
                            <p
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setOpenComparador(false);
                                    setOpenMisTiendas(false);
                                    setShouldSidebarBeOpen(false);
                                    // navigate to aichat
                                    navigate('/aichat');
                                }}
                                className={`ml-5 transition-all overflow-hidden duration-100 ease-in-out ${(openComparador || openMisTiendas || shouldSidebarBeOpen) ? 'pr-52' : ''}`}
                            >
                                Chat
                            </p>
                        </div>
                    </div>
                </li> 
                */}
                </ul>
                <div
                    className="group flex flex-row justify-start items-center ml-3 py-3 mb-3"
                    onClick={() => {
                        setOpenComparador(false);
                        setOpenMisTiendas(false);
                        setShouldSidebarBeOpen(!shouldSidebarBeOpen);
                    }}
                >
                    <div style={agregarTiendaStyle} className="flex items-center cursor-pointer font-semibold text-lg relative transition-all duration-100 ease-in-out bg-[#B4B4B4] group-hover:bg-[#aaaaaa]">
                        <AddIcon style={{ color: 'white', flex: 1 }} />
                    </div>
                    <p
                        className={`font-semibold select-none cursor-pointer text-lg ml-3 max-w-0 transition-all overflow-hidden whitespace-nowrap duration-100 ease-in-out ${(openComparador || openMisTiendas || shouldSidebarBeOpen) ? 'pr-52' : ''}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setOpenComparador(false);
                            setOpenMisTiendas(false);
                            setShouldSidebarBeOpen(false);
                            // navigate to agregar-tienda
                            navigate('/agregar-tienda');
                        }}
                    >
                        Agregar Tienda
                    </p>
                </div>
            </div >
        </>
    );
}

export default Sidebar;
