import React, { useEffect, useState } from 'react'
import { auth, db, functions } from '../helpers/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Download, FindInPage } from '@mui/icons-material';

export default function Ads() {
    const [adsAccounts, setAdsAccounts] = useState([]);
    const [loadingFetchAmazonAdsAccounts, setLoadingFetchAmazonAdsAccounts] = useState(false);
    const [loadingGetCampaigns, setLoadingGetCampaigns] = useState({});
    const [loadingGetAdvertisedProductReport, setLoadingGetAdvertisedProductReport] = useState({});
    const [loadingDownloadReportSpAdvertisedProductReport, setLoadingDownloadReportSpAdvertisedProductReport] = useState({});
    const [loadingParseSpAdvertisedProductReport, setLoadingParseSpAdvertisedProductReport] = useState({});

    useEffect(() => {
        const fetchAdsData = async () => {
            setLoadingFetchAmazonAdsAccounts(true);
            // from firestore 
            // users/{auth.currentUser.uid}/ads_accounts
            const adsAccountsCollection = collection(db, `users/${auth.currentUser.uid}/ads_accounts`);
            const adsAccountsSnapshot = await getDocs(adsAccountsCollection);

            const newAdsAccounts = adsAccountsSnapshot.docs.map(doc => {
                return {
                    id: doc.id,
                    ...doc.data()
                }
            });

            for (let i = 0; i < newAdsAccounts.length; i++) {
                const adsAccount = newAdsAccounts[i];

                // users/${auth.currentUser.uid}/ads_accounts/${adsAccount.id}/ads_profiles
                const adsProfilesCollection = collection(db, `users/${auth.currentUser.uid}/ads_accounts/${adsAccount.id}/ads_profiles`);
                const adsProfilesSnapshot = await getDocs(adsProfilesCollection);

                const newAdsProfiles = adsProfilesSnapshot.docs.map(doc => {
                    return {
                        id: doc.id,
                        ...doc.data()
                    }
                });

                newAdsAccounts[i].ads_profiles_data = newAdsProfiles;

                for (let j = 0; j < newAdsProfiles.length; j++) {
                    const adsProfile = newAdsProfiles[j];

                    // users/${auth.currentUser.uid}/ads_accounts/${adsAccount.id}/ads_profiles/${adsProfile.id}/ads_reports
                    const adsReportsCollection = collection(db, `users/${auth.currentUser.uid}/ads_accounts/${adsAccount.id}/ads_profiles/${adsProfile.id}/ads_reports`);
                    const adsReportsSnapshot = await getDocs(adsReportsCollection);

                    const newAdsReports = adsReportsSnapshot.docs.map(doc => {
                        return {
                            id: doc.id,
                            ...doc.data()
                        }
                    });

                    newAdsAccounts[i].ads_profiles_data[j].ads_reports = newAdsReports;
                }
            }

            setAdsAccounts(newAdsAccounts);
            setLoadingFetchAmazonAdsAccounts(false);
        }

        fetchAdsData();
    }, []);

    const getCampaigns = async (adAccountId, adProfileId, accessToken) => {
        try {
            setLoadingGetCampaigns(prev => ({ ...prev, [adProfileId]: true }));
            const getAmazonAdsCampaigns = httpsCallable(functions, 'getAmazonAdsCampaigns');
            const result = await getAmazonAdsCampaigns({ adAccountId, adProfileId, accessToken });
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingGetCampaigns(prev => ({ ...prev, [adProfileId]: false }));
        }
    }

    const getSpAdvertisedProductReport = async (adAccountId, adProfileId, accessToken, adsManagerAccountID) => {
        try {
            setLoadingGetAdvertisedProductReport(prev => ({ ...prev, [adProfileId]: true }));
            const getSpAdvertisedProductReport = httpsCallable(functions, 'getSpAdvertisedProductReport');
            const result = await getSpAdvertisedProductReport({ adAccountId, adProfileId, accessToken, adsManagerAccountID });
            // add to adsAccounts
            const newAdsAccounts = adsAccounts.map((adsAccount) => {
                if (adsAccount.ads_id === adsManagerAccountID) {
                    const newAdsProfiles = adsAccount.ads_profiles_data.map((adsProfile) => {
                        if (adsProfile.id === adProfileId) {
                            adsProfile.ads_reports = result.data;
                        }
                        return adsProfile;
                    });

                    return {
                        ...adsAccount,
                        ads_profiles_data: newAdsProfiles
                    }
                }

                return adsAccount;
            });

            setAdsAccounts(newAdsAccounts);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingGetAdvertisedProductReport(prev => ({ ...prev, [adProfileId]: false }));
        }
    }

    const downloadReportSpAdvertisedProductReport = async (adAccountId, adProfileId, accessToken, reportId, adsManagerAccountID) => {
        try {
            setLoadingDownloadReportSpAdvertisedProductReport(prev => ({ ...prev, [adProfileId]: true }));
            const downloadReportSpAdvertisedProductReport = httpsCallable(functions, 'downloadReportSpAdvertisedProductReport');
            const result = await downloadReportSpAdvertisedProductReport({ adAccountId, adProfileId, accessToken, reportId, adsManagerAccountID });
            // add to adsAccounts
            const newAdsAccounts = adsAccounts.map((adsAccount) => {
                const newAdsProfiles = adsAccount.ads_profiles_data.map((adsProfile) => {
                    if (adsProfile.id === adProfileId) {
                        const newAdsReports = adsProfile.ads_reports.map((adsReport) => {
                            if (adsReport.reportId === reportId) {
                                adsReport.isDownloaded = true;
                            }
                            return adsReport;
                        });

                        return {
                            ...adsProfile,
                            ads_reports: newAdsReports
                        }
                    }

                    return adsProfile;
                });

                return {
                    ...adsAccount,
                    ads_profiles_data: newAdsProfiles
                }
            });

            setAdsAccounts(newAdsAccounts);
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingDownloadReportSpAdvertisedProductReport(prev => ({ ...prev, [adProfileId]: false }));
        }
    }

    const parseSpAdvertisedProductReport = async (adAccountId, adProfileId, reportId, sellerId) => {
        try {
            setLoadingParseSpAdvertisedProductReport(prev => ({ ...prev, [adProfileId]: true }));
            const parseSpAdvertisedProductReport = httpsCallable(functions, 'parseSpAdvertisedProductReport');
            const result = await parseSpAdvertisedProductReport({ adAccountId, adProfileId, reportId, sellerId });
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingParseSpAdvertisedProductReport(prev => ({ ...prev, [adProfileId]: false }));
        }
    }

    return (
        <div
            className='p-16 w-screen flex flex-col justify-center items-center'
        >
            <h1
                className='text-4xl text-center font-bold'
            >
                Ads
            </h1>

            {loadingFetchAmazonAdsAccounts &&
                <div className='spinner my-4'></div>
            }

            {adsAccounts.map((adAccount) => {
                return <div key={adAccount.ads_id} className='bg-white p-4 m-4 rounded-md shadow-md flex flex-col justify-center items-center'>

                    <h2
                        className='text-2xl text-center font-semibold mb-4'
                    >
                        Ads Manager Account ID:
                    </h2>
                    <p>{adAccount.ads_id}</p>
                    {adAccount.ads_profiles_data.map((profile) => {
                        return <div key={profile.id} className='bg-gray-100 p-4 m-4 rounded-md shadow-md flex flex-col justify-center items-center'>
                            <h3
                                className='text-xl text-center font-semibold mb-4'
                            >
                                {profile.accountInfo.name}
                            </h3>

                            <p>
                                <span
                                    className='text-center font-semibold mr-2'
                                >
                                    Profile ID:
                                </span>

                                {profile.profileId}</p>
                            <p>
                                <span
                                    className='text-center font-semibold mr-2'
                                >
                                    Type:
                                </span>

                                {profile.accountInfo.type}</p>
                            <p>
                                <span
                                    className='text-center font-semibold mr-2'
                                >
                                    Seller ID:
                                </span>

                                {profile.accountInfo.id}</p>

                            <button
                                className={`bg-blue-500 text-white p-2 rounded-md shadow-md mt-4 hover:bg-blue-600 transition-all duration-100 ${loadingGetCampaigns[profile.profileId] && 'cursor-not-allowed'}`}
                                onClick={() => getCampaigns(profile.profileId, profile.profileId, adAccount.ads_token_data.access_token)}
                                disabled={loadingGetCampaigns[profile.profileId]}
                            >
                                {!loadingGetCampaigns[profile.profileId] ? 'Get Campaigns' : <div className='spinner-white-small' />}
                            </button>

                            <button
                                className={`bg-blue-500 text-white p-2 rounded-md shadow-md mt-4 hover:bg-blue-600 transition-all duration-100 ${loadingGetAdvertisedProductReport[profile.profileId] && 'cursor-not-allowed'}`}
                                onClick={() => getSpAdvertisedProductReport(profile.profileId, profile.profileId, adAccount.ads_token_data.access_token, adAccount.ads_id)}
                                disabled={loadingGetAdvertisedProductReport[profile.profileId]}
                            >
                                {!loadingGetAdvertisedProductReport[profile.profileId] ? 'Get Advertised Product Report' : <div className='spinner-white-small' />}
                            </button>

                            {profile.ads_reports && profile.ads_reports.map((report) => (
                                <div key={report.reportId} className='bg-gray-200 p-4 m-4 rounded-md shadow-md flex flex-col justify-center items-center'>
                                    <p>
                                        <span
                                            className='text-center font-semibold mr-2'
                                        >
                                            Report ID:
                                        </span>

                                        {report.reportId}
                                    </p>
                                    {!report.isDownloaded ?
                                        <button
                                            className={`bg-blue-500 text-white p-2 rounded-md shadow-md mt-4 hover:bg-blue-600 transition-all duration-100 ${loadingDownloadReportSpAdvertisedProductReport[profile.profileId] && 'cursor-not-allowed'}`}
                                            onClick={() => downloadReportSpAdvertisedProductReport(profile.profileId, profile.profileId, adAccount.ads_token_data.access_token, report.reportId, adAccount.ads_id)}
                                            disabled={loadingDownloadReportSpAdvertisedProductReport[profile.profileId]}
                                        >
                                            {loadingDownloadReportSpAdvertisedProductReport[profile.profileId] ? <div className='spinner-white-small' /> : 'Download Report'}
                                            {!loadingDownloadReportSpAdvertisedProductReport[profile.profileId] &&
                                                <Download className='ml-2' />
                                            }
                                        </button>
                                        :
                                        <button
                                            className={`bg-blue-500 text-white p-2 rounded-md shadow-md mt-4 hover:bg-blue-600 transition-all duration-100 ${loadingParseSpAdvertisedProductReport[profile.profileId] && 'cursor-not-allowed'}`}
                                            onClick={() => parseSpAdvertisedProductReport(profile.profileId, profile.profileId, report.reportId, profile.accountInfo.id)}
                                            disabled={loadingParseSpAdvertisedProductReport[profile.profileId]}
                                        >
                                            {loadingParseSpAdvertisedProductReport[profile.profileId] ? <div className='spinner-white-small' /> : 'Parse Report'}
                                            {!loadingParseSpAdvertisedProductReport[profile.profileId] &&
                                                <FindInPage className='ml-2' />
                                            }
                                        </button>

                                    }



                                </div>
                            ))}
                        </div>
                    })}
                </div>
            })}
        </div>
    )
}

