import React from 'react';
import useAuth from '../hooks/useAuth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../helpers/firebase';

export const PaymentOptions = () => {
    const { currentUser } = useAuth();

    const handleStartTrial = async () => {
        console.log('1 Starting free trial...');
        const userRef = doc(db, 'users', currentUser.uid);

        // subscription_type "free-trial" is used to identify users on a free trial
        await updateDoc(userRef, {
            subscription_type: 'free-trial',
        });
        window.location.href = '/tablero';
    };

    const handleSubscribe = () => {
        console.log('2 Subscribing to premium...');
        window.location.href = '/pago';
    };

    return (
        <div className="max-w-7xl min-h-screen mx-auto px-4 sm:px-6 lg:px-8 py-12">
            <div className="text-center">
                <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                    Elige tu plan
                </h2>
                <p className="mt-4 text-xl text-gray-600">
                    Comienza con una prueba gratuita o suscríbete a nuestro plan premium
                </p>
            </div>

            <div className="mt-12 grid gap-8 lg:grid-cols-2">
                {/* Free Trial Option */}
                <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                    <div className="px-6 py-8">
                        <h3 className="text-2xl font-bold text-gray-900">Prueba Gratuita</h3>
                        <p className="mt-4 text-gray-600">
                            15 días de acceso completo a todas las funciones premium
                        </p>
                        <div className="mt-8">
                            <button
                                onClick={handleStartTrial}
                                className="w-full bg-indigo-600 text-white px-6 py-3 rounded-md hover:bg-indigo-700"
                            >
                                Comenzar prueba gratuita
                            </button>
                        </div>
                    </div>
                </div>

                {/* Premium Option */}
                <div className="bg-white shadow-lg rounded-lg overflow-hidden border-2 border-indigo-500">
                    <div className="px-6 py-8">
                        <h3 className="text-2xl font-bold text-gray-900">Plan Premium</h3>
                        <p className="mt-4 text-gray-600">
                            Acceso ilimitado a todas las funciones avanzadas
                        </p>
                        <ul className="mt-6 space-y-4">
                            <li className="flex items-center">
                                <span className="text-green-500">✓</span>
                                <span className="ml-3">Análisis de tiendas ML y Amazon</span>
                            </li>
                            <li className="flex items-center">
                                <span className="text-green-500">✓</span>
                                <span className="ml-3">Herramientas de comparación</span>
                            </li>
                            <li className="flex items-center">
                                <span className="text-green-500">✓</span>
                                <span className="ml-3">Métricas temporales y por producto</span>
                            </li>
                            <li className="flex items-center">
                                <span className="text-green-500">✓</span>
                                <span className="ml-3">Análisis de relaciones entre marketplaces</span>
                            </li>
                            <li className="flex items-center">
                                <span className="text-green-500">✓</span>
                                <span className="ml-3">Análisis de métricas publicitarias</span>
                            </li>
                        </ul>
                        <div className="mt-8">
                            <button
                                onClick={handleSubscribe}
                                className="w-full bg-indigo-600 text-white px-6 py-3 rounded-md hover:bg-indigo-700"
                            >
                                Suscribirse ahora
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};