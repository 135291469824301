import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Menu, MenuItem } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { signOut } from 'firebase/auth';
import '../helpers/ripple.css';
import Select, { components } from 'react-select';
import logoMeLi from '../assets/ml_trans.png';
import logoAmazon from '../assets/az_trans.png';
import { auth, db } from '../helpers/firebase';
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { Close } from '@mui/icons-material';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import newLogoKompaMxAnalytics from '../assets/LogoKompaMxAzul.png';
import MenuIcon from '@mui/icons-material/Menu';
import { useTopbar } from '../helpers/TopbarContext';
import TimeFilter from './timeFilter/TimeFilter';
import Swal from 'sweetalert2';
import useAuth from '../hooks/useAuth';
import mx from '../assets/merchantspring/mx.svg';

function TopBar({ hideTopBar, store, setStore }) {
    const [username, setUsername] = useState('');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [generatingPdf, setGeneratingPdf] = useState(false);
    const [loading, setLoading] = useState(true);
    const [storeOptions, setStoreOptions] = useState([]);
    const [period, setPeriod] = useState({ value: 'last-12-months', label: 'Últimos 12 meses' });
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
    const [selectorHovered, setSelectorHovered] = useState(false);

    const isProfileMenuOpen = Boolean(profileMenuAnchorEl)

    const location = useLocation();

    const showStoreSelect = location.pathname.startsWith('/mis-tiendas');
    const showRelationSelect = location.pathname.startsWith('/comparador');

    const { setShouldSidebarBeOpen, shouldSidebarBeOpen, relation, setRelation, relations, setRelations } = useTopbar();

    const { Option, SingleValue, Control } = components;

    const { currentUser } = useAuth();

    useEffect(() => {
        const uid = currentUser?.uid;
        if (!uid) return;

        const fetchStores = async () => {
            setLoading(true);

            const storesSnapshot = await getDocs(collection(db, `users/${uid}/stores`));
            const stores = storesSnapshot.docs.map(doc => ({
                id: doc.id,
                value: doc.id,
                label: doc.data().custom_name || doc.id,
                data: doc.data(),
                icon: doc.id.startsWith('ml') ? logoMeLi : doc.id.startsWith('az') ? logoAmazon : undefined
            }));

            setStoreOptions(stores);
            setStore(stores.length > 0 ? stores[0] : null);

            setLoading(false);
        };

        fetchStores();
    }, [currentUser]);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            if (screenWidth > 768) {
                setShouldSidebarBeOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        try { // seteamos username al correo quitando lo que está después del @
            if (currentUser) setUsername(currentUser.email.split('@')[0]);
        } catch (error) {
            console.error(error);
        }
    }, [currentUser]);

    const generatePDF = async () => {
        setGeneratingPdf(true);
        try {
            const input = document.getElementById('content-to-pdf');
            const canvas = await html2canvas(input);
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: "landscape",
                unit: "px",
                format: [canvas.width, canvas.height]
            });

            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();
            const canvasRatio = canvas.width / canvas.height;
            const pdfRatio = pdfWidth / pdfHeight;

            let finalWidth, finalHeight;
            let xOffset = 0, yOffset = 0;

            if (pdfRatio > canvasRatio) {
                // El PDF es relativamente más ancho que el canvas
                finalHeight = pdfHeight;
                finalWidth = canvas.width * (pdfHeight / canvas.height);
                xOffset = (pdfWidth - finalWidth) / 2; // Centrar horizontalmente
            } else {
                // El PDF es relativamente más alto que el canvas
                finalWidth = pdfWidth;
                finalHeight = canvas.height * (pdfWidth / canvas.width);
                yOffset = (pdfHeight - finalHeight) / 2; // Centrar verticalmente
            }

            // Establecer el color del fondo a gris y dibujar un rectángulo que cubra toda la página
            pdf.setFillColor(200);
            pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');

            // Agregar la imagen del canvas ajustada y centrada
            pdf.addImage(imgData, 'PNG', xOffset, yOffset, finalWidth, finalHeight);

            const date = new Date();
            const formattedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

            pdf.save(`Reporte_${formattedDate}.pdf`);
        } catch (error) {
            console.error('Error al generar el PDF:', error);
        } finally {
            setGeneratingPdf(false);
        }
    };

    const handleSignOut = () => {
        signOut(auth)
    };

    const handleProfileMenuOpen = (event) => {
        setProfileMenuAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setProfileMenuAnchorEl(null);
    };

    const CustomControl = (props) => {
        return (
            <Control {...props}>
                <ArrowDropDownIcon style={{ position: 'absolute', left: 10, top: '50%', transform: 'translateY(-50%)', color: 'white' }} />
                {props.children}
            </Control>
        );
    };

    const NoOptionsMessage = props => {
        return (
            <components.NoOptionsMessage {...props}>
                <span className="text-sm font-semibold text-gray-500">
                    No hay tiendas disponibles
                </span>
            </components.NoOptionsMessage>
        );
    };

    const IconOption = (props) => {
        if (!props.data.icon) {
            return <Option {...props} >
                <div className='flex flex-row items-center justify-between'>
                    {props.data.label}
                    {!props.data.label !== '+ Crear nueva relación' &&
                        <span
                            className='text-red-500 font-semibold ml-2 opacity-0 hover:opacity-100 transition-all duration-300 cursor-pointer'
                            onClick={async (e) => {
                                e.stopPropagation();
                                // Preguntar al usuario si está seguro de desconectar la tienda
                                const result = await Swal.fire({
                                    title: '¿Estás seguro de que deseas eliminar esta relación?',
                                    text: "No podrás revertir esta acción.",
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#aaa',
                                    confirmButtonText: 'Sí, desconectar',
                                    cancelButtonText: 'Cancelar'
                                });

                                if (result.isConfirmed) {
                                    if (!currentUser) return;
                                    await deleteDoc(doc(db, `users/${currentUser.uid}/relatedStores`, props.data.id));
                                    // remove from state
                                    setRelations(relations.filter(r => r.id !== props.data.id));
                                    if (relations.length > 0) {
                                        setRelation(relations[0]);
                                    }
                                }
                            }}
                        >
                            <Close />
                        </span>
                    }
                </div>
            </Option>
                ;
        }

        return (
            <Option {...props}>
                <div className='flex flex-row items-center'>
                    {screenWidth > 968 &&
                        <>
                            <img
                                src={props.data.icon}
                                style={{ width: 20, height: 20, marginRight: 5 }}
                                alt={props.data.label}
                            />
                            <img
                                src={mx}
                                style={{ width: 20, height: 20, marginRight: 10 }}
                                alt={props.data.label}
                            />
                        </>
                    }
                    {props.data.label}
                </div>
            </Option>
        );
    };

    const CustomSingleValue = ({ children, ...props }) => {
        const [screenWidth, setScreenWidth] = useState(window.innerWidth);


        useEffect(() => {
            const handleResize = () => {
                setScreenWidth(window.innerWidth);
            };

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize
            );
        }, []);

        if (!props.data.icon) {
            return <SingleValue {...props}>
                <div className={`flex flex-row items-center ${selectorHovered ? 'text-gray-400' : 'text-gray-500'}`}>
                    {children}
                </div>
            </SingleValue>;
        }
        return (
            <SingleValue {...props}>
                <div className={`flex flex-row items-center ${selectorHovered ? 'text-gray-400' : 'text-gray-500'}`}>
                    {screenWidth > 968 &&
                        <>
                            <img
                                src={props.data.icon}
                                style={{ width: 20, height: 20, marginRight: 5 }}
                                alt={props.data.label}
                            />
                            <img
                                src={mx}
                                style={{ width: 20, height: 20, marginRight: 10 }}
                                alt={props.data.label}
                            />
                        </>
                    }
                    {children}
                </div>
            </SingleValue>
        );
    };

    const CustomDropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDropDownIcon
                    className={`absolute top-0 right-1.5 transition-all duration-300 ${selectorHovered ? 'text-gray-400' : 'text-gray-500'}`}
                    fontSize='large'
                />
            </components.DropdownIndicator>
        );
    };

    const navigate = useNavigate();

    return (
        <div className={`flex flex-row ${screenWidth > 768 ? "justify-between pl-10" : "justify-end pl-2"} items-center text-white text-sm font-semibold ${hideTopBar ? 'hidden' : ''}`} style={{ backgroundColor: 'white', borderBottom: '1px solid #e2e8f0' }}>
            {screenWidth < 768 && storeOptions.length > 0 &&
                <MenuIcon
                    className='w-8 h-8 mr-auto cursor-pointer text-gray-500 hover:text-gray-400 transition-all duration-300'
                    fontSize='large'
                    onClick={() => {
                        setShouldSidebarBeOpen(!shouldSidebarBeOpen);
                    }}
                />
            }

            {screenWidth > 768 &&
                storeOptions.length > 0 ?
                <div className='w-full justify-start items-start text-left mt-1 select-none'>
                    <p className='text-xs text-500' style={{ color: '#B4B4B4', marginBottom: '-5px' }}>
                        {location.pathname.startsWith('/mis-tiendas') ? 'Mis Tiendas' :
                            location.pathname.startsWith('/comparador') ? 'Comparador' :
                                'Inicio'
                        }
                    </p>
                    <p className='text-lg font-bold text-kompamxblue whitespace-nowrap mr-3'>
                        {location.pathname === '/mis-tiendas/graficos' ? 'Gráficos y Análisis' :
                            location.pathname === '/mis-tiendas/tabla-de-productos' ? 'Tabla de Productos' :
                                location.pathname === '/mis-tiendas/metricas-de-reputacion' ? 'Métricas de Reputación' :
                                    location.pathname === '/comparador/graficos' ? 'Gráficos y Análisis' :
                                        location.pathname === '/comparador/comparativa-de-productos' ? 'Comparativa de Productos' :
                                            location.pathname === '/comparador/graficos-por-producto' ? 'Gráficos por Producto' :
                                                'Tablero'}
                    </p>
                </div> : storeOptions.length < 1 &&
                <img
                    src={newLogoKompaMxAnalytics}
                    alt="KompaMx Analytics"
                    style={{ width: '50px' }}
                    className="ml-3 mr-auto mt-1 cursor-pointer"
                />
            }

            <div
                className={`flex items-center justify-center ${screenWidth > 768 ? 'flex-row' : 'flex-col py-2 space-y-2'}`}
                onMouseEnter={() => { setSelectorHovered(true) }}
                onMouseLeave={() => { setSelectorHovered(false) }}
            >
                {showStoreSelect && (
                    <>
                        <Select
                            styles={{
                                control: (styles) => ({
                                    ...styles,
                                    width: screenWidth < 968 ? '10rem' : "18rem",
                                    fontWeight: '600',
                                    backgroundColor: 'transparent',
                                    border: '2px solid #6b7280 !important',
                                    borderRadius: '0.5rem',
                                    boxShadow: 'none',
                                    cursor: 'pointer',
                                    paddingLeft: 5,
                                    '&:hover': {
                                        borderColor: '#9ca3af !important', // Un gris más oscuro para el hover
                                    },
                                }),
                                singleValue: (styles) => ({
                                    ...styles,
                                    color: '#B4B4B4'
                                }),
                                option: (styles, state) => ({
                                    ...styles,
                                    color: 'black',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5',
                                    },
                                    backgroundColor: state.isSelected ? '#e3e1e1' : 'white',
                                }),
                            }}
                            options={storeOptions}
                            onChange={setStore}
                            value={store}
                            placeholder="Selecciona una tienda"
                            className='mr-5'
                            components={{
                                NoOptionsMessage,
                                Option: IconOption,
                                SingleValue: CustomSingleValue,
                                Control: CustomControl,
                                DropdownIndicator: CustomDropdownIndicator,
                                IndicatorSeparator: null
                            }}
                            isSearchable={false}
                        />
                    </>
                )}
                {showRelationSelect && relations.length > 1 &&
                    (
                        <Select
                            styles={{
                                control: (styles) => ({
                                    ...styles,
                                    width: screenWidth < 968 ? '10rem' : "18rem",
                                    fontWeight: '600',
                                    backgroundColor: 'transparent',
                                    border: '2px solid #6b7280!important',
                                    borderRadius: '0.5rem',
                                    boxShadow: 'none',
                                    cursor: 'pointer',
                                    paddingLeft: 5,
                                    '&:hover': {
                                        borderColor: '#9ca3af !important', // Un gris más oscuro para el hover
                                    },
                                }),
                                singleValue: (styles) => ({
                                    ...styles,
                                    color: '#B4B4B4'
                                }),
                                option: (styles, state) => ({
                                    ...styles,
                                    color: 'black',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5',
                                    },
                                    backgroundColor: state.isSelected ? '#e3e1e1' : 'white',
                                }),
                            }}
                            options={relations}
                            onChange={(selectedOption) => {
                                if (selectedOption.value === 'create_new') {
                                    navigate('/relacionar-tiendas');
                                } else {
                                    setRelation(selectedOption);
                                }
                            }}
                            value={relation}
                            placeholder="Selecciona una relación"
                            className='mr-5'
                            components={{
                                NoOptionsMessage,
                                Option: IconOption,
                                SingleValue: CustomSingleValue,
                                Control: CustomControl,
                                DropdownIndicator: CustomDropdownIndicator,
                                IndicatorSeparator: null
                            }}
                            isSearchable={false}
                        />
                    )}
            </div>

            {storeOptions.length > 0 &&
                <TimeFilter />
            }

            <nav className="flex justify-end items-center pl-3 xl:pl-6 h-16">
                <div className="flex items-center h-full">
                    {!(location.pathname.startsWith('/mis-tiendas') && screenWidth < 768) &&
                        <button onClick={handleProfileMenuOpen} className="no-underline mr-5 hover:underline h-full text-gray-500 hover:text-gray-400">
                            <div className="flex items-center">
                                <p className='no-underline bg-kompamxblue text-white rounded-full h-8 w-8 flex items-center justify-center'>
                                    {username &&
                                        username[0].toUpperCase()
                                    }
                                </p>
                                <ArrowDropDownIcon />
                            </div>
                        </button>
                    }

                    <Menu
                        anchorEl={profileMenuAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={isProfileMenuOpen}
                        onClose={handleProfileMenuClose}
                    >
                        <MenuItem onClick={handleSignOut} style={{ padding: '1.0rem 1.0rem' }}>
                            <ExitToAppIcon className='mr-2' />
                            <span className='text-sm font-semibold'>
                                Cerrar sesión
                            </span>
                        </MenuItem>
                    </Menu>

                </div>
            </nav>
        </div>
    );
}

export default TopBar;
