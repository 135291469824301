import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../helpers/firebase';
import useAuth from './useAuth';

function useStores() {
    const [stores, setStores] = useState([]);
    const [loadingStores, setLoadingStores] = useState(true);
    
    const { currentUser } = useAuth();

    const getStores = async () => {
        setLoadingStores(true);
        try {
            const storesCollection = collection(db, "users", currentUser.uid, "stores");
            const storesSnapshot = await getDocs(storesCollection);
            const storesList = storesSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setStores(storesList);
        } catch (error) {
            console.error('Error fetching stores: ', error);
        } finally {
            setLoadingStores(false);
        }
    }

    useEffect(() => {
        if (currentUser) {
            getStores();
        } else {
            setStores([]);
            setLoadingStores(false);
        }
    }, [currentUser]);

    return { stores, loadingStores, setStores };
}

export default useStores;