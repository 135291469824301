import React, { useEffect, useState } from 'react'
import { auth, db } from '../../helpers/firebase';
import { collection, getDocs } from 'firebase/firestore';
import Select from 'react-select';
import Tooltip from '@mui/material/Tooltip';
import { InfoOutlined } from '@mui/icons-material';
import useAuth from '../../hooks/useAuth';

// Establece un mapeo entre el level_id y el color correspondiente
const levelToColor = {
    '1_red': '#F23D4F',
    '2_orange': '#FFB657',
    '3_yellow': '#FEF211',
    '4_light_green': '#AEEF1B',
    '5_green': '#00A650',
};

// Mapeo inverso para obtener el nombre del color basado en el valor hexadecimal
const colorToName = {
    '#F23D4F': 'rojo',
    '#FFB657': 'naranja',
    '#FEF211': 'amarillo',
    '#AEEF1B': 'verde claro',
    '#00A650': 'verde'
};

const SellerReputation = ({ levelId }) => {
    const activeColor = levelToColor[levelId];

    return (
        <div className="flex flex-col">
            {activeColor ?
                <div className="text-lg text-center font-medium text-kompamxblue mb-2">Tienes color {colorToName[activeColor]}</div> :
                <div className="text-lg text-center font-medium text-kompamxblue mb-2">No tienes color asignado</div>
            }
            <div className="flex flex-row items-center mb-4">
                {Object.values(levelToColor).map(color => (
                    <div
                        key={color}
                        style={{
                            width: '55px',
                            height: color === activeColor ? '10px' : '8px',
                            backgroundColor: color,
                            opacity: color === activeColor ? 1 : 0.2,
                            marginLeft: '2px' // Añade un pequeño espacio entre los rectángulos si es necesario
                        }}
                    />
                ))}
            </div>

        </div>
    );
};

export default function MeLiMetrics({ store }) {
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState(store);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (store) setSelectedStore(store);
    }, [store]);

    const [chartWidth, setChartWidth] = useState(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth) * 0.5) - 50);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setChartWidth((window.innerWidth - 15));
                return;
            }
            const newChartWidth = (window.innerWidth * 0.5) - 50;
            setChartWidth(newChartWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const { currentUser } = useAuth();

    useEffect(() => {
        const getUserStores = async () => {
            if (!currentUser) {
                return;
            }
            const storesRef = collection(db, 'users', currentUser.uid, 'stores');
            const storesSnapshot = await getDocs(storesRef);

            const meliStores = storesSnapshot.docs.filter(doc => doc.id.startsWith('ml'));

            const storesList = meliStores.map(doc => {
                const store = doc.data();
                return {
                    id: doc.id,
                    value: store.custom_name,
                    data: store,
                    label: <div
                        className='flex flex-row items-center'
                    >
                        <img
                            // si empieza con ml usamos el logo de mercado libre
                            src={
                                doc.id.startsWith('ml') ?
                                    `https://http2.mlstatic.com/frontend-assets/ui-navigation/5.18.5/mercadolibre/logo__large_plus.png` :
                                    doc.id.startsWith('az') &&
                                    `https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Amazon.com-Logo.svg/1200px-Amazon.com-Logo.svg.png`
                            }
                            className='w-16 mr-4 object-contain'
                            alt="" />
                        {store.custom_name}
                    </div>
                }
            }
            );
            setStores(storesList);
            if (storesList.length > 0 && !store) setSelectedStore(storesList[0]);
            setLoading(false);
        };

        getUserStores();
    }, [currentUser, store]);

    return (
        <div className='flex flex-col items-start justify-start bg-white rounded-lg shadow-md m-1' style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
            <div className="flex flex-col md:flex-row items-center px-5 text-kompamxblue text-base justify-center md:justify-between w-full" style={{ minHeight: "3.5rem" }}>
                <div className="flex items-center justify-start w-full">
                    <div className="flex flex-row items-center justify-start w-full ">
                        <div className="font-semibold mr-2 lg:whitespace-nowrap">
                            Métricas de Reputación (MercadoLibre)
                        </div>
                        <Tooltip title={'Selecciona una tienda para ver sus métricas de reputación'}>
                            <InfoOutlined style={{ fontSize: 18 }} className='ml-auto mr-2' />
                        </Tooltip>
                    </div>
                    {!store &&
                        <div className=''>
                            <Select
                                options={stores}
                                className='whitespace-nowrap'
                                placeholder='Selecciona una tienda'
                                value={selectedStore}
                                onChange={setSelectedStore}
                            />
                        </div>
                    }
                </div>
            </div>
            <hr className="w-full border-gray-300 mb-4" />
            {loading ?
                <div className='h-full w-full flex justify-center items-center'><div className="spinner" /></div> :
                <>
                    {(!selectedStore || !selectedStore.id.startsWith('ml_')) ?
                        <div className="flex justify-center items-center h-full w-full">
                            <p className="text-gray-500 text-lg">No hay datos para mostrar</p>
                        </div> :
                        <div className="flex flex-col items-center justify-center w-full h-full">
                            <SellerReputation levelId={selectedStore.data.seller_data.seller_reputation.level_id} />
                            <div className="text-kompamxblue text-base font-normal mb-2">
                                {selectedStore.data.seller_data.seller_reputation.transactions.ratings.negative} calificaciones negativas
                            </div>
                            <div className="text-kompamxblue text-base font-normal mb-2">
                                {selectedStore.data.seller_data.seller_reputation.transactions.ratings.positive} calificaciones positivas
                            </div>
                            <div className="text-kompamxblue text-base font-normal mb-2">
                                {selectedStore.data.seller_data.seller_reputation.transactions.completed} ventas completadas
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    )
}
