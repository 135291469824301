import { LlamadaALaAccion } from '../componentes/LlamadaALaAccion'
import { PieDePagina } from '../componentes/PieDePagina'
import { Cabecera } from '../componentes/Cabecera'
import { Heroe } from '../componentes/Heroe'
import { Precios } from '../componentes/Precios'
import { CaracteristicasPrincipales } from '../componentes/CaracteristicasPrincipales'
import { CaracteristicasSecundarias } from '../componentes/CaracteristicasSecundarias'
import { Testimonios } from '../componentes/Testimonios'
import { PreguntasFrecuentes } from '../componentes/PreguntasFrecuentes'

export default function OnBoarding() {
  return (
    <div className="bg-white">
      <Cabecera />
      <main>
        <Heroe />
        <CaracteristicasPrincipales />
        <CaracteristicasSecundarias />
        <LlamadaALaAccion />
        <Testimonios />
        <Precios />
        <PreguntasFrecuentes />
      </main>
      <PieDePagina />
    </div >
  )
}
