import React from 'react';
import { Menu, MenuItem, Checkbox, Divider } from '@mui/material';

function ColumnToggleMenu({ columnsMenuAnchorEl, handleCloseChooseColumns, visibleColumns, columnNames, handleAllColumnsToggle, handleColumnToggle }) {
    return (
        <Menu
            anchorEl={columnsMenuAnchorEl}
            open={Boolean(columnsMenuAnchorEl)}
            onClose={handleCloseChooseColumns}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            getAnchorEl={null}
        >
            <p className="text-black mx-4 my-2">
                Mostrar/Ocultar columnas
            </p>

            <Divider />

            <MenuItem onClick={handleAllColumnsToggle}>
                <Checkbox checked={Object.values(visibleColumns).every((column) => column)} />
                Todas las columnas
            </MenuItem>

            {Object.entries(visibleColumns).map(([column, isVisible]) => (
                <MenuItem key={column} onClick={() => handleColumnToggle(column)}>
                    <Checkbox checked={isVisible} />
                    {columnNames[column]}
                </MenuItem>
            ))}
        </Menu>
    );
}

export default ColumnToggleMenu;