import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth, db } from './firebase';
import { collection, getDocs } from 'firebase/firestore';
import Swal from 'sweetalert2';
import { startOfDay, endOfDay, subHours, subDays, startOfWeek, endOfWeek, subWeeks, subMonths, startOfMonth, endOfMonth, startOfQuarter, endOfQuarter, subQuarters, subYears, startOfYear } from 'date-fns';
import moment from 'moment-timezone';

// Crear el contexto
const TopbarContext = createContext();

// Crear un Hook personalizado para acceder al contexto fácilmente
export const useTopbar = () => useContext(TopbarContext);

// Proveedor del contexto
export const TopbarProvider = ({ children }) => {
    const [shouldSidebarBeOpen, setShouldSidebarBeOpen] = useState(false);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [comparisonStartDate, setComparisonStartDate] = useState(new Date());
    const [comparisonEndDate, setComparisonEndDate] = useState(new Date());
    const [relations, setRelations] = useState([]);
    const [relation, setRelation] = useState(null);
    const [loadingRelations, setLoadingRelations] = useState(false);
    // Obtener todas las zonas horarias
    const timezones = moment.tz.names().map(tz => ({ value: tz, label: tz.replace(/_/g, ' ').replace(/\//g, ' - ') }));

    const [selectedTimezone, setSelectedTimezone] = useState(timezones.find(tz => tz.value === moment.tz.guess()));

    const chartIntervalOptions = [
        { value: 'DIA', label: 'DÍA' },
        { value: 'SEMANA', label: 'SEMANA' },
        { value: 'MES', label: 'MES' },
        { value: 'ANO', label: 'AÑO' },
    ];

    const comparisonPeriodOptions = [
        { value: 'PERIODO_ANTERIOR', label: 'PERIODO ANTERIOR' },
        { value: 'ANO_PASADO', label: 'AÑO PASADO' },
    ];

    const datePeriodOptions = [
        { title: 'ÚLTIMAS 24 HORAS', value: 'last-24-hours' },
        { title: 'AYER', value: 'yesterday' },
        { title: 'SEMANA PASADA', value: 'last-week' },
        { title: 'ULTIMA SEMANA (L-D)', value: 'last-week-sun-sat' },
        { title: 'ULTIMOS 30 DÍAS', value: 'last-30-days' },
        { title: 'MES A LA FECHA', value: 'month-to-date' },
        { title: 'MES ACTUAL', value: 'current-month' },
        { title: 'MES ANTERIOR', value: 'last-month' },
        { title: 'TRIMESTRE ACTUAL', value: 'current-quarter' },
        { title: 'ÚLTIMO TRIMESTRE', value: 'last-quarter' },
        { title: 'ÚLTIMOS 12 MESES', value: 'last-12-months' },
        { title: 'AÑO HASTA LA FECHA', value: 'year-to-date' },
        { title: 'AÑO ACTUAL', value: 'current-year' },
        { title: 'PERSONALIZADO', value: 'custom' },
    ];



    const [selectedDatePeriod, setSelectedDatePeriod] = useState(datePeriodOptions[0]);
    const [selectedChartInterval, setSelectedChartInterval] = useState(chartIntervalOptions[0]);
    const [selectedComparisonPeriod, setSelectedComparisonPeriod] = useState(comparisonPeriodOptions[0]);
    const [showCustom, setShowCustom] = useState(false);

    useEffect(() => {
        if (showCustom) {
            setNewDatePeriod(false, { title: 'PERSONALIZADO', value: 'custom' }, true);
        }
    }, [showCustom]);

    useEffect(() => {
        setNewDatePeriod(true, selectedDatePeriod, showCustom);
    }, [selectedComparisonPeriod]);

    useEffect(() => {
        const uid = auth.currentUser?.uid;
        if (!uid) return;

        const fetchRelations = async () => {
            setLoadingRelations(true);

            const relationsSnapshot = await getDocs(collection(db, `users/${uid}/relatedStores`));
            const relations = relationsSnapshot.docs.map(doc => ({
                id: doc.id,
                value: doc.id,
                label: doc.data().name || doc.id,
                data: doc.data(),
            }));

            // Agrega la opción "+ Crear nueva relación" siempre que cargues las relaciones
            const extendedRelations = [
                ...relations,
                { id: 'create_new', value: 'create_new', label: <span className='text-kompamxblue font-semibold'>+ Crear nueva relación</span> }
            ];

            setRelations(extendedRelations);
            setRelation(extendedRelations.length > 0 ? extendedRelations[0] : null);
            setLoadingRelations(false);
        };

        fetchRelations();
    }, []);

    const validateDateRange = (start, end, interval) => {
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        switch (interval) {
            case 'SEMANA':
                return diffDays >= 7;
            case 'MES':
                return diffDays >= 30;
            case 'ANO':
                return diffDays >= 365;
            default:
                return true;
        }
    };

    const handleDateChange = (newDate, setDateFunction, isEndDate = false) => {
        if (validateDateRange(startDate, endDate, selectedChartInterval.value) &&
            validateDateRange(comparisonStartDate, comparisonEndDate, selectedChartInterval.value)) {
            setDateFunction(newDate);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Rango de fechas no válido',
                text: `El rango de fechas debe ser de al menos ${selectedChartInterval.value === 'SEMANA' ? '7 días' :
                    selectedChartInterval.value === 'MES' ? '30 días' :
                        selectedChartInterval.value === 'ANO' ? '365 días' : '1 día'
                    } para el intervalo seleccionado.`,
            });
        }
    };

    const handleChartIntervalChange = (newInterval) => {
        if (validateDateRange(startDate, endDate, newInterval.value) &&
            validateDateRange(comparisonStartDate, comparisonEndDate, newInterval.value)) {
            setSelectedChartInterval(newInterval);
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Intervalo no válido',
                text: `No se puede seleccionar este intervalo. Asegúrate de que ambos períodos tengan al menos ${newInterval.value === 'SEMANA' ? '7 días' :
                    newInterval.value === 'MES' ? '30 días' :
                        newInterval.value === 'ANO' ? '365 días' : '1 día'
                    }.`,
            });
        }
    };


    const setNewDatePeriod = (shouldChangeComparisonPeriod = true, newSelectedDatePeriod = null, showCustom = false) => {
        let selectedDatePeriodVar = newSelectedDatePeriod || selectedDatePeriod;
        if (!selectedDatePeriodVar) {
            selectedDatePeriodVar = datePeriodOptions[0];
        }
        const now = new Date();

        setShowCustom(showCustom);
        let newStartDate = startDate;
        let newEndDate = endDate;

        switch (selectedDatePeriodVar.value) {
            case 'last-24-hours':
                setStartDate(subHours(now, 24));
                setEndDate(now);
                newStartDate = subHours(now, 24);
                newEndDate = now;
                break;
            case 'yesterday':
                setStartDate(startOfDay(subDays(now, 1)));
                setEndDate(endOfDay(subDays(now, 1)));
                newStartDate = startOfDay(subDays(now, 1));
                newEndDate = endOfDay(subDays(now, 1));
                break;
            case 'last-week':
                setStartDate(startOfDay(subWeeks(now, 1)));
                setEndDate(endOfDay(subDays(now, 1)));
                newStartDate = startOfDay(subWeeks(now, 1));
                newEndDate = endOfDay(subDays(now, 1));
                break;
            case 'last-week-sun-sat':
                setStartDate(startOfWeek(subWeeks(now, 1), { weekStartsOn: 0 }));
                setEndDate(endOfWeek(subWeeks(now, 1), { weekStartsOn: 0 }));
                newStartDate = startOfWeek(subWeeks(now, 1), { weekStartsOn: 0 });
                newEndDate = endOfWeek(subWeeks(now, 1), { weekStartsOn: 0 });
                break;
            case 'last-30-days':
                setStartDate(subDays(now, 30));
                setEndDate(now);
                newStartDate = subDays(now, 30);
                newEndDate = now;
                break;
            case 'month-to-date':
                setStartDate(startOfMonth(now));
                setEndDate(now);
                newStartDate = startOfMonth(now);
                newEndDate = now;
                break;
            case 'current-month':
                setStartDate(startOfMonth(now));
                setEndDate(endOfMonth(now));
                newStartDate = startOfMonth(now);
                newEndDate = endOfMonth(now);
                break;
            case 'last-month':
                setStartDate(startOfMonth(subMonths(now, 1)));
                setEndDate(endOfMonth(subMonths(now, 1)));
                newStartDate = startOfMonth(subMonths(now, 1));
                newEndDate = endOfMonth(subMonths(now, 1));
                break;
            case 'current-quarter':
                setStartDate(startOfQuarter(now));
                setEndDate(endOfQuarter(now));
                newStartDate = startOfQuarter(now);
                newEndDate = endOfQuarter(now);
                break;
            case 'last-quarter':
                setStartDate(startOfQuarter(subQuarters(now, 1)));
                setEndDate(endOfQuarter(subQuarters(now, 1)));
                newStartDate = startOfQuarter(subQuarters(now, 1));
                newEndDate = endOfQuarter(subQuarters(now, 1));
                break;
            case 'last-12-months':
                setStartDate(subYears(now, 1));
                setEndDate(now);
                newStartDate = subYears(now, 1);
                newEndDate = now;
                break;
            case 'year-to-date':
                setStartDate(startOfYear(now));
                setEndDate(now);
                newStartDate = startOfYear(now);
                newEndDate = now;
                break;
            case 'current-year':
                setStartDate(startOfYear(now));
                setEndDate(endOfDay(now));
                newStartDate = startOfYear(now);
                newEndDate = endOfDay(now);
                break;
            case 'custom':
                // No hacemos nada aquí, ya que el usuario seleccionará las fechas manualmente
                break;
            default:
                console.error('Período de fecha no reconocido');
        }
        if (shouldChangeComparisonPeriod) {
            const timeInCurrentPeriod = newEndDate.getTime() - newStartDate.getTime();

            // get days rounding u´
            const days = Math.round(timeInCurrentPeriod / (1000 * 60 * 60 * 24));

            let subExtraDays = 0;

            switch (selectedComparisonPeriod.value) {
                case 'PERIODO_ANTERIOR':
                    if (selectedDatePeriodVar.value === 'month-to-date') subExtraDays = 1;
                    if (selectedDatePeriodVar.value === 'year-to-date') subExtraDays = 1;

                    // Restamos a ambas fechas el tiempo que dura el período actual
                    setComparisonStartDate(subDays(newStartDate, days + subExtraDays));
                    setComparisonEndDate(subDays(newEndDate, days + subExtraDays));
                    break;
                case 'ANO_PASADO':
                    setComparisonStartDate(subYears(newStartDate, 1));
                    setComparisonEndDate(subYears(newEndDate, 1));
                    break;
                default:
                    console.error('Período de comparación no reconocido');
            }
        }
        if (newSelectedDatePeriod) {
            setSelectedDatePeriod(newSelectedDatePeriod);
        }
    }

    useEffect(() => {
        setNewDatePeriod(true, {
            "title": "ÚLTIMOS 30 DÍAS",
            "value": "last-30-days"
        }, false);
    }, []);

    return (
        <TopbarContext.Provider value={{
            shouldSidebarBeOpen,
            setShouldSidebarBeOpen,
            startDate,
            setStartDate,
            endDate,
            setEndDate,
            comparisonStartDate,
            setComparisonStartDate,
            comparisonEndDate,
            setComparisonEndDate,
            relations,
            setRelations,
            relation,
            setRelation,
            loadingRelations,
            selectedChartInterval,
            setSelectedChartInterval,
            chartIntervalOptions,
            handleDateChange,
            handleChartIntervalChange,
            comparisonPeriodOptions,
            selectedComparisonPeriod,
            setSelectedComparisonPeriod,
            selectedDatePeriod,
            setSelectedDatePeriod,
            datePeriodOptions,
            setNewDatePeriod,
            showCustom,
            setShowCustom,
            timezones,
            selectedTimezone,
            setSelectedTimezone,
        }}>
            {children}
        </TopbarContext.Provider>
    );
};
