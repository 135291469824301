import { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy, limit, doc, setDoc, documentId, startAfter } from 'firebase/firestore';
import { db } from '../helpers/firebase';
import useAuth from './useAuth';

function useListings({ store = {}, searchText = '', sortState = { column: null, direction: 'desc' }, currentPage = 0, setCurrentPage, setLoading, anchorDocs, setAnchorDocs, ecomType }) {
    const [storeListings, setStoreListings] = useState([]);
    const listingsPerPage = 10; // Ajusta según tus necesidades

    // Estado para manejar el total de listados
    const [totalListings, setTotalListings] = useState(0);

    const { currentUser } = useAuth();

    useEffect(() => {
        if (!store) {
            return;
        }

        if (store.data.total_productos) {
            setTotalListings(store.data.total_productos);
            return;
        }

        const fetchTotalListings = async () => {
            if (!store) return;
            if (!currentUser) return;

            const storeRef = collection(db, 'users', currentUser.uid, 'stores', store.id, 'listings');
            const querySnapshot = await getDocs(storeRef);
            // setDoc store Doc with merge True with field total_productos
            const totalListings = querySnapshot.size;
            const storeDocRef = doc(db, 'users', currentUser.uid, 'stores', store.id);
            await setDoc(storeDocRef, { total_productos: totalListings }, { merge: true });
            setTotalListings(querySnapshot.size); // Actualiza el estado con el total de documentos
        };

        fetchTotalListings();
    }, [store]); // Este efecto depende del store, se ejecutará cada vez que el store cambie

    const totalPages = Math.ceil(totalListings / listingsPerPage);

    const filteredData = storeListings.filter((data) =>
        data.producto.toLowerCase().includes(searchText.toLowerCase()) ||
        data.asin.toLowerCase().includes(searchText.toLowerCase())
    );


    useEffect(() => {
        if (sortState.column !== null) {
            const sorted = [...filteredData].sort((a, b) => {
                const field = fieldMappings[ecomType][sortState.column] || sortState.column;

                let valueA = a[field];
                let valueB = b[field];

                // Manejo especial para campos anidados (como 'orderedProductSales.amount')
                if (field.includes('.')) {
                    const [parentField, childField] = field.split('.');
                    valueA = a[parentField] ? a[parentField][childField] : null;
                    valueB = b[parentField] ? b[parentField][childField] : null;
                }

                if (valueA < valueB) {
                    return sortState.direction === 'asc' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortState.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
            setStoreListings(sorted);
        }
    }, [sortState, ecomType]);

    const fieldMappings = {
        az: {
            precio_actual: 'price',
            cantidad_vendida: 'units_ordered',
            ingresos_totales: 'orderedProductSales.amount',
            cantidad_disponible: 'quantity',
            calificacion_promedio: 'average_rating',
            numero_resenas: 'reviews_count',
            ranking_categoria: 'sales_rank',
            visitas_totales: 'browserPageViews',
            // ... otros campos específicos de Amazon ...
        },
        ml: {
            precio_actual: 'listing_price',
            cantidad_vendida: 'sold_quantity',
            ingresos_totales: 'ventas',
            cantidad_disponible: 'available_quantity',
            calificacion_promedio: 'health',
            numero_resenas: null, // MercadoLibre podría no tener este campo
            ranking_categoria: null, // MercadoLibre podría no tener este campo
            visitas_totales: 'pageViews',
            // ... otros campos específicos de MercadoLibre ...
        }
    };

    const fetchListings = async () => {
        if (!store || !currentUser) return;

        setLoading(true);
        setStoreListings([]);

        let startAfterId = currentPage > 0 ? anchorDocs[currentPage - 1] : null;

        const storeRef = collection(db, 'users', currentUser.uid, 'stores', store.id, 'listings');

        // Usa el mapeo de campos aquí
        const sortField = sortState.column ? (fieldMappings[ecomType][sortState.column] || sortState.column) : documentId();

        let q;
        if (startAfterId) {
            q = query(
                storeRef,
                orderBy(sortField, sortState.direction || 'desc'),
                startAfter(startAfterId),
                limit(listingsPerPage)
            );
        } else {
            q = query(
                storeRef,
                orderBy(sortField, sortState.direction || 'desc'),
                limit(listingsPerPage)
            );
        }

        const querySnapshot = await getDocs(q);

        // Maneja la respuesta, creando la lista de listados
        const listings = querySnapshot.docs.map(doc => {
            const data = doc.data();
            const mappings = fieldMappings[ecomType];

            return {
                ...data,
                id: doc.id || "-",
                producto: data.producto || "-",
                asin: data.asin || data.listing_id || "-",
                venta_de_anuncios: data.venta_de_anuncios || "-",
                gasto: data.gasto || "-",
                acos: data.acos || "-",
                impresiones: data.impresiones || "-",
                clicks: data.clicks || "-",
                ctr: data.ctr || "-",
                cpc: data.cpc || "-",
                conv: data.conv || "-",
                pedidos: data.pedidos || "-",
                unidades: data.unidades || "-",
                roas: data.roas || "-",
                thumbnail: data.thumbnail || "https://via.placeholder.com/50",
                store: store,

                // Nuevos campos usando los mappings
                precio_actual: data[mappings.precio_actual] || "-",
                cantidad_vendida: data[mappings.cantidad_vendida] == undefined ? "-" : data[mappings.cantidad_vendida],
                ingresos_totales: (data[mappings.cantidad_vendida] || 0) * (data[mappings.precio_actual] || 0),
                cantidad_disponible: data[mappings.cantidad_disponible] || "-",
                calificacion_promedio: data[mappings.calificacion_promedio] || "-",
                numero_resenas: data[mappings.numero_resenas] || "-",
                ranking_categoria: data[mappings.ranking_categoria] || "-",
                tasa_conversion_general: ((data[mappings.cantidad_vendida] || 0) / (data[mappings.visitas_totales] || 1)) * 100 || "-",
                visitas_totales: data[mappings.visitas_totales] || "-"
            };
        });

        setStoreListings(listings);

        if (listings.length === 0) {
            setLoading(false);
            return;
        }

        // if last listing is not on anchorDocs, add it
        if (currentPage === 0) {
            setAnchorDocs([listings[0].id]);
        } else if (anchorDocs.indexOf(listings[listings.length - 1].id) === -1) {
            setAnchorDocs([...anchorDocs, listings[listings.length - 1].id]);
        }

        setLoading(false);
    };

    useEffect(() => {
        // Cargar listados para la tienda seleccionada
        fetchListings();
    }, [store, currentPage, sortState, currentUser]); // Dependencia en la tienda seleccionada y la página actual

    // if store changes, reset currentPage to 0
    useEffect(() => {
        if (!store) {
            return;
        }
        // Restablecer estados relevantes
        setCurrentPage(0);
        setStoreListings([]);
        // setAnchorDocs([]);
    }, [store]); // Dependencia en la tienda seleccionada    

    return { filteredData, totalPages };
}

export default useListings;