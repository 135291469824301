import { loadStripe } from '@stripe/stripe-js';

const production = true;

export const STRIPE_PUBLIC_KEY = // process.env.NODE_ENV === 'production'
    production
        ? 'pk_live_51MS4qSBXBPWPCaNoMQ4J1E2qWpaRhHKW9mMbcIQVFA0PPyrgcPcVPjrUoeLtczSN1FvwWFDPkyBxp8hvYAKkKKrK005eQeKBBO'
        : 'pk_test_51Q4jfBRuAsA43oAAJ550BzAWFWDXfeX6CqKM2aTkX6Tn5bzSKpGWizUKldYaCbJNzdIflT1oi0wdp3v6itsk3CcD00y0PAhxyW';

export const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
