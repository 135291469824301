import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import loadingAnimationData from '../assets/meli.json';
import successAnimationData from '../assets/success.json';
import errorAnimationData from '../assets/error.json';
import confetti from 'canvas-confetti';

export default function MeliAnimation({ animationState }) {
    const lottieRef = useRef(null);
    const animationInstance = useRef(null);

    const playConfetti = () => {
        if (lottieRef.current) {
            const rect = lottieRef.current.getBoundingClientRect(); // Obtiene las dimensiones y la posición del contenedor
            const centerX = rect.x + rect.width / 2; // Calcula el centro X
            const centerY = rect.y + rect.height / 2; // Calcula el centro Y

            confetti({
                particleCount: 400,
                spread: 180,
                origin: {
                    y: centerY / window.innerHeight, // Normaliza respecto a la altura de la ventana
                    x: centerX / window.innerWidth // Normaliza respecto al ancho de la ventana
                },
                colors: ['#FFE600', '#FFE600', '#3483FA']

            });
        }
    }

    useEffect(() => {
        if (animationState === 'loading') {
            animationInstance.current = lottie.loadAnimation({
                container: lottieRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: loadingAnimationData,
            });
        } else if (animationState === 'success') {
            animationInstance.current = lottie.loadAnimation({
                container: lottieRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: successAnimationData,
            });
            playConfetti(); // Disparar confeti para el estado 'success'
        } else if (animationState === 'error') {
            animationInstance.current = lottie.loadAnimation({
                container: lottieRef.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: errorAnimationData,
            });
        }

        return () => {
            if (animationInstance.current) {
                animationInstance.current.destroy();
            }
        };
    }, [animationState]); // Añadir animationState a la lista de dependencias para reaccionar a cambios

    return (
        <div className="w-40 h-40 mb-10">
            <div ref={lottieRef}></div>
        </div>
    );
}
