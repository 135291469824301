import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Importa useNavigate
import Footer from '../../../components/Footer';
import SalesMixChart from '../../../components/charts/pie/SalesMixChart';
import StoreComparisonChart from '../../../components/charts/bar/StoreComparisonChart';
import AdMetricsChart from '../../../components/charts/AdMetricsChart';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../../helpers/firebase';
import { useTopbar } from '../../../helpers/TopbarContext';

export default function GraficosYAnalisisC() {
  const { relation } = useTopbar();  // Obtiene la relación del contexto
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();  // Declara la función navigate

  const handleCreateRelation = () => {
    navigate('/relacionar-tiendas');  // Navega a la ruta deseada
  };

  const handleScroll = (e) => {
    const scrollTop = e.target.scrollTop;  // Obtiene la posición actual del scroll
    if (scrollTop < lastScrollTop) {
      // scrollear hasta arriba el big-container, suavemente
      const bigContainer = document.getElementById('big-container');
      bigContainer.scrollTop = 0;

    }
    setLastScrollTop(scrollTop);  // Actualiza la última posición del scroll
  };

  useEffect(() => {
    const contentToPdf = document.getElementById('content-to-pdf');
    contentToPdf.addEventListener('scroll', handleScroll);

    return () => {
      contentToPdf.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);  // Dependencias en las que el efecto depende

  useEffect(() => {
    if (!relation || !relation.data || !relation.data.storeFrom || !relation.data.storeTo) {
      return;
    }

    const storeFromId = relation.data.storeFrom;
    const storeToId = relation.data.storeTo;

    const getStores = async () => {
      setLoading(true);
      const storeFromDoc = await getDoc(doc(db, 'users', auth.currentUser.uid, 'stores', storeFromId));
      const storeToDoc = await getDoc(doc(db, 'users', auth.currentUser.uid, 'stores', storeToId));

      const storeFromData = storeFromDoc.data();
      const storeToData = storeToDoc.data();

      const storeFromFormatted = {
        value: storeFromId,
        label: storeFromData.name,
        data: storeFromData,
        id: storeFromId
      };

      const storeToFormatted = {
        value: storeToId,
        label: storeToData.name,
        data: storeToData,
        id: storeToId
      };


      setStores([storeFromFormatted, storeToFormatted]);
      setLoading(false);
    }

    getStores();
    setStoresInRelation([relation?.data?.storeFrom, relation?.data?.storeTo]);
  }, [relation]);

  const [storesInRelation, setStoresInRelation] = useState([]);

  return (
    <div id="big-container" style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>
      <div id='content-to-pdf' className='flex flex-col justify-start items-center bg-gray-100 py-0 px-0' style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>
        {!relation || !storesInRelation.length > 0 ? (
          // Contenido mostrado cuando NO hay relación
          <div className="text-center p-5 max-w-lg h-full flex items-center justify-center flex-col">
            <h1 className="text-xl font-semibold text-gray-800 mb-2">
              No cuentas con ninguna relación aún
            </h1>
            <p className="text-gray-600 mb-4">
              Las relaciones sirven para comparar tiendas con mismos productos en diferentes marketplaces
            </p>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-200" onClick={handleCreateRelation}>
              Crear una relación entre tiendas
            </button>
          </div>
        ) : loading ? (
          // Contenido mostrado cuando se está cargando la información
          <div className="flex justify-center items-center h-full w-full">
            <div className="spinner" />
          </div >
        ) : (
          <div className="flex flex-col items-center justify-start w-full pb-10">
            <div
              className='flex flex-row justify-between items-center w-full mb-5 px-5 '
            >
            </div>
            <div className="flex flex-row items-center justify-center mb-5">
              <SalesMixChart storeIds={storesInRelation} productsProp={null} />
              <StoreComparisonChart storeIdsProp={storesInRelation} metricProp='Ventas' />
            </div>
            <div className="flex flex-row items-center justify-center mb-5">
              <StoreComparisonChart storeIdsProp={storesInRelation} metricProp='Pedidos' />
              <StoreComparisonChart storeIdsProp={storesInRelation} metricProp='AOV' />
            </div>

            <div className="flex flex-row items-center justify-center mb-5">
              <StoreComparisonChart storeIdsProp={storesInRelation} metricProp='Unidades' />
              <StoreComparisonChart storeIdsProp={storesInRelation} metricProp='TotalAcumulado' />
            </div>

            {stores.length > 1 &&
              <div className="flex flex-col items-center justify-start w-full">
                <div className="flex flex-row items-center justify-center mb-5">
                  <AdMetricsChart
                    selectedOptionProp={{ value: 'acos', label: 'ACoS' }}
                    store={stores[0]}
                  />
                  <AdMetricsChart
                    selectedOptionProp={{ value: 'acos', label: 'ACoS' }}
                    store={stores[1]}
                  />
                </div>
                <div className="flex flex-row items-center justify-center mb-5">
                  <AdMetricsChart
                    selectedOptionProp={{ value: 'tacos', label: 'TACoS' }}
                    store={stores[0]}
                  />
                  <AdMetricsChart
                    selectedOptionProp={{ value: 'tacos', label: 'TACoS' }}
                    store={stores[1]}
                  />
                </div>
                <div className="flex flex-row items-center justify-center mb-5">
                  <AdMetricsChart
                    selectedOptionProp={{ value: 'adSpend', label: 'Ad Spend' }}
                    store={stores[0]}
                  />
                  <AdMetricsChart
                    selectedOptionProp={{ value: 'adSpend', label: 'Ad Spend' }}
                    store={stores[1]}
                  />
                </div>
                <div className="flex flex-row items-center justify-center mb-5">
                  <AdMetricsChart
                    selectedOptionProp={{ value: 'roas', label: 'ROAS' }}
                    store={stores[0]}
                  />
                  <AdMetricsChart
                    selectedOptionProp={{ value: 'roas', label: 'ROAS' }}
                    store={stores[1]}
                  />
                </div>
              </div>
            }
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
