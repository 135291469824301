import { useId } from 'react'
import clsx from 'clsx'

const estilosFormulario =
  'block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm'

function Etiqueta({ id, children }) {
  return (
    <label
      htmlFor={id}
      className="mb-3 block text-sm font-medium text-gray-700"
    >
      {children}
    </label>
  )
}

export function CampoTexto({ etiqueta, tipo = 'text', className, ...props }) {
  let id = useId()

  return (
    <div className={className}>
      {etiqueta && <Etiqueta id={id}>{etiqueta}</Etiqueta>}
      <input id={id} type={tipo} {...props} className={estilosFormulario} />
    </div>
  )
}

export function CampoSeleccion({ etiqueta, className, ...props }) {
  let id = useId()

  return (
    <div className={className}>
      {etiqueta && <Etiqueta id={id}>{etiqueta}</Etiqueta>}
      <select id={id} {...props} className={clsx(estilosFormulario, 'pr-8')} />
    </div>
  )
}