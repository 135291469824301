import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ViewWeek, Launch } from '@mui/icons-material';
import { collection, getDocs, doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../helpers/firebase';
import { ToastContainer, toast } from 'react-toastify';
import StoreSelector from './productsCard/StoreSelector';
import SearchBar from './productsCard/SearchBar';
import ColumnToggleMenu from './productsCard/ColumnToggleMenu';
import CampaignsMenu from './productsCard/CampaignsMenu';
import PaginationControls from './productsCard/PaginationControls';
import useListings from '../hooks/useListings';
import ProductRow from './productsCard/ProductRow';
import ColumnHeaders from './productsCard/ColumnHeaders';
import useAuth from '../hooks/useAuth';

function getClassNamesForColumn(columnId) {
    // Retorna las clases CSS basadas en el ID de la columna, ajusta según tus necesidades
    switch (columnId) {
        case 'producto':
            return 'w-96';
        case 'venta_de_anuncios':
            return 'w-48';
        default:
            return 'w-36';
    }
}

const ProductsCard = ({ passedStore }) => {
    const [stores, setStores] = useState([])
    const [store, setStore] = useState(passedStore);
    const { currentUser } = useAuth();

    useEffect(() => {
        setStore(passedStore);
    }, [passedStore]);

    const [columnsMenuAnchorEl, setColumnsMenuAnchorEl] = useState(null);
    const [campaignMenuAnchorEl, setCampaignMenuAnchorEl] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [visibleColumns, setVisibleColumns] = useState({
        producto: true,
        venta_de_anuncios: true,
        gasto: true,
        acos: true,
        impresiones: true,
        clicks: true,
        ctr: true,
        cpc: true,
        conv: true,
        pedidos: true,
        unidades: true,
        roas: true,

        // Nuevas columnas
        precio_actual: true,
        cantidad_vendida: true,
        ingresos_totales: true,
        cantidad_disponible: true,
        calificacion_promedio: true,
        numero_resenas: true,
        ranking_categoria: true,
        tasa_conversion_general: true,
        visitas_totales: true
    });
    const [includeCampaigns, setIncludeCampaigns] = useState([]);
    const [loadingColumnOrder, setLoadingColumnOrder] = useState(true);
    const [sortState, setSortState] = useState({ column: null, direction: 'desc' });

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        setIncludeCampaigns(store && store.data && store.data.campanas ? store.data.campanas : []);
    }, [store]);

    const onSortChange = (columnId) => {
        setSortState((prevState) => {
            if (prevState.column === columnId) {
                return { ...prevState, direction: prevState.direction === 'desc' ? 'asc' : 'desc' };
            } else {
                return { column: columnId, direction: 'desc' };
            }
        });
        setCurrentPage(0);
        // setAnchorDocs([]);
    };

    const handleClickChooseColumns = (event) => {
        setColumnsMenuAnchorEl(event.currentTarget);
    };


    const handleCloseChooseColumns = () => {
        setColumnsMenuAnchorEl(null);
    };

    // Función para manejar el clic en el botón "Elegir Campañas"
    const handleClickChooseCampaigns = (event) => {
        setCampaignMenuAnchorEl(event.currentTarget);
    };

    // Función para cerrar el menú de campañas
    const handleCloseCampaignMenu = () => {
        setCampaignMenuAnchorEl(null);
    };

    const handleColumnToggle = (column) => {
        setVisibleColumns({
            ...visibleColumns,
            [column]: !visibleColumns[column],
        });
    };

    const handleAllColumnsToggle = () => {
        const allVisible = Object.values(visibleColumns).every((column) => column);
        const newVisibleColumns = {};
        for (const key in visibleColumns) {
            newVisibleColumns[key] = !allVisible;
        }
        setVisibleColumns(newVisibleColumns);
    };

    const handleChooseCampaign = (campaign) => {
        setIncludeCampaigns((prevState) => {
            if (prevState.includes(campaign)) {
                return prevState.filter((item) => item !== campaign);
            } else {
                return [...prevState, campaign];
            }
        });
    }

    const handleAllCampaignsToggle = () => {
        if (includeCampaigns.length === store.data.campanas.length) {
            setIncludeCampaigns([]);
        } else {
            setIncludeCampaigns(store.data.campanas);
        }
    }

    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
    };

    const columnNames = {
        producto: "Producto",
        venta_de_anuncios: "Venta de Anuncios",
        gasto: "Gasto",
        acos: "ACOS",
        impresiones: "Impresiones",
        clicks: "Clicks",
        ctr: "CTR",
        cpc: "CPC",
        conv: "Conv",
        pedidos: "Pedidos",
        unidades: "Unidades",
        roas: "ROAS",

        // Nuevas columnas
        precio_actual: "Precio Actual",
        cantidad_vendida: "Cantidad Vendida",
        ingresos_totales: "Ingresos Totales",
        cantidad_disponible: "Cantidad Disponible",
        calificacion_promedio: "Calificación",
        numero_resenas: "Reseñas",
        ranking_categoria: "Ranking",
        tasa_conversion_general: "Tasa de Conversión",
        visitas_totales: "Visitas Totales"
    };

    const [columnsOrder, setColumnsOrder] = useState([
        'producto',
        'precio_actual', 'cantidad_vendida', 'ingresos_totales', 'cantidad_disponible', 'calificacion_promedio', 'numero_resenas', 'ranking_categoria', 'tasa_conversion_general', 'visitas_totales',
        'venta_de_anuncios', 'gasto', 'acos', 'impresiones', 'clicks', 'ctr', 'cpc', 'conv', 'pedidos', 'unidades', 'roas',
    ]);

    const moveColumn = async (draggedId, overId, finalizeDrop = false) => {
        const newOrder = [...columnsOrder];
        const draggedIndex = newOrder.indexOf(draggedId);
        const overIndex = newOrder.indexOf(overId);
        if (draggedIndex >= 0 && overIndex >= 0) {
            newOrder.splice(draggedIndex, 1);
            newOrder.splice(overIndex, 0, draggedId);
            setColumnsOrder(newOrder);

            if (finalizeDrop) {
                if (!currentUser) return;
                const uid = currentUser.uid;
                if (!uid) return;

                const userDocRef = doc(db, `users/${uid}`);
                try {
                    await setDoc(userDocRef, { columnsOrderField: newOrder }, { merge: true });
                    toast.success("¡Nuevo orden de las columnas guardado exitosamente!");
                } catch (error) {
                    toast.error("Ocurrió un error al guardar el orden.");
                }
            }
        }
    };

    const onDrop = async (draggedId, overId) => {
        moveColumn(draggedId, overId, true); // true indicates to finalize the drop and update Firestore
    };

    const saveInitialColumnOrder = async (columnsOrder) => {
        if (!currentUser) return;
        const uid = currentUser.uid;
        if (!uid) return;

        const userDocRef = doc(db, `users/${uid}`);
        await setDoc(userDocRef, { columnsOrderField: columnsOrder }, { merge: true });
    };

    useEffect(() => {
        if (!currentUser) return;
        const uid = currentUser.uid;
        if (!uid) return;

        const fetchAndInitializeColumnsOrder = async () => {
            setLoadingColumnOrder(true);
            const userDocRef = doc(db, `users/${uid}`);
            const docSnap = await getDoc(userDocRef);

            // Si ya existe un orden de gráficas, lo usamos para establecer el estado inicial
            if (docSnap.exists() && docSnap.data().columnsOrderField) {
                const { columnsOrderField } = docSnap.data();
                // Reordenar `columnsOrders` basado en `columnsOrderField`
                setColumnsOrder(columnsOrderField);
            } else {
                // Si no existe un orden, lo guardamos en Firestore
                await saveInitialColumnOrder(columnsOrder);
            }
            setLoadingColumnOrder(false);
        };

        fetchAndInitializeColumnsOrder();
    }, [currentUser]); // Dependencias vacías para que se ejecute solo una vez al montar el componente

    const [anchorDocs, setAnchorDocs] = useState([]);

    const { filteredData, totalPages } = useListings({
        store,
        searchText,
        sortState,
        currentPage,
        setCurrentPage,
        setLoading,
        anchorDocs,
        setAnchorDocs,
        ecomType: store.id.startsWith('ml') ? 'ml' : 'az'
    });

    function renderColumnData(data, columnId) {
        if (!store) {
            return;
        }
        // Retorna el contenido a renderizar basado en el ID de la columna y los datos
        // Ejemplo:
        switch (columnId) {
            case 'producto':
                return (
                    <div className="flex flex-row w-96 pl-3">
                        <img src={data.thumbnail} alt="Producto" className="w-10 h-10 mr-2" />
                        <div className="flex-grow">
                            <a
                                href={!store.id.startsWith('ml') ?
                                    `https://www.amazon.com.mx/dp/${data.asin1}`
                                    :
                                    data.listing_permalink
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-row items-center justify-start"
                            >
                                <p
                                    className="text-sm text-blue-500 underline w-72 truncate"
                                >
                                    {data.producto}
                                </p>

                                <Launch
                                    className="cursor-pointer ml-2"
                                    style={{ fontSize: 15 }}
                                />
                            </a>

                            <p
                                className="text-sm"
                            >
                                { // if it does not start with MLM then show ASIN
                                    !data.asin.startsWith('MLM') ? `ASIN: ${data.asin}` :
                                        // if it starts with MLM then show SKU
                                        `SKU: ${data.asin}`
                                }
                            </p>
                        </div>
                    </div>
                );
            case 'venta_de_anuncios':
                return <p className="w-48 text-sm text-right">{data.venta_de_anuncios !== "-" ? `$${data.venta_de_anuncios.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}</p>;
            case 'gasto':
                return <p className="w-36 text-sm text-right">{data.gasto !== "-" ? `$${data.gasto.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}</p>;
            case 'acos':
                let colorClass = '';
                if (data.acos <= 20) {
                    colorClass = 'text-green-500'; // Clase de Tailwind CSS para texto verde
                } else if (data.acos > 20 && data.acos <= 40) {
                    colorClass = 'text-yellow-500'; // Clase de Tailwind CSS para texto amarillo
                } else if (data.acos !== "-") {
                    colorClass = 'text-red-500'; // Clase de Tailwind CSS para texto rojo
                }

                return <p className={`w-36 text-sm text-right ${colorClass}`}>{data.acos !== "-" ? `${data.acos.toFixed(2)}%` : "-"}</p>;
            case 'impresiones':
                return <p className="w-36 text-sm text-right">
                    {!store.id.startsWith('ml') ? `${data.impresiones.toLocaleString('en-US')}` : `${includeCampaigns.reduce((total, campaign) => {
                        const campaignImpressions = data[`campaign_${campaign.id}_data`] ? data[`campaign_${campaign.id}_data`][0].impressions : 0;
                        return total + campaignImpressions;
                    }, 0).toLocaleString('en-US')}`}
                </p>; // Ejemplo: `1,234`
            case 'clicks':
                return <p className="w-36 text-sm text-right">
                    {!store.id.startsWith('ml') ? `${data.clicks.toLocaleString('en-US')}` : `${includeCampaigns.reduce((total, campaign) => {
                        const campaignClicks = data[`campaign_${campaign.id}_data`] ? data[`campaign_${campaign.id}_data`][0].clicks : 0;
                        return total + campaignClicks;
                    }, 0).toLocaleString('en-US')}`}
                </p>; // Ejemplo: `1,234`
            case 'ctr':
                return <p className="w-36 text-sm text-right">
                    {!store.id.startsWith('ml') ? `${data.ctr.toLocaleString('en-US')}` : `${includeCampaigns.reduce((accumulator, campaign, _, array) => {
                        const campaignCtr = data[`campaign_${campaign.id}_data`] ? data[`campaign_${campaign.id}_data`][0].ctr : 0;
                        return accumulator + campaignCtr;
                    }, 0) / (includeCampaigns.length || 1) // Evita la división por cero
                        .toFixed(2)}%`}
                </p>; // Ejemplo: `2.34%`
            case 'cpc':
                return <p className="w-36 text-sm text-right">{data.cpc !== "-" ? `$${data.cpc.toFixed(2)}` : "-"}</p>; // Ejemplo: `$2.34`
            case 'conv':
                return <p className="w-36 text-sm text-right">{data.conv !== "-" ? `${data.conv.toFixed(2)}%` : "-"}</p>; // Ejemplo: `2.34%`
            case 'pedidos':
                return <p className="w-36 text-sm text-right">{`${data.pedidos.toLocaleString('en-US')}`}</p>; // Ejemplo: `1,234
            case 'unidades':
                return <p className="w-36 text-sm text-right">{`${data.unidades.toLocaleString('en-US')}`}</p>; // Ejemplo: `1,234
            case 'roas':
                return <p className="w-36 pr-3 text-sm text-right">{data.roas !== "-" ? `${data.roas.toFixed(2)}%` : "-"}</p>; // Ejemplo: `2.34%`
            case 'precio_actual':
                return <p className="w-36 text-sm text-right">{data.precio_actual == "-" ? "-" :
                    `$${data.precio_actual.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</p>;
            case 'cantidad_vendida':
                return <p className="w-36 text-sm text-right">{data.cantidad_vendida.toLocaleString('en-US')}</p>;
            case 'ingresos_totales':
                return <p className="w-36 text-sm text-right">{`$${data.ingresos_totales.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</p>;
            case 'cantidad_disponible':
                return <p className="w-36 text-sm text-right">{data.cantidad_disponible.toLocaleString('en-US')}</p>;
            case 'calificacion_promedio':
                return <p className="w-36 text-sm text-right">{data.calificacion_promedio !== "-" ? `${data.calificacion_promedio.toFixed(1)}` : "-"}</p>;
            case 'numero_resenas':
                return <p className="w-36 text-sm text-right">{data.numero_resenas.toLocaleString('en-US')}</p>;
            case 'ranking_categoria':
                return <p className="w-36 text-sm text-right">{data.ranking_categoria !== "-" ? `#${data.ranking_categoria.toLocaleString('en-US')}` : "-"}</p>;
            case 'tasa_conversion_general':
                return <p className="w-36 text-sm text-right">{data.tasa_conversion_general !== "-" ? `${data.tasa_conversion_general.toFixed(2)}%` : "-"}</p>;
            case 'visitas_totales':
                return <p className="w-36 text-sm text-right">{data.visitas_totales.toLocaleString('en-US')}</p>;
            default:
                return null;
        }
    }

    const [tableWidth, setTableWidth] = useState((window.innerWidth - 75) * 0.9);

    useEffect(() => {
        const handleResize = () => {
            setTableWidth((window.innerWidth - 75) * 0.9);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [currentUser]);

    useEffect(() => {
        document.title = 'Informes | Kompa'

        const getUserStores = async () => {
            if (!currentUser) return;
            if (!currentUser.uid) return;
            const storesRef = collection(db, 'users', currentUser.uid, 'stores');
            const storesSnapshot = await getDocs(storesRef);
            const storesList = storesSnapshot.docs.map(doc => {
                const store = doc.data();
                return {
                    id: doc.id,
                    value: store.custom_name,
                    data: store,
                    label: <div
                        className='flex flex-row items-center'
                    >
                        <img
                            // si empieza con ml usamos el logo de mercado libre
                            src={
                                doc.id.startsWith('ml') ?
                                    `https://http2.mlstatic.com/frontend-assets/ui-navigation/5.18.5/mercadolibre/logo__large_plus.png` :
                                    doc.id.startsWith('az') &&
                                    `https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Amazon.com-Logo.svg/1200px-Amazon.com-Logo.svg.png`
                            }
                            className='w-16 mr-4 object-contain'
                            alt="" />
                        {store.custom_name}
                    </div>
                }
            }
            );
            setStores(storesList);
            if (storesList.length > 0 && !store) {
                setStore(storesList[0]);
            }
        };

        getUserStores();
    }, [currentUser]);

    if (loadingColumnOrder) {
        return <div className='h-36 flex justify-center items-center'>
            <div className='spinner' />
        </div>;
    }

    if (!passedStore) {
        return <div>No se ha seleccionado ninguna tienda</div>;
    }
    
    return (
        <DndProvider backend={HTML5Backend}>

            <div
                className="p-4"
                style={{ width: tableWidth }}
            >

                <div className="rounded-lg shadow-lg bg-white w-full">
                    {!passedStore &&
                        <div className='w-full px-4 py-3'>
                            <small
                                className='text-gray-500 w-full text-left'
                            >
                                Selecciona una tienda para ver los productos
                            </small>
                            <StoreSelector stores={stores} selectedStore={store} onStoreChange={setStore} />
                        </div>
                    }
                    <div
                        className="flex flex-row items-center justify-between px-4 py-3"
                        id="productsHeader"
                    >
                        <h2 className="text-lg font-semibold">Productos</h2>
                        <div className="flex flex-row items-center">
                            <SearchBar handleSearchChange={handleSearchChange} />
                            <div
                                className="text-black cursor-pointer mx-2 my-2 rounded-full hover:bg-gray-200 transition-all duration-200 p-1"
                                onClick={handleClickChooseColumns}
                            >
                                <ViewWeek />
                            </div>
                            {store && store.id.startsWith('ml') &&
                                <div
                                    className="cursor-pointer mx-2 my-2 rounded-full transition-all duration-200 px-3 py-1 text-blue-500 border border-blue-500 hover:bg-blue-500 hover:text-white"
                                    onClick={handleClickChooseCampaigns}
                                >
                                    Elegir Campañas
                                </div>
                            }
                        </div>

                        {/* Menú de campañas */}
                        <CampaignsMenu campaignMenuAnchorEl={campaignMenuAnchorEl} handleCloseCampaignMenu={handleCloseCampaignMenu} store={store} includeCampaigns={includeCampaigns} handleAllCampaignsToggle={handleAllCampaignsToggle} handleChooseCampaign={handleChooseCampaign} />

                        {/* Menú de columnas */}
                        <ColumnToggleMenu columnsMenuAnchorEl={columnsMenuAnchorEl} handleCloseChooseColumns={handleCloseChooseColumns} visibleColumns={visibleColumns} columnNames={columnNames} handleAllColumnsToggle={handleAllColumnsToggle} handleColumnToggle={handleColumnToggle} />

                    </div>
                    <div className="overflow-x-auto">
                        <div className="table-wrapper">
                            <ColumnHeaders columnsOrder={columnsOrder} columnNames={columnNames} visibleColumns={visibleColumns} moveColumn={moveColumn} onSortChange={onSortChange} sortState={sortState} onDrop={onDrop} />
                            {loading ? <div className='h-36 flex justify-center items-center'> <div className='spinner' /> </div> :
                                filteredData.map((data, dataIndex) => (
                                    <ProductRow key={dataIndex} data={data} dataIndex={dataIndex} columnsOrder={columnsOrder} visibleColumns={visibleColumns} getClassNamesForColumn={getClassNamesForColumn} renderColumnData={renderColumnData} />
                                ))
                            }
                        </div>
                    </div>
                    <PaginationControls currentPage={currentPage} setCurrentPage={setCurrentPage} totalPages={totalPages} />
                </div>
            </div >
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </DndProvider>
    );
};

export default ProductsCard;
