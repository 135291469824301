import React, { useEffect, useState } from 'react';
import { ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, Cell, ReferenceLine } from 'recharts';
import { collection, documentId, getDocs, limit, orderBy, query, where, getAggregateFromServer, sum } from 'firebase/firestore';
import { auth, db } from '../../helpers/firebase';
import Select from 'react-select';
import { InfoOutlined } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { useTopbar } from '../../helpers/TopbarContext';
import useAuth from '../../hooks/useAuth';

const TrafficConversionChart = ({ store }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [stores, setStores] = useState([])
    const [selectedStore, setSelectedStore] = useState(store);
    const [totalViews, setTotalViews] = useState(0);
    const [averageConversion, setAverageConversion] = useState(0);
    const [totalSales, setTotalSales] = useState(0);
    const { currentUser } = useAuth();

    const {
        startDate,
        endDate,
        selectedChartInterval
    } = useTopbar();

    useEffect(() => {
        if (store) {
            setSelectedStore(store);
        }
    }, [store]);

    const [chartWidth, setChartWidth] = useState(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth * 0.5) - 50));

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setChartWidth((window.innerWidth - 15));
                return;
            }
            const newChartWidth = (window.innerWidth * 0.5) - 50;
            setChartWidth(newChartWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (!selectedStore) return;
            if (!startDate || !endDate) return;
            if (!selectedChartInterval) return;
            if (!currentUser) return;

            setLoading(true);
            const startDateString = startDate?.toISOString().split('T')[0];
            const endDateString = endDate?.toISOString().split('T')[0];

            const daysRef = collection(db, `users/${currentUser.uid}/stores/${selectedStore.id}/days`);
            const q = query(
                daysRef,
                where('__name__', '>=', startDateString),
                where('__name__', '<=', endDateString),
                orderBy('__name__', 'desc')
            );

            const querySnapshot = await getDocs(q);

            const fetchedData = [];
            let totalPageViews = 0;
            let totalSales = 0;

            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                const pageViews = docData.page_views || 0;
                const soldQuantity = docData.sold_quantity || 0;

                totalPageViews += pageViews;
                totalSales += soldQuantity;

                fetchedData.unshift({
                    name: doc.id,
                    pageviews: pageViews,
                    sales: soldQuantity
                });
            });

            const aggregatedData = aggregateData(fetchedData, selectedChartInterval.value);

            setTotalViews(totalPageViews);
            setTotalSales(totalSales);
            setAverageConversion((totalSales / totalPageViews * 100).toFixed(2));
            setData(aggregatedData);
            setLoading(false);
        }

        fetchData();
    }, [startDate, endDate, selectedStore, selectedChartInterval, currentUser]);

    useEffect(() => {
        const getUserStores = async () => {
            if (!currentUser) {
                return;
            }
            const storesRef = collection(db, 'users', currentUser.uid, 'stores');
            const storesSnapshot = await getDocs(storesRef);

            const amazonStores = storesSnapshot.docs.filter(doc => doc.id.startsWith('az'));
            const mercadolibreStores = storesSnapshot.docs.filter(doc => doc.id.startsWith('ml'));

            const storesList = amazonStores.concat(mercadolibreStores).map(doc => {
                const store = doc.data();
                return {
                    id: doc.id,
                    value: store.custom_name,
                    data: store,
                    label: <div
                        className='flex flex-row items-center'
                    >
                        <img
                            // si empieza con ml usamos el logo de mercado libre
                            src={
                                doc.id.startsWith('ml') ?
                                    `https://http2.mlstatic.com/frontend-assets/ui-navigation/5.18.5/mercadolibre/logo__large_plus.png` :
                                    doc.id.startsWith('az') &&
                                    `https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Amazon.com-Logo.svg/1200px-Amazon.com-Logo.svg.png`
                            }
                            className='w-16 mr-4 object-contain'
                            alt="" />
                        {store.custom_name}
                    </div>
                }
            }
            );
            setStores(storesList);
            if (storesList.length > 0 && !store) setSelectedStore(storesList[0]);
        };

        getUserStores();
    }, [currentUser]);

    // Componente de Leyenda Personalizado
    const renderLegend = (props) => {
        const { payload } = props;

        return (
            <div style={{ margin: '0 auto', textAlign: 'center' }}>
                <ul style={{
                    listStyle: 'none',
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'left',
                    marginBottom: 25,
                    flexWrap: 'wrap' // Esto permite que los elementos de la leyenda se ajusten en líneas adicionales si no hay suficiente espacio horizontal.
                }}>
                    {payload.map((entry, index) => (
                        <li key={`item-${index}`} style={{
                            color: entry.color,
                            marginBottom: 10,
                            fontSize: 12,
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: 15 // Ajusta el espaciado entre elementos de la leyenda
                        }}>
                            <svg width="20" height={entry.value === 'pageviews' ? "20" : "5"} style={{ verticalAlign: 'middle', marginRight: 5 }}>
                                <rect width="20" height={entry.value === 'pageviews' ? "20" : "5"} fill={entry.value === 'pageviews' ? barColor : entry.color} />
                            </svg>
                            <span className='text-kompamxblue' style={{ fontSize: 12 }}>
                                {entry.value === 'pageviews' ?
                                    `Vistas (${totalViews.toLocaleString('en-US')} vistas)` :
                                    `Tasa de Conversión (${averageConversion}%)`}
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const aggregateData = (data, interval) => {
        const aggregated = {};

        data.forEach(item => {
            let key;
            const date = new Date(item.name);

            switch (interval) {
                case 'SEMANA':
                    const day = date.getDay();
                    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
                    key = new Date(date.setDate(diff)).toISOString().split('T')[0];
                    break;
                case 'MES':
                    key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
                    break;
                case 'ANO':
                    key = `${date.getFullYear()}`;
                    break;
                default: // 'DIA'
                    key = item.name;
            }

            if (!aggregated[key]) {
                aggregated[key] = { ...item, pageviews: 0, sales: 0 };
            }
            aggregated[key].pageviews += item.pageviews;
            aggregated[key].sales += item.sales;
        });

        // Recalcula la conversión
        Object.values(aggregated).forEach(item => {
            item.conversion = (item.sales / item.pageviews) * 100;
            if (isNaN(item.conversion)) {
                item.conversion = 0;
            }
            // infinite
            if (item.conversion === Infinity) {
                item.conversion = 100;
            }

        });

        return Object.values(aggregated).sort((a, b) => new Date(a.name) - new Date(b.name));
    };


    const CustomizedAxisTick = ({ x, y, payload }) => {
        const date = new Date(payload.value);
        let formattedDate;
        switch (selectedChartInterval.value) {
            case 'SEMANA':
                formattedDate = `Sem ${date.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit' })}`;
                break;
            case 'MES':
                formattedDate = date.toLocaleDateString('es-ES', { month: 'short', year: 'numeric' });
                break;
            case 'ANO':
                formattedDate = date.getFullYear().toString();
                break;
            default: // 'DIA'
                formattedDate = date.toLocaleDateString('es-ES', { day: '2-digit', month: 'short' });
        }
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
                    {formattedDate}
                </text>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const date = new Date(label);
            let formattedDate;
            switch (selectedChartInterval.value) {
                case 'SEMANA':
                    formattedDate = `Semana del ${date.toLocaleDateString('es-ES', { day: '2-digit', month: 'long', year: 'numeric' })}`;
                    break;
                case 'MES':
                    formattedDate = date.toLocaleDateString('es-ES', { month: 'long', year: 'numeric' });
                    break;
                case 'ANO':
                    formattedDate = date.getFullYear().toString();
                    break;
                default: // 'DIA'
                    formattedDate = date.toLocaleDateString('es-ES', { day: '2-digit', month: 'long', year: 'numeric' });
            }
            return (
                <div style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px' }}>
                    <p>{formattedDate}</p>
                    {payload.map((entry, index) => (
                        <p key={`item-${index}`} style={{ color: entry.dataKey === 'pageviews' ? barColor : entry.color }}>
                            {`${entry.name === 'pageviews' ? 'Vistas' : 'Tasa de Conversión'}: ${entry.dataKey === 'pageviews' ? entry.value.toLocaleString('en-US') : entry.value.toFixed(2)}${entry.dataKey === 'conversion' ? `% (${payload[1].payload.sales.toLocaleString('en-US')} ${payload[1].payload.sales === 1 ? 'venta' : 'ventas'})` : ''} `}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    const barColor = ((selectedStore && selectedStore.data.color) || '#1E6BB9');

    return <div className='flex flex-col items-start justify-start bg-white rounded-lg shadow-md m-1' style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
        <div className="flex flex-row items-center px-5 text-kompamxblue text-base justify-between md:justify-between w-full" style={{ minHeight: "3.5rem" }}>
            <div className="flex flex-row items-center justify-start w-full">
                <div className="font-semibold mr-2 lg:whitespace-nowrap">
                    Tendencia de Tráfico y Conversión
                </div>
                <Tooltip title={'Muestra la tendencia de tráfico y la tasa de conversión de la tienda seleccionada.'}>
                    <InfoOutlined style={{ fontSize: 18, marginRight: 15 }} className='ml-auto' />
                </Tooltip>
            </div>
            {!store &&
                <div className=''>
                    <Select
                        options={stores}
                        className=''
                        placeholder='Selecciona una tienda'
                        value={selectedStore}
                        onChange={setSelectedStore}
                    />
                </div>
            }
        </div>
        <hr className="w-full border-gray-300 mb-4" />

        {!selectedStore ?
            <div className="flex justify-center items-center h-full w-full">
                <p className="text-gray-500 text-lg">No hay datos para mostrar</p>
            </div> : loading ?
                <div className='h-full w-full flex justify-center items-center'><div className="spinner" /></div> :
                data.length === 0 ?
                    <div className="flex justify-center items-center h-full w-full">
                        <p className="text-gray-500 text-lg">No hay datos para mostrar</p>
                    </div> :
                    <div className='flex flex-row items-center justify-center w-full pb-3'>
                        <ComposedChart
                            width={chartWidth}
                            height={300}
                            data={data}
                            margin={{
                                top: 20,
                                right: 20,
                                left: 20,
                                bottom: 40,
                            }}
                        >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
                            <YAxis />
                            <YAxis yAxisId="right" orientation="right" tickFormatter={(value) => `${value}%`} />
                            <RechartsTooltip content={<CustomTooltip />} />
                            <Legend content={renderLegend} align="left" verticalAlign="top" layout="horizontal" />


                            <Bar dataKey="pageviews" barSize={20}>
                                {data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={barColor} />
                                ))}
                            </Bar>
                            <Line yAxisId="right" type="linear" dataKey="conversion" stroke="#1E6BB9" strokeWidth={2} dot={false} />

                            <ReferenceLine y={0} stroke="#CCCCCC" strokeDasharray="3 3" />
                        </ComposedChart>
                    </div>
        }
    </div>
};

export default TrafficConversionChart;
