import React from 'react'
import ProductsCard from '../components/ProductsCard';

export default function Informes() {
    return (
        <div className='flex flex-col justify-center items-center bg-gray-100 py-10'>
            <ProductsCard />
        </div>
    )
}
