import { AlertCircle } from 'lucide-react';

export const TrialExpiredNotice = () => (
    <div className="bg-amber-50 border-l-4 border-amber-500 p-6 mb-6">
        <div className="flex items-center">
            <AlertCircle className="h-6 w-6 text-amber-500 mr-3" />
            <div>
                <h3 className="text-lg font-semibold text-amber-800">
                    Tu período de prueba ha expirado
                </h3>
                <p className="text-amber-700 mt-1">
                    Reactiva tu cuenta ahora por solo $100 USD/mes y continúa aprovechando todas las herramientas premium.
                </p>
            </div>
        </div>
    </div>
);