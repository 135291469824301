import React from 'react';
import DraggableColumnHeader from './DraggableColumnHeader';

function ColumnHeaders({ columnsOrder, columnNames, visibleColumns, moveColumn, onSortChange, sortState, onDrop }) {
    return (
        <div
            className='bg-gray-100 flex h-12 border-t-2 border-gray-200'
        >
            <div className='bg-gray-100 flex flex-row space-x-2 p-2 items-center'>
                {columnsOrder.map((columnId) => visibleColumns[columnId] && (
                    <DraggableColumnHeader
                        key={columnId}
                        id={columnId}
                        name={columnNames[columnId]}
                        type="COLUMN"
                        moveColumn={moveColumn}
                        onSortChange={onSortChange}
                        sortState={sortState}
                        onDrop={onDrop}
                    />
                ))}
            </div>
        </div>
    );
}

export default ColumnHeaders;