import React, { createContext, useState, useContext, useEffect } from 'react';
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
import Select from 'react-select';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../../helpers/firebase';
import { Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useTopbar } from '../../helpers/TopbarContext';
import useAuth from '../../hooks/useAuth';

const options = [
    { value: 'acos', label: 'ACoS' },
    { value: 'tacos', label: 'TACoS' },
    { value: 'adSpend', label: 'Gasto en publicidad' },
    { value: 'roas', label: 'ROAS' },
];

const getColor = (value) => {
    const colorMap = {
        acos: '#FFD700',
        tacos: '#B0FC38',
        adSpend: '#FF0000',
        roas: '#FFA500',
    };
    return colorMap[value] || '#000';
};

const renderLegend = (props) => {
    const { payload } = props;
    return (
        <div style={{ margin: '0 auto', textAlign: 'center' }}>
            <ul style={{
                listStyle: 'none',
                display: 'flex',
                justifyContent: 'left',
                marginBottom: 25,
                flexWrap: 'wrap'
            }}>
                {payload.map((entry, index) => (
                    <li key={`item-${index}`} style={{
                        color: entry.color,
                        marginBottom: 10,
                        paddingLeft: 15,
                        fontSize: 12,
                        display: 'flex',
                        alignItems: 'center',
                        marginRight: 15
                    }}>
                        <svg width="10" height={entry.value === 'adSales' || entry.value === 'organicSales' ? "10" : "3"} style={{ verticalAlign: 'middle', marginRight: 5 }}>
                            <rect width="19" height={entry.value === 'adSales' || entry.value === 'organicSales' ? "10" : "3"} fill={entry.value === 'pageviews' ? '#F6B444' : entry.color} />
                        </svg>
                        <span className='text-kompamxblue' style={{ fontSize: 12 }}>
                            {entry.value === 'adSales' ? 'Ventas de publicidad'
                                : entry.value === 'organicSales' ? 'Ventas orgánicas'
                                    : entry.value === 'acos' ? 'ACoS'
                                        : entry.value === 'tacos' ? 'TACoS'
                                            : entry.value === 'adSpend' ? 'Gasto'
                                                : 'ROAS'}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const currencyFormatter = (value) => {
    return new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN'
    }).format(value);
};

const AdMetricsChart = ({ store, selectedOptionProp }) => {
    const [selectedOption, setSelectedOption] = useState(selectedOptionProp || options[0]);
    const [chartWidth, setChartWidth] = useState(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth) * 0.5) - 50);
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState(store);
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState([]);

    const { startDate, endDate, selectedChartInterval } = useTopbar();

    useEffect(() => {
        if (selectedOptionProp) setSelectedOption(selectedOptionProp);
    }, [selectedOptionProp]);

    useEffect(() => {
        if (store) {
            setSelectedStore(store);
        }
    }, [store]);

    useEffect(() => {
        const handleResize = () => {
            setChartWidth(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth) * 0.5) - 50);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const { currentUser } = useAuth();

    useEffect(() => {
        const getUserStores = async () => {
            setLoading(true);
            if (!currentUser) {
                return;
            }
            const storesRef = collection(db, 'users', currentUser.uid, 'stores');
            const storesSnapshot = await getDocs(storesRef);

            const storesList = storesSnapshot.docs.map(doc => ({
                id: doc.id,
                value: doc.data().custom_name,
                data: doc.data(),
                label: <div className='flex flex-row items-center'>
                    <img
                        src={doc.id.startsWith('ml')
                            ? `https://http2.mlstatic.com/frontend-assets/ui-navigation/5.18.5/mercadolibre/logo__large_plus.png`
                            : `https://upload.wikimedia.org/wikipedia/commons/thumb/6/62/Amazon.com-Logo.svg/1200px-Amazon.com-Logo.svg.png`
                        }
                        className='w-16 mr-4 object-contain'
                        alt=""
                    />
                    {doc.data().custom_name}
                </div>
            }));
            setStores(storesList);
            if (storesList.length > 0 && !store) setSelectedStore(storesList[0]);
            setLoading(false);
        };

        getUserStores();
    }, [store, currentUser]);

    const aggregateData = (data, interval) => {
        const aggregated = {};

        data.forEach(item => {
            let key;
            const date = new Date(item.date);

            switch (interval) {
                case 'SEMANA':
                    // Usa el lunes de la semana como clave
                    const day = date.getDay();
                    const diff = date.getDate() - day + (day === 0 ? -6 : 1);
                    key = new Date(date.setDate(diff)).toISOString().split('T')[0];
                    break;
                case 'MES':
                    key = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
                    break;
                case 'ANO':
                    key = `${date.getFullYear()}`;
                    break;
                default: // 'DIA'
                    key = item.date;
            }

            if (!aggregated[key]) {
                aggregated[key] = { ...item, date: key };
            } else {
                ['adSales', 'adSpend', 'organicSales'].forEach(metric => {
                    aggregated[key][metric] += item[metric];
                });
            }
        });

        // Recalcula las métricas derivadas
        Object.values(aggregated).forEach(item => {
            const totalSales = item.adSales + item.organicSales;
            item.acos = item.adSales > 0 ? (item.adSpend / item.adSales) * 100 : 0;
            item.roas = item.adSpend > 0 ? (item.adSales / item.adSpend) : 0;
            item.tacos = totalSales > 0 ? (item.adSpend / totalSales) * 100 : 0;
        });

        return Object.values(aggregated).sort((a, b) => new Date(a.date) - new Date(b.date));
    };

    useEffect(() => {
        const fetchStoreData = async () => {
            if (!selectedStore || !startDate || !endDate) return;

            if (!currentUser) {
                return;
            }

            setLoading(true);
            const userId = currentUser.uid;
            const storeDaysRef = collection(db, `users/${userId}/stores/${selectedStore.id}/days`);
            const startDateString = startDate.toISOString().split('T')[0];
            const endDateString = endDate.toISOString().split('T')[0];

            const q = selectedStore.id.startsWith('ml')
                ? query(storeDaysRef, where('__name__', '>=', startDateString), where('__name__', '<=', endDateString))
                : query(storeDaysRef, where('__name__', '>=', startDateString), where('__name__', '<=', endDateString));

            try {
                const querySnapshot = await getDocs(q);
                const dailyData = {};

                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    const adSpend = data.ad_report?.spend || data.ads_cost || 0;
                    const adSales = data.ad_report?.sales30d || data.ads_amount || 0;
                    const totalSalesAmount = data.sales_and_traffic?.salesByDate?.orderedProductSales?.amount || (data.total_amount + data.ads_amount) || 0;
                    const organicSales = Math.max(totalSalesAmount - adSales, 0) || data.total_amount || 0;

                    dailyData[doc.id] = {
                        date: doc.id,
                        adSales: adSales,
                        adSpend: adSpend,
                        organicSales: organicSales,
                        acos: adSales > 0 ? (adSpend / adSales) * 100 : 0,
                        roas: adSpend > 0 ? (adSales / adSpend) : 0,
                        tacos: totalSalesAmount > 0 ? (adSpend / totalSalesAmount) * 100 : 0
                    };
                });

                // Fill in missing dates
                let currentDate = new Date(startDate);
                const formattedData = [];
                while (currentDate <= endDate) {
                    const dateString = currentDate.toISOString().split('T')[0];
                    formattedData.push(dailyData[dateString] || {
                        date: dateString,
                        acos: 0,
                        adSales: 0,
                        adSpend: 0,
                        organicSales: 0,
                        roas: 0,
                        tacos: 0
                    });
                    currentDate.setDate(currentDate.getDate() + 1);
                }

                const aggregatedData = aggregateData(formattedData, selectedChartInterval.value);
                setChartData(aggregatedData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching store data: ", error);
                setLoading(false);
            }
        };

        fetchStoreData();
    }, [startDate, endDate, selectedStore, selectedChartInterval, currentUser]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-white p-3 border border-gray-300 shadow-md">
                    <p className="label">{formatLabel(label)}</p>
                    <ul className="list-disc list-inside">
                        {payload.map((entry, index) => (
                            <li key={`item-${index}`} style={{ color: entry.color }}>
                                <span className='text-kompamxblue'>
                                    {entry.name === 'adSales' ? 'Ventas de publicidad'
                                        : entry.name === 'organicSales' ? 'Ventas orgánicas'
                                            : entry.name === 'acos' ? 'ACoS'
                                                : entry.name === 'tacos' ? 'TACoS'
                                                    : entry.name === 'adSpend' ? 'Gasto en publicidad'
                                                        : 'ROAS'}
                                </span>
                                <span className='text-kompamxblue font-semibold'>
                                    : {entry.name === 'acos' || entry.name === 'tacos' || entry.name === 'roas'
                                        ? `${entry.value.toFixed(2)}%`
                                        : currencyFormatter(entry.value)}
                                </span>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
        return null;
    };

    const formatLabel = (label) => {
        const date = new Date(label);
        switch (selectedChartInterval.value) {
            case 'SEMANA':
                return `${date.toLocaleDateString('es-ES', { day: '2-digit', month: 'short', year: 'numeric' })}`;
            case 'MES':
                return date.toLocaleDateString('es-ES', { month: 'long', year: 'numeric' });
            case 'ANO':
                return date.getFullYear().toString();
            default: // 'DIA'
                return date.toLocaleDateString('es-ES', { day: '2-digit', month: 'short', year: 'numeric' });
        }
    };

    return (
        <div className='flex flex-col items-center justify-center bg-white rounded-lg shadow-md m-1' style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
            <div className="flex flex-row items-center px-5 text-kompamxblue text-base justify-start w-full" style={{ minHeight: "3.5rem" }}>
                <div className="font-semibold mr-2 whitespace-nowrap">
                    {store ? `${selectedOption.label} de ${store.data.custom_name}` : `Rendimiento de ${selectedOption.label}`}
                </div>
                <Tooltip title={`El gráfico muestra el rendimiento diario de la publicidad basado en la métrica ${selectedOption.label}`}>
                    <InfoOutlined style={{ fontSize: 18 }} className='ml-auto mr-2' />
                </Tooltip>
                <div className='flex flex-row'>
                    {!store &&
                        <Select
                            options={stores}
                            className='mr-2'
                            placeholder='Selecciona una tienda'
                            value={selectedStore}
                            onChange={setSelectedStore}
                        />
                    }
                    {!selectedOptionProp &&
                        <Select
                            className=''
                            value={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                        />
                    }
                </div>
            </div>

            <hr className="w-full border-gray-300" />

            {(chartData && chartData.length > 0) ?
                <div className="flex flex-col items-center justify-center w-full h-full">
                    <ResponsiveContainer>
                        <ComposedChart
                            data={chartData}
                            margin={{ top: 30, right: 30, bottom: 30, left: 50 }}
                        >
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis
                                dataKey="date"
                                tickFormatter={(tick) => formatLabel(tick)}
                                angle={-35}
                                textAnchor="end"
                                height={60}
                            />
                            <YAxis yAxisId="left" orientation="left" tickFormatter={currencyFormatter} />
                            <YAxis yAxisId="right" orientation="right" tickFormatter={(value) => `${value.toFixed(2)}%`} />
                            <RechartsTooltip content={<CustomTooltip />} />
                            <Legend content={renderLegend} align="left" verticalAlign="top" layout="horizontal" />
                            <Bar yAxisId="left" dataKey="adSales" stackId="a" fill="#205096" />
                            <Bar yAxisId="left" dataKey="organicSales" stackId="a" fill="#757575" />
                            <Line
                                yAxisId={selectedOption.value === 'adSpend' ? 'left' : 'right'}
                                type="linear"
                                dataKey={selectedOption.value}
                                stroke={getColor(selectedOption.value)}
                                dot={false}
                                strokeWidth={2}
                            />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
                : loading ?
                    <div className="flex justify-center items-center h-full w-full">
                        <div className="spinner" />
                    </div>
                    :
                    <div className="flex justify-center items-center h-full w-full">
                        <p className="text-gray-500 text-lg">No hay datos para mostrar</p>
                    </div>
            }
        </div>
    );
};

export default AdMetricsChart;
