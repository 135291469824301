import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Importa useNavigate
import Footer from '../../../components/Footer';
import ProductsCardComparativa from '../../../components/ProductsCardComparativa';
import { useTopbar } from '../../../helpers/TopbarContext';

export default function ComparativaDeProductosC() {
    const { relation } = useTopbar();
    const navigate = useNavigate();  // Declara la función navigate

    const handleCreateRelation = () => {
        navigate('/relacionar-tiendas');  // Navega a la ruta deseada
    };

    const [lastScrollTop, setLastScrollTop] = useState(0);

    const handleScroll = (e) => {
        const scrollTop = e.target.scrollTop;  // Obtiene la posición actual del scroll
        if (scrollTop < lastScrollTop) {
            // scrollear hasta arriba el big-container, suavemente
            const bigContainer = document.getElementById('big-container');
            bigContainer.scrollTop = 0;

        }
        setLastScrollTop(scrollTop);  // Actualiza la última posición del scroll
    };

    useEffect(() => {
        const contentToPdf = document.getElementById('content-to-pdf');
        contentToPdf.addEventListener('scroll', handleScroll);

        return () => {
            contentToPdf.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);  // Dependencias en las que el efecto depende

    return (
        <div id="big-container" style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>
            <div id='content-to-pdf' className='flex flex-col justify-start items-center bg-gray-100 py-5 px-5' style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>
                {(!relation || !relation.data || !relation.data.storeFrom || !relation.data.storeTo) ? (
                    // Contenido mostrado cuando NO hay relación
                    <div className="text-center p-5 max-w-lg h-full flex items-center justify-center flex-col">
                        <h1 className="text-xl font-semibold text-gray-800 mb-2">
                            No cuentas con ninguna relación aún
                        </h1>
                        <p className="text-gray-600 mb-4">
                            Las relaciones sirven para comparar tiendas con mismos productos en diferentes marketplaces
                        </p>
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-200" onClick={handleCreateRelation}>
                            Crear una relación entre tiendas
                        </button>
                    </div>
                ) : (
                    <div>
                        <ProductsCardComparativa relation={relation} />
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}
