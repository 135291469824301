import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, query, where, orderBy, deleteDoc, doc } from 'firebase/firestore';
import { auth, db } from '../helpers/firebase';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import DeleteIcon from '@mui/icons-material/Delete';

const TaskViewer = () => {
    const [tasks, setTasks] = useState({
        failed: [],
        pending: [],
        historical: []
    });
    const [currentUser, setCurrentUser] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        const unsubscribeAuth = auth.onAuthStateChanged(user => {
            setCurrentUser(user);
        });

        return () => unsubscribeAuth();
    }, []);

    useEffect(() => {
        if (!currentUser) return;

        const collections = ['failed_tasks', 'pending_tasks', 'historical_tasks'];
        const unsubscribes = collections.map(collectionName => {
            let q;
            if (collectionName === 'historical_tasks') {
                q = query(
                    collection(db, collectionName),
                    where('paraUsuario', '==', currentUser.uid),
                    orderBy('fecha', 'desc')
                );
            } else {
                q = query(
                    collection(db, collectionName),
                    where('paraUsuario', '==', currentUser.uid)
                );
            }

            return onSnapshot(q, (querySnapshot) => {
                const tasksData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setTasks(prevTasks => ({
                    ...prevTasks,
                    [collectionName.split('_')[0]]: tasksData
                }));
            });
        });

        return () => unsubscribes.forEach(unsubscribe => unsubscribe());
    }, [currentUser]);

    const deleteTask = async (collectionName, taskId) => {
        if (!currentUser) return;
        setIsDeleting(true);
        try {
            await deleteDoc(doc(db, `${collectionName}_tasks`, taskId));
        } catch (error) {
            console.error("Error deleting task:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const deleteAllTasks = async (collectionName) => {
        if (!currentUser) return;
        setIsDeleting(true);
        const tasksToDelete = tasks[collectionName];
        for (const task of tasksToDelete) {
            try {
                await deleteDoc(doc(db, `${collectionName}_tasks`, task.id));
            } catch (error) {
                console.error("Error deleting task:", error);
            }
        }
        setIsDeleting(false);
    };

    const renderTaskTable = (taskList, title, collectionName) => (
        <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">{title}</h2>
                <button
                    onClick={() => deleteAllTasks(collectionName)}
                    className=" font-bold py-2 px-4 rounded text-red-500 hover:text-red-700 hover:bg-red-100 flex flex-row items-center justify-center"
                    disabled={isDeleting}
                >
                    {isDeleting ? 'Limpiando...' : 'Limpiar Todas'}
                    <CleaningServicesIcon className="ml-2" />
                </button>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="px-4 py-2 border-b">ID</th>
                            <th className="px-4 py-2 border-b">Tipo</th>
                            <th className="px-4 py-2 border-b">Para Tienda</th>
                            <th className="px-4 py-2 border-b">Estatus</th>
                            <th className="px-4 py-2 border-b">Fecha</th>
                            <th className="px-4 py-2 border-b">Otros Campos</th>
                            <th className="px-4 py-2 border-b">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {taskList.map(task => (
                            <tr key={task.id} className="hover:bg-gray-50">
                                <td className="px-4 py-2 border-b">{task.id}</td>
                                <td className="px-4 py-2 border-b">{task.type || 'N/A'}</td>
                                <td className="px-4 py-2 border-b">{task.paraTienda || 'N/A'}</td>
                                <td className="px-4 py-2 border-b">{task.status || 'N/A'}</td>
                                <td className="px-4 py-2 border-b">{task.fecha ? new Date(task.fecha.seconds * 1000).toLocaleString() : 'N/A'}</td>
                                <td className="px-4 py-2 border-b">
                                    {Object.entries(task)
                                        .filter(([key]) => !['id', 'type', 'paraUsuario', 'paraTienda', 'status', 'fecha'].includes(key))
                                        .map(([key, value]) => (
                                            <div key={key} className="text-sm">
                                                <strong>{key}:</strong> {JSON.stringify(value)}
                                            </div>
                                        ))}
                                </td>
                                <td className="px-4 py-2 border-b">
                                    <button
                                        onClick={() => deleteTask(collectionName, task.id)}
                                        className="font-bold py-1 px-2 rounded text-sm flex flex-row items-center justify-center text-red-500 hover:text-red-700 hover:bg-red-100"
                                        disabled={isDeleting}
                                    >
                                        {isDeleting ? 'Eliminando...' : 'Eliminar'}
                                        <DeleteIcon className="ml-2" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );

    if (!currentUser) {
        return <div>Por favor, inicia sesión para ver tus tareas.</div>;
    }

    return (
        <div className="bg-white w-full min-h-[91vh] overflow-y-auto border-t border-b border-gray-300">
            <div className="p-8">
                <h1 className="text-3xl font-bold mb-8">Mis Tareas</h1>
                {renderTaskTable(tasks.failed, 'Tareas Fallidas', 'failed')}
                {renderTaskTable(tasks.pending, 'Tareas Pendientes', 'pending')}
                {renderTaskTable(tasks.historical, 'Tareas Históricas', 'historical')}
            </div>
        </div>
    );
};

export default TaskViewer;