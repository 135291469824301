import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle2 } from 'lucide-react';

function GarabatoCurvo(props) {
  return (
    <svg
      aria-hidden="true"
      viewBox="0 0 281 40"
      preserveAspectRatio="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.172 22.994c-8.007 1.246-15.477 2.23-31.26 4.114-18.506 2.21-26.323 2.977-34.487 3.386-2.971.149-3.727.324-6.566 1.523-15.124 6.388-43.775 9.404-69.425 7.31-26.207-2.14-50.986-7.103-78-15.624C10.912 20.7.988 16.143.734 14.657c-.066-.381.043-.344 1.324.456 10.423 6.506 49.649 16.322 77.8 19.468 23.708 2.65 38.249 2.95 55.821 1.156 9.407-.962 24.451-3.773 25.101-4.692.074-.104.053-.155-.058-.135-1.062.195-13.863-.271-18.848-.687-16.681-1.389-28.722-4.345-38.142-9.364-15.294-8.15-7.298-19.232 14.802-20.514 16.095-.934 32.793 1.517 47.423 6.96 13.524 5.033 17.942 12.326 11.463 18.922l-.859.874.697-.006c2.681-.026 15.304-1.302 29.208-2.953 25.845-3.07 35.659-4.519 54.027-7.978 9.863-1.858 11.021-2.048 13.055-2.145a61.901 61.901 0 0 0 4.506-.417c1.891-.259 2.151-.267 1.543-.047-.402.145-2.33.913-4.285 1.707-4.635 1.882-5.202 2.07-8.736 2.903-3.414.805-19.773 3.797-26.404 4.829Z"
      />
    </svg>
  )
}

function PremiumPlan() {
  const navigate = useNavigate();

  return (
    <section className="bg-blue-600 rounded-3xl px-6 sm:px-8 py-8 max-w-2xl mx-auto">
      <h3 className="mt-5 font-display text-lg text-white">Premium</h3>
      <p className="mt-2 text-base text-white">
        Accede a todas las herramientas de análisis para potenciar tu negocio
      </p>
      <p className="order-first font-display text-5xl font-light tracking-tight text-white">
        $100
      </p>
      <ul
        role="list"
        className="order-last mt-10 flex flex-col gap-y-3 text-sm text-white"
      >
        <li className="flex items-center">
          <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
          <span className="ml-3">Análisis de tiendas ML y Amazon</span>
        </li>
        <li className="flex items-center">
          <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
          <span className="ml-3">Herramientas de comparación</span>
        </li>
        <li className="flex items-center">
          <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
          <span className="ml-3">Métricas temporales y por producto</span>
        </li>
        <li className="flex items-center">
          <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
          <span className="ml-3">Análisis de relaciones entre marketplaces</span>
        </li>
        <li className="flex items-center">
          <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
          <span className="ml-3">Análisis de métricas publicitarias</span>
        </li>
      </ul>
      <div className="mt-8 flex flex-col gap-4">
        <button
          onClick={() => navigate('/registro')}
          className="block w-full rounded-lg py-2 px-3 text-center text-sm font-semibold text-blue-600 bg-white hover:bg-gray-100 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
        >
          Prueba gratis por 7 días
        </button>
        <button
          onClick={() => navigate('/pago')}
          className="block w-full rounded-lg py-2 px-3 text-center text-sm font-semibold text-white border border-white hover:bg-blue-500 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
        >
          Comenzar con Premium
        </button>
      </div>
    </section>
  )
}

export function Precios() {
  return (
    <section
      id="precios"
      aria-label="Precios"
      className="bg-slate-900 py-20 sm:py-32"
    >
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="md:text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            <span className="relative whitespace-nowrap">
              <GarabatoCurvo className="absolute left-0 top-1/2 h-[1em] w-full fill-blue-400" />
              <span className="relative">Precios simples,</span>
            </span>{' '}
            para todos.
          </h2>
          <p className="mt-4 text-lg text-slate-400">
            No importa el tamaño de tu negocio, nuestro software se adaptará perfectamente a tus necesidades de análisis de e-commerce.
          </p>
        </div>
        <div className="mt-16">
          <PremiumPlan />
        </div>
      </div>
    </section>
  )
}

export default Precios;