import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { getAuth } from 'firebase/auth';
import { motion } from 'framer-motion';
import { CreditCard, Loader2, AlertCircle } from 'lucide-react';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../helpers/firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardholderName, setCardholderName] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const auth = getAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements || !auth.currentUser) {
            setError('No se puede procesar el pago en este momento');
            return;
        }

        setIsProcessing(true);
        setError(null);

        try {
            const cardElement = elements.getElement(CardElement);
            const { paymentMethod, error: pmError } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: { name: cardholderName }
            });

            if (pmError) throw pmError;

            const idToken = await auth.currentUser.getIdToken();
            const response = await fetch('https://us-central1-goodz-project.cloudfunctions.net/process_subscription', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    paymentMethodId: paymentMethod.id
                })
            });

            const result = await response.json();
            if (!result.success) throw new Error(result.error);

            const { error } = await stripe.confirmCardPayment(result.clientSecret);
            if (error) throw error;

            // Update Firestore
            await setDoc(doc(db, 'users', auth.currentUser.uid), {
                subscription_type: 'premium',
                subscription_id: result.subscriptionId,
                updated_at: new Date()
            }, { merge: true });

            window.location.href = '/tablero';
        } catch (err) {
            console.error('Error:', err);
            setError(err.message || 'Error al procesar el pago');
            toast.error('Error al procesar el pago', {
                position: "top-center",
                autoClose: 5000
            });
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label className="block text-sm font-medium text-gray-700">
                        Nombre del titular
                        <input
                            type="text"
                            value={cardholderName}
                            onChange={(e) => setCardholderName(e.target.value)}
                            required
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 h-10 px-4"
                            placeholder="Como aparece en la tarjeta"
                        />
                    </label>
                </div>

                <div className="rounded-md border border-gray-300 p-4 bg-white shadow-sm">
                    <CardElement
                        options={{
                            style: {
                                base: {
                                    fontSize: '16px',
                                    color: '#424770',
                                    '::placeholder': {
                                        color: '#aab7c4',
                                    },
                                },
                                invalid: {
                                    color: '#9e2146',
                                },
                            },
                        }}
                    />
                </div>

                {error && (
                    <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="flex items-center text-red-600 bg-red-50 p-3 rounded-md"
                    >
                        <AlertCircle className="w-5 h-5 mr-2" />
                        <span>{error}</span>
                    </motion.div>
                )}

                <motion.button
                    type="submit"
                    className="w-full bg-indigo-600 text-white py-3 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 flex items-center justify-center transition-colors duration-200 disabled:opacity-50"
                    disabled={isProcessing || !stripe}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                >
                    {isProcessing ? (
                        <>
                            <Loader2 className="w-5 h-5 mr-2 animate-spin" />
                            Procesando...
                        </>
                    ) : (
                        <>
                            <CreditCard className="w-5 h-5 mr-2" />
                            Pagar y activar membresía
                        </>
                    )}
                </motion.button>
            </form>
            <ToastContainer />
        </>
    );
};

export default PaymentForm;