import React, { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { auth, db, functions } from '../../helpers/firebase';
import MeliAnimation from '../../components/MeliAnimation';
import './amazonConnect.css';
import Footer from '../../components/Footer';
import { collection, getDocs } from 'firebase/firestore';
import { useTopbar } from '../../helpers/TopbarContext';

export default function MercadoLibreConnect({ stores, setStores = () => { }
    , hideSidebar, setHideSidebar = () => { } }) {
    const [loadingTokenData, setLoadingTokenData] = useState(false);
    const [authCodeML, setAuthCodeML] = useState(null);
    const [tokenDataML, setTokenDataML] = useState(null);
    const [apiCalled, setApiCalled] = useState(false);
    const { setShouldSidebarBeOpen } = useTopbar();

    useEffect(() => {
        document.title = 'Mercado Libre Connect | Kompa';
    }, []);

    const [lastScrollTop, setLastScrollTop] = useState(0);

    const handleScroll = (e) => {
        const scrollTop = e.target.scrollTop;  // Obtiene la posición actual del scroll
        if (scrollTop < lastScrollTop) {
            // scrollear hasta arriba el big-container, suavemente
            const bigContainer = document.getElementById('big-container');
            bigContainer.scrollTop = 0;

        }
        setLastScrollTop(scrollTop);  // Actualiza la última posición del scroll
    };

    useEffect(() => {
        const contentToPdf = document.getElementById('content-to-pdf');
        contentToPdf.addEventListener('scroll', handleScroll);

        return () => {
            contentToPdf.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);  // Dependencias en las que el efecto depende

    useEffect(() => {
        const exchangeMercadoLibreCodeForToken = async (code) => {
            if (!code) return;
            if (loadingTokenData) return;
            if (tokenDataML) return;

            setLoadingTokenData(true);
            const exchangeFunction = httpsCallable(functions, 'exchangeMercadoLibreCode');
            try {
                const result = await exchangeFunction({ authCodeML: code });
                // get user stores from users/{userId}/stores
                const user = auth.currentUser;
                const userId = user.uid;
                const storesCollection = collection(db, `users/${userId}/stores`);
                const storesSnapshot = await getDocs(storesCollection);
                const storesData = storesSnapshot.docs.map(doc => doc.data());
                setTokenDataML(result.data);
                setHideSidebar(false);
                setLoadingTokenData(false);
                setStores(storesData);
                setShouldSidebarBeOpen(true);
            } catch (error) {
                setLoadingTokenData(false);
                setTokenDataML(null);
                setHideSidebar(false);

                console.error('Error al intercambiar el código de Mercado Libre:', error);
            }
        };

        const fetchData = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const code = queryParams.get('code');
            setAuthCodeML(code);
            if (code && !apiCalled) {
                setApiCalled(true);
                exchangeMercadoLibreCodeForToken(code);
            }
        };

        setHideSidebar(true);
        fetchData();
    }, [apiCalled]);

    return (
        <div id="big-container" style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}>

            <div
                id='content-to-pdf'
                className={`flex flex-col justify-center items-center bg-gray-100 py-5 px-5 ${((stores && stores.length && stores.length > 0) && !hideSidebar) && ""}`}
                style={{ height: 'calc(100vh - 4rem)', overflowY: 'auto' }}
            >
                <MeliAnimation animationState={loadingTokenData ? 'loading' : (tokenDataML && tokenDataML.access_token && tokenDataML.refresh_token ? 'success' : 'error')} />

                {authCodeML ?
                    (!loadingTokenData ?
                        <h1
                            className="mb-8 text-center w-1/2 text-green-500"
                            style={{
                                fontWeight: 'bold',
                                display: 'inline-block',
                                fontFamily: 'monospace',
                                fontSize: '30px'
                            }}
                        >
                            {tokenDataML && tokenDataML.access_token && tokenDataML.refresh_token ?
                                <>
                                    ¡Conexión con MercadoLibre exitosa!
                                    <p className="text-gray-400 text-sm block font-normal mt-4">
                                        Recibirás un correo electrónico cuando se haya terminado de traer la información de tu tienda.
                                    </p>
                                </>
                                : ''}
                        </h1> :
                        <div className="flex flex-row w-full justify-center items-center">
                            <div className="flex justify-center items-center">
                                <h1 className="text-kompamxblue font-bold mb-8 text-center w-full loading">
                                    Conectando con MercadoLibre...
                                </h1>
                            </div>
                        </div>
                    ) :
                    <h1 className="text-5xl font-bold mb-8 text-center text-red-500 w-1/2">
                        ¡Conexión con MercadoLibre fallida!
                    </h1>
                }

                {!authCodeML &&
                    <p className="text-2xl font-medium text-center text-red-500">
                        No se pudo obtener el código de MercadoLibre.
                    </p>
                }
                {
                    !loadingTokenData && authCodeML &&
                    !(tokenDataML && tokenDataML.access_token && tokenDataML.refresh_token) &&
                    <p className="text-2xl font-medium text-center text-red-500">
                        Hubo un problema obteniendo el token, por favor intente de nuevo.
                    </p>
                }
            </div >
            <Footer />
        </div>
    );
}
