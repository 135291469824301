import { Link } from 'react-router-dom'
import { useCallback } from 'react'

const scrollToSection = (elementId) => {
  const element = document.getElementById(elementId)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}

export function EnlaceNavegacion({ href, children }) {
  const handleClick = useCallback((e) => {
    if (href.startsWith('#')) {
      e.preventDefault()
      const sectionId = href.substring(1)
      scrollToSection(sectionId)
    }
  }, [href])

  return (
    <Link
      to={href}
      className="inline-block rounded-lg px-2 py-1 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
      onClick={handleClick}
    >
      {children}
    </Link>
  )
}