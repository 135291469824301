import React from 'react';
import { Search } from '@mui/icons-material';

function SearchBar({ handleSearchChange }) {
    const [isSearchFocused, setIsSearchFocused] = React.useState(false);
    return (
        <div
            className={`mr-2 flex flex-row border-b px-2 py-1 text-black bg-transparent box-content leading-normal  ${isSearchFocused ? "border-kompamx-300 border-b-2" : "hover:border-black hover:border-b-2 border-gray-300"}`}
        >
            <Search className="mr-2" />
            <input
                type="text"
                placeholder="Buscar productos"
                className='w-full bg-transparent focus:outline-none'
                onFocus={() => {
                    setIsSearchFocused(true);
                }}
                onBlur={() => {
                    setIsSearchFocused(false);
                }}
                onChange={handleSearchChange}
            />
        </div>
    );
}

export default SearchBar;
