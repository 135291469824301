
import backgroundImage from '../imagenes/fondo-autenticacion.jpg'

export function DisenoFinoV2({ children }) {
  return (
    <div className="relative flex min-h-screen shrink-0 justify-center md:px-12 lg:px-0">
      <div className="mx-auto relative z-10 min-h-full flex flex-col bg-white shadow-2xl sm:justify-center w-[50%]">
        {children}
      </div>
      <div className="hidden sm:contents lg:relative lg:block lg:flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={backgroundImage}
          alt=""
        />
      </div>
    </div>
  )
}
