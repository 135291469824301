
import { Boton } from '../componentes/Boton'
import { Logo } from '../componentes/Logo'
import { DisenoFino } from '../componentes/DisenoFino'
import { Link } from 'react-router-dom'

export default function NotFound() {
  return (
    <DisenoFino>
      <div className="flex">
        <Link to="/" aria-label="Inicio">
          <Logo className="h-10 w-auto" />
        </Link>
      </div>
      <p className="mt-20 text-sm font-medium text-gray-700">404</p>
      <h1 className="mt-3 text-lg font-semibold text-gray-900">
        Página no encontrada
      </h1>
      <p className="mt-3 text-sm text-gray-700">
        Lo sentimos, no pudimos encontrar la página que estás buscando.
      </p>
      <Boton to="/" className="mt-10">
        Volver al inicio
      </Boton>
    </DisenoFino>
  )
}