import React, { useEffect, useState } from 'react';
import { collection, collectionGroup, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../helpers/firebase';
import { Launch, FirstPage, NavigateBefore, NavigateNext, LastPage } from '@mui/icons-material';
import mx from '../../assets/flags/4x3/mx.svg';
import amazonIcon from '../../assets/icons/amazon_icon.jpg';
import mercadolibreIcon from '../../assets/icons/mercadolibre_icon.jpg';
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { InfoOutlined } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTopbar } from '../../helpers/TopbarContext';
import useAuth from '../../hooks/useAuth';

const ColumnHeader = ({ name, type, id }) => {
    return (
        <div
            className={`text-gray-500 text-sm font-semibold uppercase flex flex-row items-center ${name === "Producto" ? "pl-3 w-96" : name === "Tienda" ? "w-48" : name === "Disponibilidad" ? "w-36" : "w-24"} ${name === "Producto" ? "text-left justify-start" : name === "Disponibilidad" ? "text-center justify-center" : "text-right justify-end"}`}
        >
            <span className="flex items-center">
                {name}
            </span>
        </div>
    );
};

function getClassNamesForColumn(columnId) {
    // Retorna las clases CSS basadas en el ID de la columna, ajusta según tus necesidades
    switch (columnId) {
        case 'producto':
            return 'w-96';
        case 'tienda':
            return 'w-48';
        default:
            return 'w-24';
    }
}

export default function MostSoldProducts({ store, sortingTypeProp }) {
    const [mostSoldProducts, setMostSoldProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const limitPerPage = 5;
    const [totalPages, setTotalPages] = useState(0);
    const [chartWidth, setChartWidth] = useState(window.innerWidth < 768 ? (window.innerWidth - 15) : ((window.innerWidth) * 0.5) - 50);
    const [sortingType, setSortingType] = useState(sortingTypeProp || 'desc');

    const { currentUser } = useAuth();

    const {
        startDate,
        endDate,
        comparisonStartDate,
        comparisonEndDate,
    } = useTopbar();

    function renderColumnData(data, columnId, currentPeriodData, comparisonPeriodData) {
        switch (columnId) {
            case 'producto':
                return (
                    <div className="w-96 flex flex-row pl-3 border-b border-gray-200 items-center">
                        <img src={data.thumbnail || "https://via.placeholder.com/150"} alt="Producto" className="w-10 h-10 mr-2 object-contain" />
                        <div className="flex flex-col items-start justify-start">
                            <a
                                href={data.asin1 ?
                                    `https://www.amazon.com.mx/dp/${data.asin1}`
                                    :
                                    data.listing_permalink
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex flex-row items-center justify-start"
                            >
                                <p
                                    className="text-sm text-blue-500 underline w-72 truncate"
                                >
                                    {data.producto || data.id || 'N/A'}
                                </p>

                                <Launch
                                    className="ml-2"
                                    style={{ fontSize: 15 }}
                                />
                            </a>
                        </div>
                    </div>
                );
            case 'canal':
                return <div className="w-24 flex flex-row justify-end items-center border-b border-gray-200">
                    <img
                        src={(data.id && data.id.startsWith('MLM')) ? mercadolibreIcon : amazonIcon}
                        alt="Canal"
                        className="w-4 h-4 mr-2"
                    />
                    <img
                        src={mx}
                        alt="Country"
                        className="w-4 h-4"
                    />
                </div>
            case 'tienda':
                return <div className="w-48 flex flex-row pl-3 items-center border-b border-gray-200">
                    <p
                        className="w-48 text-sm text-right"
                    >
                        {data.store ? data.store.custom_name : 'N/A'}
                    </p>
                </div>
            case 'ventas':
                return (
                    <div className="w-24 flex flex-col items-end justify-center border-b border-gray-200">
                        <Tooltip title={`Ventas en el período actual: ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}>
                            <p className="w-24 text-sm text-right">
                                {currentPeriodData.ventas.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
                            </p>
                        </Tooltip>
                        <Tooltip title={`Ventas en el período de comparación: ${comparisonStartDate.toLocaleDateString()} - ${comparisonEndDate.toLocaleDateString()}`}>
                            <p className="w-24 text-xs text-right text-gray-500">
                                {comparisonPeriodData.ventas.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
                            </p>
                        </Tooltip>
                    </div>
                );
            case 'unidades_vendidas':
                return (
                    <div className="w-24 flex flex-col items-end justify-center border-b border-gray-200">
                        <Tooltip title={`Unidades vendidas en el período actual: ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`}>
                            <p className="w-24 text-sm text-right">
                                {currentPeriodData.unidades_vendidas}
                            </p>
                        </Tooltip>
                        <Tooltip title={`Unidades vendidas en el período de comparación: ${comparisonStartDate.toLocaleDateString()} - ${comparisonEndDate.toLocaleDateString()}`}>
                            <p className="w-24 text-xs text-right text-gray-500">
                                {comparisonPeriodData.unidades_vendidas}
                            </p>
                        </Tooltip>
                    </div>
                );
            case 'disponibilidad':
                return (
                    <div style={{ minWidth: '9rem', maxWidth: '9rem' }} className="w-36 flex items-center justify-center border-b border-gray-200">
                        <div className={`whitespace-nowrap flex items-center justify-center text-sm rounded-full px-2 py-1 text-white ${data.available_quantity || data.quantity || 0 > 5 ? 'bg-green-500' : data.quantity || data.available_quantity > 0 ? 'bg-yellow-500' : 'bg-red-500'}`}>
                            {data.available_quantity || data.quantity || 0} disponibles
                        </div>
                    </div>
                );
        }
    }
    useEffect(() => {
        if (sortingTypeProp) setSortingType(sortingTypeProp);
    }, [sortingTypeProp]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setChartWidth((window.innerWidth - 15));
                return;
            }
            const newChartWidth = ((window.innerWidth) * 0.5) - 50;
            setChartWidth(newChartWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const columnNames = {
        producto: 'Producto',
        canal: 'Canal',
        tienda: 'Tienda',
        ventas: 'Ventas',
        unidades_vendidas: 'Unidades Vendidas',
        disponibilidad: 'Disponibilidad'
    };

    const getMostSoldProducts = async () => {
        if (!currentUser) return;

        setLoading(true);

        try {
            if (sortingType === 'desc') {
                // Most sold products logic (using orders)
                let currentPeriodQuery;
                let comparisonPeriodQuery;

                if (store) {
                    currentPeriodQuery = query(
                        collection(db, `users/${currentUser.uid}/stores/${store.id}/orders`),
                        where('date_timestamp', '>=', new Date(startDate)),
                        where('date_timestamp', '<=', new Date(endDate))
                    );
                    comparisonPeriodQuery = query(
                        collection(db, `users/${currentUser.uid}/stores/${store.id}/orders`),
                        where('date_timestamp', '>=', new Date(comparisonStartDate)),
                        where('date_timestamp', '<=', new Date(comparisonEndDate))
                    );
                } else {
                    const userStoreIds = (await getDocs(collection(db, `users/${currentUser.uid}/stores`)))
                        .docs.map(doc => doc.id);

                    if (userStoreIds.length === 0) {
                        setMostSoldProducts([]);
                        return;
                    }

                    currentPeriodQuery = query(
                        collectionGroup(db, `orders`),
                        where('store_id', 'in', userStoreIds),
                        where('date_timestamp', '>=', new Date(startDate)),
                        where('date_timestamp', '<=', new Date(endDate))
                    );
                    comparisonPeriodQuery = query(
                        collectionGroup(db, `orders`),
                        where('store_id', 'in', userStoreIds),
                        where('date_timestamp', '>=', new Date(comparisonStartDate)),
                        where('date_timestamp', '<=', new Date(comparisonEndDate))
                    );
                }

                const [currentPeriodSnapshot, comparisonPeriodSnapshot] = await Promise.all([
                    getDocs(currentPeriodQuery),
                    getDocs(comparisonPeriodQuery)
                ]);

                const processOrderData = (snapshot) => {
                    return snapshot.docs.reduce((acc, doc) => {
                        const data = doc.data();
                        if (!acc[data.listing_id]) {
                            acc[data.listing_id] = {
                                ventas: 0,
                                unidades_vendidas: 0,
                                store_id: data.store_id
                            };
                        }
                        acc[data.listing_id].ventas += data.total_float || 0;
                        acc[data.listing_id].unidades_vendidas += data.unidades_vendidas || 0;
                        return acc;
                    }, {});
                };

                const currentPeriodSales = processOrderData(currentPeriodSnapshot);
                const comparisonPeriodSales = processOrderData(comparisonPeriodSnapshot);

                const combinedSales = Object.keys(currentPeriodSales).reduce((acc, listingId) => {
                    acc[listingId] = {
                        currentPeriodData: currentPeriodSales[listingId],
                        comparisonPeriodData: comparisonPeriodSales[listingId] || { ventas: 0, unidades_vendidas: 0 },
                        store_id: currentPeriodSales[listingId].store_id
                    };
                    return acc;
                }, {});

                const sortedProducts = Object.entries(combinedSales)
                    .sort(([, a], [, b]) => b.currentPeriodData.ventas - a.currentPeriodData.ventas);

                const productsWithDetails = await Promise.all(sortedProducts.map(async ([listingId, salesData]) => {
                    const listingDoc = await getDoc(doc(db, `users/${currentUser.uid}/stores/${salesData.store_id}/listings`, listingId));
                    const listingData = listingDoc.data() || {};

                    if (!listingData.thumbnail) {
                        return null;
                    }

                    const storeDoc = await getDoc(doc(db, `users/${currentUser.uid}/stores`, salesData.store_id));
                    const storeData = storeDoc.data() || {};

                    return {
                        id: listingId,
                        ...listingData,
                        store: storeData,
                        currentPeriodData: salesData.currentPeriodData,
                        comparisonPeriodData: salesData.comparisonPeriodData
                    };
                }));

                const filteredProducts = productsWithDetails.filter(product => product !== null);
                setTotalPages(Math.ceil(filteredProducts.length / limitPerPage));
                const paginatedProducts = filteredProducts.slice(currentPage * limitPerPage, (currentPage + 1) * limitPerPage);
                setMostSoldProducts(paginatedProducts);

            } else {
                // Lógica para productos menos vendidos (usando listings)
                let listingsQuery;

                if (store) {
                    listingsQuery = query(
                        collection(db, `users/${currentUser.uid}/stores/${store.id}/listings`)
                    );
                } else {
                    const userStores = await getDocs(collection(db, `users/${currentUser.uid}/stores`));
                    const userStoreIds = userStores.docs.map(doc => doc.id);

                    if (userStoreIds.length === 0) {
                        setMostSoldProducts([]);
                        return;
                    }

                    listingsQuery = query(
                        collectionGroup(db, 'listings'),
                        where('store_id', 'in', userStoreIds)
                    );
                }

                const listingsSnapshot = await getDocs(listingsQuery);

                const productsWithDetails = await Promise.all(
                    listingsSnapshot.docs.map(async (docSnap) => {
                        const listingData = docSnap.data();

                        if (!listingData.thumbnail) {
                            return null;
                        }

                        const storeDoc = await getDoc(doc(db, `users/${currentUser.uid}/stores`, listingData.store_id));
                        const storeData = storeDoc.data() || {};

                        return {
                            id: docSnap.id,
                            ...listingData,
                            store: storeData,
                            currentPeriodData: {
                                ventas: listingData.ventas || 0,
                                unidades_vendidas: listingData.unidades_vendidas || 0
                            },
                            comparisonPeriodData: {
                                ventas: 0,
                                unidades_vendidas: 0
                            }
                        };
                    })
                );

                const filteredProducts = productsWithDetails.filter(product => product !== null);

                // Ordenar productos en el frontend
                const sortedProducts = filteredProducts.sort((a, b) => {
                    // Productos sin ventas van primero
                    if (!a.ventas && !b.ventas) return 0;
                    if (!a.ventas) return -1;
                    if (!b.ventas) return 1;
                    // Luego ordenar por ventas de menor a mayor
                    return a.ventas - b.ventas;
                });

                setTotalPages(Math.ceil(sortedProducts.length / limitPerPage));
                const paginatedProducts = sortedProducts.slice(currentPage * limitPerPage, (currentPage + 1) * limitPerPage);
                setMostSoldProducts(paginatedProducts);
            }
        } catch (error) {
            console.error('Error fetching most/least sold products:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getMostSoldProducts();
    }, [currentPage, sortingType, store, startDate, endDate, currentUser]);

    return (

        <div className='flex flex-col items-start justify-center bg-white rounded-lg shadow-md m-1' style={{ minWidth: chartWidth, maxWidth: chartWidth, height: 400 }}>
            <div className="flex flex-row items-center px-5 text-kompamxblue text-base justify-between w-full" style={{ minHeight: "3.5rem" }}>
                <div className="flex items-center justify-start w-full">
                    <div className="flex flex-row items-center justify-start w-full">
                        <div className="font-semibold mr-2 lg:whitespace-nowrap">
                            {sortingType === 'desc' ?
                                "Productos más vendidos" : "Productos menos vendidos"
                            }
                        </div>
                        <Tooltip title={sortingType === 'desc' ? "En esta tabla se muestran los productos más vendidos de tus tiendas" : "En esta tabla se muestran los productos menos vendidos de tus tiendas"}>
                            <InfoOutlined style={{ fontSize: 18 }} className='ml-auto' />
                        </Tooltip>
                        {!sortingTypeProp &&
                            <div className="text-xs text-gray-500 hover:text-gray-700 cursor-pointer transition duration-100 rounded-full p-1 hover:bg-gray-100">
                                <Tooltip title={sortingType === 'desc' ? "Ordenar de menor a mayor" : "Ordenar de mayor a menor"}>
                                    {sortingType === 'asc' ?
                                        <KeyboardArrowUpIcon
                                            style={{ fontSize: 22 }}
                                            onClick={() => setSortingType('desc')} />
                                        :
                                        <KeyboardArrowDownIcon
                                            style={{ fontSize: 22 }}
                                            onClick={() => setSortingType('asc')}
                                        />
                                    }
                                </Tooltip>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <hr className="w-full border-gray-300" />

            <div className="overflow-x-auto w-full h-full">
                {!loading && mostSoldProducts.length === 0 ?
                    <div className='h-full flex justify-center items-center'>
                        <p className="text-gray-500 text-lg">No hay productos para mostrar</p>
                    </div>
                    :
                    <div className="table-wrapper h-full">
                        <div className='flex h-12'>
                            <div className='bg-gray-100 border-t-2 border-gray-200 flex flex-row items-center'>
                                {Object.keys(columnNames).map((columnId, index) => (
                                    <ColumnHeader
                                        key={index}
                                        name={columnNames[columnId]}
                                        type={columnId}
                                    />
                                ))}
                            </div>
                        </div>
                        {loading ? <div className='h-full flex justify-center items-center'><div className="spinner" /></div> :
                            mostSoldProducts.map((product, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`flex w-full h-12 flex-row items-center`}
                                    >
                                        {Object.keys(columnNames).map((columnId, index2) => (
                                            <div
                                                key={index2}
                                                className={`flex w-full h-12 flex-row ${getClassNamesForColumn(columnId)}`}
                                            >
                                                {renderColumnData(product, columnId, product.currentPeriodData, product.comparisonPeriodData)}
                                            </div>
                                        ))}
                                    </div>
                                );
                            })
                        }

                    </div>
                }
            </div>

            <div className="flex flex-row justify-end items-center px-4 w-full py-1">
                <IconButton onClick={() => setCurrentPage(0)} disabled={currentPage === 0}>
                    <FirstPage />
                </IconButton>
                <IconButton onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
                    <NavigateBefore />
                </IconButton>
                <IconButton onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage >= totalPages - 1}>
                    <NavigateNext />
                </IconButton>
                <IconButton onClick={() => setCurrentPage(totalPages - 1)} disabled={currentPage === totalPages - 1}>
                    <LastPage />
                </IconButton>
            </div>
        </div>
    )
}
