import React from 'react';
import Select from 'react-select';
import { Timeline, Today } from '@mui/icons-material';
import { useTopbar } from '../../helpers/TopbarContext';

const TFFirstSection = () => {

    const formatDate = (date) => {
        return date.toISOString().split('T')[0];
    };

    const {
        selectedChartInterval,
        chartIntervalOptions,
        handleDateChange,
        handleChartIntervalChange,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        comparisonStartDate,
        setComparisonStartDate,
        comparisonEndDate,
        setComparisonEndDate,
        selectedComparisonPeriod,
        setSelectedComparisonPeriod,
        timezones,
        selectedTimezone,
        setSelectedTimezone,
        comparisonPeriodOptions,
    } = useTopbar();

    return (
        <div className="bg-white select-none w-[25%] font-normal px-4 text-xs text-gray-500 flex flex-col justify-center">
            <p>Huso Horario</p>
            <Select
                options={timezones}
                value={selectedTimezone}
                onChange={setSelectedTimezone}
                placeholder="Seleccionar zona horaria"
            />

            <p className="mt-2">Período de comparación</p>
            <Select
                options={comparisonPeriodOptions}
                value={selectedComparisonPeriod}
                onChange={setSelectedComparisonPeriod}
                placeholder="Seleccionar período"
                isSearchable={false}
            />

            <p className="mt-2">Intervalo de Gráficos</p>
            <Select
                options={chartIntervalOptions}
                value={selectedChartInterval}
                onChange={handleChartIntervalChange}
                placeholder="Seleccionar intervalo"
                isSearchable={false}
            />

            <div className="h-4" />

            <div className="flex flex-row justify-start items-center py-2">
                <Today />
                <p className="ml-2">Período del informe:</p>
            </div>
            <div className="flex flex-row justify-between items-center mb-1">
                <p>Desde:</p>
                <input
                    type="date"
                    value={formatDate(startDate)}
                    onChange={(e) => handleDateChange(new Date(e.target.value), setStartDate)}
                    className="border border-gray-200 rounded p-1 w-26"
                />
            </div>
            <div className="flex flex-row justify-between items-center">
                <p className="">Hasta:</p>
                <input
                    type="date"
                    value={formatDate(endDate)}
                    onChange={(e) => handleDateChange(new Date(e.target.value), setEndDate, true)}
                    className="border border-gray-200 rounded p-1 w-26"
                />
            </div>

            <div className="flex flex-row justify-start items-center py-2">
                <Timeline />
                <p className="ml-2">Período de comparación:</p>
            </div>

            <div className="flex flex-row justify-between items-center mb-1">
                <p>Desde:</p>
                <input
                    type="date"
                    value={formatDate(comparisonStartDate)}
                    onChange={(e) => handleDateChange(new Date(e.target.value), setComparisonStartDate)}
                    className="border border-gray-200 rounded p-1 w-26"
                />
            </div>
            <div className="flex flex-row justify-between items-center">
                <p className="">Hasta:</p>
                <input
                    type="date"
                    value={formatDate(comparisonEndDate)}
                    onChange={(e) => handleDateChange(new Date(e.target.value), setComparisonEndDate, true)}
                    className="border border-gray-200 rounded p-1 w-26"
                />
            </div>
        </div>
    );
};

export default TFFirstSection;
