import React from 'react';
import { FirstPage, NavigateBefore, NavigateNext, LastPage, Launch } from '@mui/icons-material';
import { IconButton } from '@mui/material';

function PaginationControls({ currentPage, setCurrentPage, totalPages }) {
    return (
        <div className="pagination-controls flex flex-row justify-end items-center px-4 py-3">
            <IconButton onClick={() => setCurrentPage(0)} disabled={currentPage === 0}>
                <FirstPage />
            </IconButton>
            <IconButton onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
                <NavigateBefore />
            </IconButton>
            <IconButton onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage >= totalPages - 1}>
                <NavigateNext />
            </IconButton>
            <IconButton onClick={() => setCurrentPage(totalPages - 1)} disabled={currentPage === totalPages - 1}>
                <LastPage />
            </IconButton>
        </div>
    );
}

export default PaginationControls;