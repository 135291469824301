
import { Boton } from './Boton'
import { Contenedor } from './Contenedor'
import imagenFondo from '../imagenes/fondo-llamada-a-la-accion.jpg'

export function LlamadaALaAccion() {
  return (
    <section
      id="empieza-hoy"
      className="relative overflow-hidden bg-blue-600 py-32"
    >
      <img
        className="absolute left-1/2 top-1/2 max-w-none -translate-x-1/2 -translate-y-1/2"
        src={imagenFondo}
        alt=""
        width={2347}
        height={1244}
        unoptimized
      />
      <Contenedor className="relative">
        <div className="mx-auto max-w-lg text-center">
          <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
            Empieza hoy mismo
          </h2>
          <p className="mt-4 text-lg tracking-tight text-white">
            Transforma tus datos en decisiones inteligentes. Descubre el poder de Kompa y lleva tu negocio de e-commerce al siguiente nivel con análisis precisos y en tiempo real.
          </p>
          <Boton to="/registro" color="blanco" className="mt-10">
            Prueba gratis por 7 días
          </Boton>
        </div>
      </Contenedor>
    </section>
  )
}