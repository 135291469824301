// src/components/payment/PaymentComponents.js
import { CheckCircle2 } from 'lucide-react';

export const BenefitsList = () => (
    <div className="bg-white p-6 rounded-lg mb-6 border border-gray-200">
        <h3 className="text-xl font-semibold text-gray-900 mb-4">
            Beneficios que recuperarás
        </h3>
        <ul className="space-y-3">
            <li className="flex items-center">
                <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                <span className="ml-3">Análisis de tiendas ML y Amazon</span>
            </li>
            <li className="flex items-center">
                <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                <span className="ml-3">Herramientas de comparación</span>
            </li>
            <li className="flex items-center">
                <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                <span className="ml-3">Métricas temporales y por producto</span>
            </li>
            <li className="flex items-center">
                <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                <span className="ml-3">Análisis de relaciones entre marketplaces</span>
            </li>
            <li className="flex items-center">
                <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                <span className="ml-3">Análisis de métricas publicitarias</span>
            </li>
        </ul>
    </div>
);