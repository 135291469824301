import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DragIndicator, ArrowDropUp, ArrowDropDown, Search, ViewWeek, FirstPage, NavigateBefore, NavigateNext, LastPage, Launch } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

const DraggableColumnHeader = ({ name, type, id, moveColumn, onSortChange, sortState, onDrop }) => {
    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: type,
        item: { id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const [, drop] = useDrop({
        accept: type,
        hover(item, monitor) {
            if (item.id !== id) {
                moveColumn(item.id, id, false); // false indicates not to perform the final drop operation yet
            }
        },
        drop(item, monitor) {
            onDrop(item.id, id); // Perform the final drop operation, including Firestore update
        },
    });

    const handleSort = () => {
        onSortChange(id);
    };

    return (
        <div
            ref={(node) => {
                drop(node);
                preview(node);
            }}
            style={{ opacity: isDragging ? 0.5 : 1 }}
            className={`draggable-column-header text-gray-500 text-sm font-semibold uppercase flex flex-row items-center ${name === "Producto" ? "w-96" : name === "Venta de Anuncios" ? "w-48" : "w-36"} ${name === "Producto" ? "text-left justify-start" : "text-right justify-end"}`}
        >
            <div ref={drag} className='cursor-move text-transparent hover:text-gray-500 transition-all duration-300 mr-2'>
                <DragIndicator />
            </div>
            <span onClick={handleSort} className="flex items-center cursor-pointer">
                {name}
                {sortState.column === id && sortState.direction === 'asc' ? <ArrowDropUp /> : sortState.column === id && sortState.direction === 'desc' ? <ArrowDropDown /> : null}
            </span>
            {name === "ACOS" && (
                <Tooltip title="Advertising Cost of Sales (Costo de venta de publicidad)">
                    <IconButton aria-label="info" size="small">
                        <InfoOutlined fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            )}
            {name === "CTR" && (
                <Tooltip title="Click-Through Rate (Tasa de clicks)">
                    <IconButton aria-label="info" size="small">
                        <InfoOutlined fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            )}
            {name === "CPC" && (
                <Tooltip title="Cost Per Click (Costo por clic)">
                    <IconButton aria-label="info" size="small">
                        <InfoOutlined fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            )}
            {name === "Conv" && (
                <Tooltip title="Conversiones">
                    <IconButton aria-label="info" size="small">
                        <InfoOutlined fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            )}
            {name === "ROAS" && (
                <Tooltip title="Return on Advertising Spend (Retorno de la inversión publicitaria)">
                    <IconButton aria-label="info" size="small">
                        <InfoOutlined fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};

export default DraggableColumnHeader;